import { FiltrarService } from './../shared/services/filtrar.service';
import { CatalogoService } from './../shared/services/catalogo.service';
import { Component, OnInit,  ViewChild, Renderer2, ElementRef  } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { map } from 'rxjs/operators';
import { Admin } from './../shared/models/admin.model';
import { Router } from '@angular/router';
import { PaginacionService } from './../shared/services/paginacion.service';
//import { faPlus } from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'app-cat-admin',
  templateUrl: './cat-admin.component.html',
  styleUrls: ['./cat-admin.component.css']
})
export class CatAdminComponent implements OnInit {
  //faPlus = faPlus;
  vacio;
  accionConfirmar;
  noEliminar = false;
  admin = new Admin();
  lista = [];
  frmAdmin: FormGroup;
  id;
  tituloForm = '';
  @ViewChild('ventanaAdmin', { static: true }) ventanaAdmin: ElementRef;
  @ViewChild('ventanaConfirm', { static: true }) ventanaConfirm: ElementRef;
  @ViewChild('bloqueFiltro') bloqueFiltro: ElementRef;
  constructor(private fb: FormBuilder, private renderizar: Renderer2,
              private catalogoService: CatalogoService,
              private router: Router,
              public filtrar: FiltrarService,
              public paginacion: PaginacionService) {
    this.frmAdmin = this.fb.group({
      id: ['', [Validators.required, Validators.minLength(7)]],
      nombre: ['', [Validators.required, Validators.minLength(7)]],
      correo: ['',[Validators.required, Validators.email]],
      puesto: ['', Validators.required],
      telefono: [''],
      Estado: [false],
      funcion: ['N']
    });
    catalogoService.setServidor('admin');
  }

  cambioCant(e) {
    this.paginacion.cambioCant(e.target.value);
    this.filtrarM({});
  }
  cambiarPagina(p) { /* Este cambia filtro*/
    this.paginacion.cambiarPagina(p);
    if (!this.filtrar.visible) {
      this.filtrarM({});
    } else {
      this.filtrarM(this.filtrar.filtro);
    }
  }
  guardar() {
  let resultado;
  if (this.frmAdmin.invalid) {return; }
  const datos = new Admin(this.frmAdmin.value);
  switch (this.frmAdmin.value.funcion) {
      case 'N':
        this.catalogoService.guardar(datos)
        .pipe(
          map(res => res)
        )
        .subscribe(
            res => resultado = res,
            err => console.log(err),
            () => {
              if (resultado[0] === '1') {
                this.frmAdmin.controls.id.setErrors({errordup: true});
              } else {
                this.refrescar();
                this.cerrarVentana(1);
              }
            }
            );
        break;
      case 'E':
        datos.id = this.id;
        this.catalogoService.guardar(datos, datos.id)
          .subscribe(
            res => console.log(res),
            err => console.log(err),
            () => {
              this.refrescar();
              this.cerrarVentana(1);
            }
            );
  }
  }
  nuevo() {
    this.tituloForm = 'Nuevo Administrador';
    this.frmAdmin.reset({funcion: 'N'});
    this.frmAdmin.controls.id.enable();

    this.renderizar.addClass(this.ventanaAdmin.nativeElement, 'show');
    this.renderizar.setStyle(this.ventanaAdmin.nativeElement, 'display', 'block');
  }
  editar(id) {
  this.tituloForm = 'Modificar Admin';
  this.id = id;
  this.filtrar.ejecutar({id, UsuarioEstado: id})
    .then(
      snapshot => {
        snapshot.subscribe(
          data => {
            this.frmAdmin.setValue({
              id : (data[1] as Admin).id,
              nombre : (data[1] as Admin).nombre,
              correo: (data[1] as Admin).correo,
              puesto : (data[1]  as Admin).puesto,
              telefono : (data[1] as Admin).telefono,
              Estado : (data[1] as Admin).Estado === 'A' ? true : false,
              funcion : 'E'
            });
            this.frmAdmin.controls.id.disable();
          }
        );
      }
    );
  this.renderizar.addClass(this.ventanaAdmin.nativeElement, 'show');
  this.renderizar.setStyle(this.ventanaAdmin.nativeElement, 'display', 'block');
  }
  eliminar() {
    this.catalogoService.eliminar(this.admin.id)
        .subscribe(
          () => {
            return this.refrescar();
          }
        );
    this.cerrarVentana(2);
  }
  confirmar(dato, accion) {
    this.accionConfirmar = accion;
    switch (accion) {
      case 1:
        this.tituloForm = 'Resetear contraseña para ';
        break;
      case 2:
        this.tituloForm = 'Eliminar';
    }

    if (dato.id === 'admin' && accion === 2) {
      this.showMessageSoon();
      return false;
    } else {
      this.renderizar.addClass(this.ventanaConfirm.nativeElement, 'show');
      this.renderizar.setStyle(this.ventanaConfirm.nativeElement, 'display', 'block');
      this.admin.id = dato.id;
      this.admin.nombre = dato.nombre;
      this.admin.correo = dato.correo;
    }
  }
  onConfirmar() {
    switch (this.accionConfirmar) {
      case 1:
        this.resetearPassw();
        break;
      case 2:
        this.eliminar();
    }
    this.cerrarVentana(2);
  }
  resetearPassw() {  // puede colocar un mensaje a la hora de reseteo exitoso o fallido
      this.catalogoService.resetearPassw(this.admin.id, this.admin.correo, this.admin.nombre)
        .subscribe(
          res => res
        );
  }
  refrescar() {
    this.filtrar.cantidad({})
    .then(
      snapshot => {
        snapshot.subscribe(
          data => {
      //      this.cantidadRegs = Number(data);
            this.paginacion.calcularPiecera(Number(data));
          }
        );
      }
    );

    this.filtrarM({});
  }
  mostrarFiltro() {
    this.filtrar.toggleVisible();
    this.refrescar();
  }
  ordenar(campo) {
    this.filtrar.ordenar(campo);
    this.filtrarM(this.filtrar.filtro);
  }
  filtrarM(filtro, e?) {
    if ((e) && this.filtrar.invalidChar( e.key )) {
      return;
    }
    if (e) {
      this.paginacion.actual = 1;
    }
    this.filtrar.cantidad(filtro)
    .then(
      res => {
        res.subscribe(
          data => this.paginacion.calcularPiecera(Number(data))
        );
      }
    );
    this.filtrar.ejecutar(filtro, this.paginacion.actual, this.paginacion.limite)
      .then(
        snapshot => {
          snapshot.subscribe(

            data => {
              this.lista.length = 0;
              for (const prop in data) {
                if (prop) {
                  const dato = {
                    id : data[prop].id,
                    nombre: data[prop].nombre,
                    correo: data[prop].correo,
                    puesto: data[prop].puesto,
                    telefono: data[prop].telefono
                  };
                  this.lista.push(dato);
                }
              }
              this.vacio = this.lista.length === 0;
            });
        }
      );
  }
  cerrarVentana(v: number) {
    switch (v) {
      case 1:
        this.renderizar.removeClass(this.ventanaAdmin.nativeElement, 'show');
        this.renderizar.removeStyle(this.ventanaAdmin.nativeElement, 'display');
        break;
      case 2:
        this.renderizar.removeClass(this.ventanaConfirm.nativeElement, 'show');
        this.renderizar.removeStyle(this.ventanaConfirm.nativeElement, 'display');
    }
  }
  cerrarCatalogo() {
    this.router.navigate(['/'], { skipLocationChange: true, replaceUrl: true });
  }
  showMessageSoon() { // Mostrar mensaje de forma temporal
    this.noEliminar = true;
    setTimeout(() => {
      this.noEliminar = false;
    }, 3000);
  }
  ngOnInit() {
    this.paginacion.iniciar();
    this.filtrar.inicializar({}, 'administra', 'nombre');
    this.refrescar();
  }
}
