import { map } from 'rxjs/operators';
import { AuthenticationService } from './../shared/services/authentication.service';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CatalogoService } from './../shared/services/catalogo.service';

@Component({
  selector: 'app-cambio-contras',
  templateUrl: './cambio-contras.component.html',
  styleUrls: ['./cambio-contras.component.css']
})
export class CambioContrasComponent implements OnInit {
  cambioForm: FormGroup;
  returnUrl: string;
  error = '';
  mensaje = '';
  constructor(private fb: FormBuilder,
              private router: Router,
              private authService: AuthenticationService,
              private catalogoService: CatalogoService) { }

  onSubmit() {
    if (this.cambioForm.invalid) { return; }
    if (this.cambioForm.value.passwNuevo.localeCompare(this.cambioForm.value.passwRepetir) !== 0) {
      this.error = 'Contraseñas Nueva y Repetir no coinciden';
      return;
    }
    const datos = {
      id: this.cambioForm.value.id,
      passw: this.cambioForm.value.passw,
      passwNuevo: this.cambioForm.value.passwNuevo
    };
    this.catalogoService.cambiarPassw(datos)
       .subscribe(
        res =>  {
          if (res[0] === '0') {
            this.error = 'Contraseña actual no válida';
          } else {
            this.mensaje = 'Se actualizó la contraseña de forma correcta';
            setTimeout(() => {
              this.mensaje = '';
              this.cerrarCatalogo();
            }, 3000);
          }
        }
      );

  }
  onFocus() {
    this.error = '';
  }
  ngOnInit() {
      this.cambioForm = this.fb.group({
        id: [this.authService.currentUserValue.id],
        passw: ['', Validators.required],
        passwNuevo: ['', Validators.required],
        passwRepetir: ['', Validators.required]
    });
  }
  cerrarCatalogo() {
     this.router.navigate(['/'], { skipLocationChange: true, replaceUrl: true });
  }

}
