import { ImprimirService } from './../shared/services/imprimir.service';
import { FiltrarService } from './../shared/services/filtrar.service';
import { PaginacionService } from './../shared/services/paginacion.service';
// import { CatCursoService } from './../shared/services/cat-curso.service';
import { Component, OnInit,  ViewChild, Renderer2, ElementRef  } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CatalogoService } from './../shared/services/catalogo.service';
import { Curso } from '../shared/models/curso.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cat-cursos',
  templateUrl: './cat-cursos.component.html',
  styleUrls: ['./cat-cursos.component.css']
})
export class CatCursosComponent implements OnInit {
  vacio;
  lista = [];
  curso = new Curso();
  // cursos;
  frmCurso: FormGroup;
  codigo;
  tituloForm = '';
  filtroActual;
  cantidadRegs: number;
  mensajeEliminar;
  @ViewChild('ventanaCurso', { static: true }) ventanaCurso: ElementRef;
  @ViewChild('ventanaConfirm', { static: true }) ventanaConfirm: ElementRef;
  @ViewChild('bloqueFiltro') bloqueFiltro: ElementRef;
  constructor(private fb: FormBuilder, private renderizar: Renderer2,
              private catalogoService: CatalogoService, private router: Router,
              public paginacion: PaginacionService,
              public filtrar: FiltrarService,
              private imprimirSrv: ImprimirService) {
      this.frmCurso = this.fb.group({
        codigo: ['', [Validators.required, Validators.minLength(8), Validators.pattern('[0-9A-Za-z]{3}-[0-9]{4}')]],
        nombre: ['', [Validators.required, Validators.minLength(7)]],
        funcion: ['N']
      });
      catalogoService.setServidor('curso');
  }
  cambioCant(e) {
    this.paginacion.cambioCant(e.target.value);
    this.filtrarM({});
  }
  cambiarPagina(p) {
    this.paginacion.cambiarPagina(p);
    if (!this.filtrar.visible) {
      this.filtrarM({});
    } else {
      this.filtrarM(this.filtrar.filtro);
    }
  }
  guardarCurso() {
   let resultado;
   if (this.frmCurso.invalid) {return; }
   const curso = new Curso(this.frmCurso.value);
   if (this.codigo) {
    curso.codigo = this.codigo.toUpperCase();
   } else {
     curso.codigo = curso.codigo.toUpperCase();
   }
   switch (this.frmCurso.value.funcion) {
      case 'N':
        this.catalogoService.guardar(curso)
         .subscribe(
           res => resultado = res,
           err => console.log(err),
           () => {
            console.log(resultado);
            if (resultado[0] === '1') {
              this.frmCurso.controls.codigo.setErrors({errordup: true});
            } else {
              this.refrescar();
              this.cerrarVentana(1);
            }
            }
           );
        break;
      case 'E':
        curso.codigo = this.codigo;
        this.catalogoService.guardar(curso, curso.codigo)
          .subscribe(
            res => console.log(res),
            err => console.log(err),
            () => {
              this.refrescar();
              this.cerrarVentana(1);
            }
            );
   }
  }
  nuevoCurso() {
    this.tituloForm = 'Nuevo Curso';
    this.frmCurso.reset({funcion: 'N'});
    this.frmCurso.controls.codigo.enable();

    this.renderizar.addClass(this.ventanaCurso.nativeElement, 'show');
    this.renderizar.setStyle(this.ventanaCurso.nativeElement, 'display', 'block');
  }
  editarCurso(codigo) {
   this.tituloForm = 'Modificar Curso';
   this.codigo = codigo;
   this.filtrar.ejecutar({codigo, UsuarioEstado: codigo})
     .then(
       snapshot => {
         snapshot.subscribe(
            data => {
              this.frmCurso.setValue({
                codigo : (data[1] as Curso).codigo,
                nombre : (data[1] as Curso).nombre,
                funcion : 'E'
              });
              this.frmCurso.controls.codigo.disable();
            }
          );
       });
   this.renderizar.addClass(this.ventanaCurso.nativeElement, 'show');
   this.renderizar.setStyle(this.ventanaCurso.nativeElement, 'display', 'block');
  }
  eliminarCurso() {
//    let emp;
    this.catalogoService.eliminar(this.curso.codigo)
        .subscribe(
//           res => emp = res,
  //         err => console.log(err),
           () => this.refrescar() // this.cursos = this.cursos.filter(e => e.codigo !== this.curso.codigo)
        );
    this.cerrarVentana(2);
  }
  confirmarEliminarCurso(curso) {
    this.tituloForm = 'Confirmación';
    this.filtrar.buscarRegistro(false, false, false, false, curso.codigo, false)
    .then (
      snapshot => {
        snapshot.subscribe(
          data => {
            this.curso.codigo = curso.codigo;
            this.curso.nombre = curso.nombre;
            if (data === null) {
              this.tituloForm = 'Confirmación';
              this.mensajeEliminar = 'Eliminar curso';
            } else {
              this.mensajeEliminar = 'No se puede eliminar el curso';
              this.tituloForm = 'Advertencia';
            }
            this.renderizar.addClass(this.ventanaConfirm.nativeElement, 'show');
            this.renderizar.setStyle(this.ventanaConfirm.nativeElement, 'display', 'block');
          }
        );
      }
    );
  }
  refrescar() {
    this.filtrar.cantidad({})
    .then(
      snapshot => {
        snapshot.subscribe(
          data => {
            this.cantidadRegs = Number(data);
            this.paginacion.calcularPiecera(Number(data));
          }
        );
      }
    );
    this.filtrarM({});
  }
  mostrarFiltro() {
    this.filtrar.toggleVisible();
    this.refrescar();
  }
  ordenar(campo) {  // esto es nuevo
    this.filtrar.ordenar(campo);
    this.filtrarM(this.filtrar.filtro);
  }
  filtrarM(filtro, e?) {
    this.filtroActual = filtro;
    if ((e) && this.filtrar.invalidChar( e.key )) {
      return;
    }
    if (e) {
      this.paginacion.actual = 1;
    }
    this.filtrar.cantidad(filtro)
    .then(
      res => {
        res.subscribe(
          data => this.paginacion.calcularPiecera(Number(data))
        );
      }
    );
    this.filtrar.ejecutar(filtro, this.paginacion.actual, this.paginacion.limite)
      .then(
        snapshot => {
          snapshot.subscribe(
            data => {
              this.lista.length = 0;
              for (const prop in data) {
                if (prop) {
                  const dato = {
                    codigo : data[prop].codigo,
                    nombre: data[prop].nombre,
                  };
                  this.lista.push(dato);
                }
              }
              this.vacio = this.lista.length === 0;
            }
          );
        }
      );
  }
  cerrarVentana(v: number) {
    switch (v) {
      case 1:
        this.renderizar.removeClass(this.ventanaCurso.nativeElement, 'show');
        this.renderizar.removeStyle(this.ventanaCurso.nativeElement, 'display');
        break;
      case 2:
        this.renderizar.removeClass(this.ventanaConfirm.nativeElement, 'show');
        this.renderizar.removeStyle(this.ventanaConfirm.nativeElement, 'display');
    }
  }
  cerrarCatalogo() {
    this.router.navigate(['/'], { skipLocationChange: true, replaceUrl: true });
  }
  onImprimir() {
    const columna = [['Codigo', 'Nombre']];
    const fila = [];
    this.filtrar.ejecutar(this.filtroActual, 1, this.cantidadRegs)
    .then(
      snapshot => {
        snapshot.subscribe(
          data => {
            for (const prop in data) {
              if (prop) {
                const dato = [
                  data[prop].codigo,
                  data[prop].nombre
                ];
                fila.push(dato);
              }
            }
            this.imprimirSrv.imprimir(columna, fila, 'Listado de Cursos');
          }
        );
      }
    );

  }
  ngOnInit() {
    this.paginacion.iniciar();
    this.filtrar.inicializar({}, 'cursos', 'codigo');
    this.refrescar();
  }
}
