import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Servidor } from '../servidor';

@Injectable({
  providedIn: 'root'
})
export class PruebaService {
  servidor = new Servidor();
  constructor(private http: HttpClient) {  }
  async pruebaActiva(carnet) {
    const respuesta = await this.http.get(`${this.servidor.URL}/examen/activo/${carnet}`);
    return respuesta;
  }
  async armarPrueba(idPrueba, carnet) {
    const respuesta = await this.http.get(`${this.servidor.URL}/examen/formar/${idPrueba}/${carnet}`);
    return respuesta;
  }
  async revisarPrueba(datos, prueba) {
    const respuesta = await this.http.post(`${this.servidor.URL}/examen/revisar/${prueba}`, datos) ;
    return respuesta;
  }
  async mostrarPruebas(grupo, tipoPrueba) {
    const respuesta = await this.http.get(`${this.servidor.URL}/examen/pruebas/${grupo}/${tipoPrueba}`);
    return respuesta;
  }
  async mostrarAlumnos(grupo, idPrueba) {
    const respuesta = await this.http.get(`${this.servidor.URL}/examen/activar/${grupo}/${idPrueba}`);
    return respuesta;
  }
  async activarPruebaAlumno(datos) {
    const respuesta = await this.http.post(`${this.servidor.URL}/examen/activarA`, datos);
    return respuesta;
  }
  async activarPruebaGrupo(datos) {
    const respuesta = await this.http.post(`${this.servidor.URL}/examen/activarT`, datos);
    return respuesta;
  }
  async todosActivos(grupo) {
    const respuesta = await this.http.get(`${this.servidor.URL}/examen/activos/${grupo}`);
    return respuesta;
  }  
}
