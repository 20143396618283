import { ImprimirService } from './../shared/services/imprimir.service';
import { FiltrarService } from './../shared/services/filtrar.service';
import { PaginacionService } from './../shared/services/paginacion.service';
import { Component, OnInit,  ViewChild, Renderer2, ElementRef  } from '@angular/core';
import { CatalogoService } from './../shared/services/catalogo.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { map } from 'rxjs/operators';
import { Alumno } from './../shared/models/alumno.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cat-alum',
  templateUrl: './cat-alum.component.html',
  styleUrls: ['./cat-alum.component.css']
})
export class CatAlumComponent implements OnInit {
  filtroActual;
  cantidadRegs: number;
  accionConfirmar;
  enviado = false;
  alumno = new Alumno();
  lista = [];
  vacio;
  frmAlumno: FormGroup;
  id = {
    Carnet : '',
    Identificacin : ''
  };
  tituloForm = '';
  mensajeEliminar;
  @ViewChild('ventanaAlumno', { static: true }) ventanaAlumno: ElementRef;
  @ViewChild('ventanaConfirm', { static: true }) ventanaConfirm: ElementRef;
  @ViewChild('bloqueFiltro') bloqueFiltro: ElementRef;
  constructor(private fb: FormBuilder, private renderizar: Renderer2,
              private catalogoService: CatalogoService,
              private router: Router,
              public paginacion: PaginacionService,
              public filtrar: FiltrarService,
              private imprimirSrv: ImprimirService) {
              this.frmAlumno = this.fb.group({
              Carnet: [''],
              Identificacion: ['', [Validators.required, Validators.minLength(7)]],
              Nombre: ['', [Validators.required, Validators.minLength(3)]],
              Apellido1: ['', [Validators.required, Validators.minLength(3)]],
              Apellido2: ['', [Validators.required, Validators.minLength(3)]],
              Correo: ['', [Validators.required, Validators.email]],
              Sexo: ['', Validators.required],
              CodGrupo: [''],
              PruebaActiva: ['0'],
              Telefono: ['', [Validators.required, Validators.minLength(8)]],
              Estado: [false],
              funcion: ['N']
              });
              this.frmAlumno.controls.Carnet.disable();
              this.catalogoService.setServidor('alumno');
  }
  cambioCant(e) {
    this.paginacion.cambioCant(e.target.value);
    this.filtrarM({});
  }
  cambiarPagina(p) { // aquí hay cambios
    this.paginacion.cambiarPagina(p);
    if (!this.filtrar.visible) {
      this.filtrarM({});
    } else {
      this.filtrarM(this.filtrar.filtro);
    }
  }
  guardar() {
  let resultado;
  if (this.frmAlumno.invalid) {return; }
  this.enviado = true;
  const datos = new Alumno(this.frmAlumno.value);
  datos.Carnet = this.id.Carnet;

  switch (this.frmAlumno.value.funcion) {
      case 'N':
        this.catalogoService.guardar(datos)
        .subscribe(
          res =>  { resultado = res },
          err => console.log(err),
          () => {
            if (resultado[0] === '1') {
              this.frmAlumno.controls.Identificacion.setErrors({errordup: true});
            } else {
              this.refrescar();
              this.cerrarVentana(1);
            }
          }
          );
        break;
      case 'E':
        datos.Carnet = this.id.Carnet;
        datos.Identificacion = this.id.Identificacin;
        this.catalogoService.guardar(datos, datos.Carnet)
          .subscribe(
            /*res => console.log(res),
            err => console.log(err),*/
            () => {
              this.refrescar();
              this.cerrarVentana(1);
            }
            );
  }
  }
  nuevo() {
    this.tituloForm = 'Nuevo Alumno';
    // this.frmAlumno.reset({funcion: 'N'});
    this.catalogoService.sigCarnet()
    .pipe(
      map(res => res)
    )
    .subscribe(
      data => {
        this.id.Carnet = (data as any).carnet;
        this.frmAlumno.reset({funcion: 'N', Carnet : (data as any).carnet});
      }
    )
    ;
    this.frmAlumno.controls.Identificacion.enable();
    this.enviado = false;
    this.renderizar.addClass(this.ventanaAlumno.nativeElement, 'show');
    this.renderizar.setStyle(this.ventanaAlumno.nativeElement, 'display', 'block');
  }
  editar(carnet, id) {
  this.tituloForm = 'Modificar Alumno';
  this.id.Carnet = carnet;
  this.id.Identificacin = id;
  this.filtrar.ejecutar({Carnet: carnet, UsuarioEstado: carnet})
    .then(
      snapshot => {
        snapshot.subscribe(
          data => {
            data = data[1];
            this.frmAlumno.setValue({
              Carnet : (data as Alumno).Carnet,
              Identificacion : (data as Alumno).Identificacion,
              Nombre : (data as Alumno).Nombre,
              Apellido1 : (data as Alumno).Apellido1,
              Apellido2 : (data as Alumno).Apellido2,
              Correo: (data as Alumno).Correo,
              Sexo : (data as Alumno).Sexo,
              CodGrupo : (data as Alumno).CodGrupo,
              PruebaActiva : (data as Alumno).PruebaActiva,
              Telefono : (data as Alumno).Telefono,
              Estado : (data as Alumno).Estado === 'A' ? true : false,
              funcion : 'E'
            });
            this.frmAlumno.controls.Identificacion.disable();
          }
          );
      }
  );
  this.enviado = false;
  this.renderizar.addClass(this.ventanaAlumno.nativeElement, 'show');
  this.renderizar.setStyle(this.ventanaAlumno.nativeElement, 'display', 'block');
  }
  eliminar() {
    this.catalogoService.eliminar(this.alumno.Carnet)
        .subscribe(
  //           res => emp = res,
  //         err => console.log(err),
          () => this.refrescar() // this.cursos = this.cursos.filter(e => e.codigo !== this.curso.codigo)
        );
    this.cerrarVentana(2);
  }
  reseteoTodos() {
    this.catalogoService.reseteoTodos()
      .subscribe(
        res => console.log(res)
      )
  }
  confirmar(alumno, accion) {
    this.accionConfirmar = accion;
    switch (accion) {
      case 1:
        this.tituloForm = 'Contraseña';
        this.mensajeEliminar = 'Resetear contraseña de ';
        break;
      case 2:
        this.filtrar.buscarRegistro(false, false, alumno.Carnet, false, false, false)
          .then (
            snapshot => {
              snapshot.subscribe(
                data => {
                  this.alumno.Carnet = alumno.Carnet;
                  if (data === null) {
                    this.tituloForm = 'Confirmación';
                    this.mensajeEliminar = 'Eliminar a';
                  } else {
                    this.mensajeEliminar = 'No se puede eliminar a';
                    this.tituloForm = 'Advertencia';
                  }
                }
              );
            }
          );
    }

    // this.tituloForm = 'Confirmación';
    this.renderizar.addClass(this.ventanaConfirm.nativeElement, 'show');
    this.renderizar.setStyle(this.ventanaConfirm.nativeElement, 'display', 'block');
    this.alumno.Carnet = alumno.Carnet;
    this.alumno.Nombre = alumno.Nombre;
    this.alumno.Apellido1 = alumno.Apellido1;
    this.alumno.Apellido2 = alumno.Apellido2;
    this.alumno.Correo = alumno.Correo;
  }
  onConfirmar() {
    switch (this.accionConfirmar) {
      case 1:
        this.resetearPassw();
        break;
      case 2:
        this.eliminar();
    }
    this.cerrarVentana(2);
  }
  resetearPassw() {
      this.catalogoService.resetearPassw(this.alumno.Carnet, this.alumno.Correo, this.alumno.Nombre)
        .subscribe(
          res => res
        );
  }
  refrescar() {
    this.filtrar.cantidad({})
    .then(
      snapshot => {
        snapshot.subscribe(
          data => {
            this.cantidadRegs = Number(data);
            this.paginacion.calcularPiecera(Number(data));
          }
        );
      }
    );
    this.filtrarM({});
  }
  mostrarFiltro() {  // aquí hay cambios
    this.filtrar.toggleVisible();
    this.refrescar();
  }
  ordenar(campo) {  // esto es nuevo
    this.filtrar.ordenar(campo);
    this.filtrarM(this.filtrar.filtro);
  }
  filtrarM(filtro, e?) {  // aquí hay cambios
    this.filtroActual = filtro;
    if ((e) && this.filtrar.invalidChar( e.key )) {
      return;
    }
    if (e) {
      this.paginacion.actual = 1;
    }
    this.filtrar.cantidad(filtro)
    .then(
      res => {
        res.subscribe(
          data => this.paginacion.calcularPiecera(Number(data))
        );
      }
    );
    this.filtrar.ejecutar(filtro, this.paginacion.actual, this.paginacion.limite)
      .then(
        snapshot => {
          snapshot.subscribe(
            data => {

              this.lista.length = 0;
              for (const prop in data) {
                if (prop) {
                  const dato = {
                    Identificacion: data[prop].Identificacion,
                    Carnet : data[prop].Carnet,
                    Nombre: data[prop].Nombre,
                    Apellido1: data[prop].Apellido1,
                    Apellido2: data[prop].Apellido2,
                    Correo: data[prop].Correo,
                    Telefono: data[prop].Telefono,
                    Sexo: data[prop].Sexo
                  };
                  this.lista.push(dato);
                }
              }
              this.vacio = this.lista.length === 0;
            }
          );
        }
      );
  }
  cerrarVentana(v: number) {
    switch (v) {
      case 1:
        this.renderizar.removeClass(this.ventanaAlumno.nativeElement, 'show');
        this.renderizar.removeStyle(this.ventanaAlumno.nativeElement, 'display');
        break;
      case 2:
        this.renderizar.removeClass(this.ventanaConfirm.nativeElement, 'show');
        this.renderizar.removeStyle(this.ventanaConfirm.nativeElement, 'display');
    }
  }
  cerrarCatalogo() {
    this.router.navigate(['/'], { skipLocationChange: true, replaceUrl: true });
  }
  onImprimir() {
    const columna = [['Carnet', 'Id', 'Nombre', 'Teléfono', 'Correo']];
    const fila = [];
    this.filtrar.ejecutar(this.filtroActual, 1, this.cantidadRegs)
    .then(
      snapshot => {
        snapshot.subscribe(
          data => {
            for (const prop in data) {
              if (prop) {
                const dato = [
                  data[prop].Carnet,
                  data[prop].Identificacion,
                  `${data[prop].Nombre} ${data[prop].Apellido1} ${data[prop].Apellido2}`,
                  data[prop].Telefono, data[prop].Correo
                ];
                fila.push(dato);
              }
            }
            this.imprimirSrv.imprimir(columna, fila, 'Listado de Alumnos');
          }
        );
      }
    );
  }
  ngOnInit() {  // aquí hay cambios
    this.paginacion.iniciar();
    this.filtrar.inicializar({}, 'alumnos', 'Carnet');
    this.refrescar();
  }
}
