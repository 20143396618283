import { Observable, interval, Subscription } from 'rxjs';
import { Component, OnInit, Input, Output,
        ChangeDetectionStrategy, ChangeDetectorRef, EventEmitter } from '@angular/core';
import { take, map } from 'rxjs/operators';
@Component({
  selector: 'app-contador',
  templateUrl: './contador.component.html',
  styleUrls: ['./contador.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContadorComponent implements OnInit {
  @Input() public startAt = 0;
  @Output()
    estadoContador = new EventEmitter<string>();

  valorActual = '';
  subscripcionActual: Subscription;
  constructor(private changeDetector: ChangeDetectorRef) { }

  ngOnInit() {
  }
  public Iniciar() {
    this.valorActual = this.formatoHora(this.startAt);
    this.changeDetector.detectChanges();

    const t: Observable<number> = interval(1000);

    this.subscripcionActual = t.pipe(
      take(this.startAt), map(v => this.startAt - (v + 1)))
     .subscribe(
       v => {
          this.valorActual = this.formatoHora(v);
          if (v === 300) {
            this.estadoContador.emit('AVISO');
          }
          this.changeDetector.detectChanges();
       },
       error => {
         this.estadoContador.error(error);
       },
       () => {
         this.valorActual = '00:00';
         this.estadoContador.emit('COMPLETO');
         this.changeDetector.detectChanges();
       }
     );
  }
  public detener() {
    this.subscripcionActual.unsubscribe();
    this.estadoContador.emit('ABORTA');

  }
  private formatoHora(v) {
    const horas = Math.floor(v / 60 / 60);
    const formatearHoras = (horas > 9 ? horas : '0' + horas);
    const minutos = Math.floor((v - (horas * 60 * 60)) / 60);
    const formatearMinutos = (minutos > 9 ? minutos : '0' + minutos);
    const segundos =  v % 60;
    const formatearSegundos = (segundos > 9 ? segundos : '0' + segundos);
    return `${formatearHoras}:${formatearMinutos}:${formatearSegundos}`;
  }

}
