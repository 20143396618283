<div class="card text-center mx-auto" style="width: 28rem;" mt10>
    <h4 class="card-header">
      <i class="fa fa-sign-in-alt"></i> Cambiar Contraseña
    </h4>
    <div class="card-body">
      <form class="form" [formGroup]= "cambioForm" name="cambioForm" id="cambioForm"
        (ngSubmit)="onSubmit()">
        <input type="hidden" name="id">
        <div class="form-group">
          <label for="">Contraseña Actual</label>
          <input type="password" class="form-control" formControlName="passw" name="passw"
            [ngClass]="{'border-danger':cambioForm.controls['passw'].invalid && cambioForm.controls['passw'].touched}">
            <div class="alert alert-danger" *ngIf="cambioForm.controls['passw'].invalid && cambioForm.controls['passw'].touched">
              <div *ngIf="cambioForm.controls['passw'].errors['required']">Necesita ingresar la contraseña actual</div>
           </div>
        </div>
        <div class="form-group">
            <label for="">Nueva Contraseña</label>
            <input type="password" class="form-control" formControlName="passwNuevo" name="passwNuevo"
              [ngClass]="{'border-danger':cambioForm.controls['passwNuevo'].invalid && cambioForm.controls['passwNuevo'].touched}"
              (focus)="onFocus();">
              <div class="alert alert-danger" *ngIf="cambioForm.controls['passwNuevo'].invalid && cambioForm.controls['passwNuevo'].touched">
                <div *ngIf="cambioForm.controls['passwNuevo'].errors['required']">La nueva contraseña es obligatoria</div>
             </div>
          </div>
          <div class="form-group">
              <label for="">Repetir Contraseña</label>
              <input type="password" class="form-control" formControlName="passwRepetir" name="passwRepetir"
                [ngClass]="{'border-danger':cambioForm.controls['passwRepetir'].invalid && cambioForm.controls['passwRepetir'].touched}"
                (focus)="onFocus();">
                <div class="alert alert-danger" *ngIf="cambioForm.controls['passwRepetir'].invalid && cambioForm.controls['passwRepetir'].touched">
                  <div *ngIf="cambioForm.controls['passwRepetir'].errors['required']">Debe confirmar la nueva contraseña</div>
               </div>
            </div>
        <div class="alert alert-danger"
          [ngStyle]="{'display':error ? 'block' : 'none'}">
          {{error}}</div>
          <div class="alert alert-success"
          [ngStyle]="{'display':mensaje ? 'block' : 'none'}">
          {{mensaje}}</div>
      </form>
    </div>
    <div class="card-footer text-right">
      <button class="btn btn-success me-3" type="submit" form="cambioForm"
      [disabled]="!cambioForm.valid">Cambiar</button>
      <button class="btn btn-danger" (click)="cerrarCatalogo()">  Cerrar  </button>
    </div>
  </div>
