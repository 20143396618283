import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { ContadorComponent } from './contador/contador.component';
import { AppComponent } from './app.component';
import { CatAdminComponent } from './cat-admin/cat-admin.component';
import { CatProfComponent } from './cat-prof/cat-prof.component';
import { CatAlumComponent } from './cat-alum/cat-alum.component';
import { CatCursosComponent } from './cat-cursos/cat-cursos.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HomeComponent } from './home/home.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { PruebasComponent } from './pruebas/pruebas.component';
import { MatriculaComponent } from './matricula/matricula.component';
import { LoginComponent } from './login/login.component';
import { NavbarComponent } from './navbar/navbar.component';
import { PreguntasComponent } from './preguntas/preguntas.component';
import { LoaderInterceptor } from './shared/helpers/loader.interceptor';
import { LoaderService } from './shared/services/loader.service';
import { LoaderComponent } from './loader/loader.component';
import { ErrorInterceptor } from './shared/helpers/error.interceptors';
import { JwtInterceptor } from './shared/helpers/jwt.interceptor';
import { CambioContrasComponent } from './cambio-contras/cambio-contras.component';
import { GrupoComponent } from './grupo/grupo.component';
import { PruebasentComponent } from './pruebasent/pruebasent.component';
import { PruebaComponent } from './prueba/prueba.component';
import { ResultadoComponent } from './resultado/resultado.component';
import { NotasEstComponent } from './notas-est/notas-est.component';
import { CanDeactivateGuard } from './shared/guards/can-deactivate.guard';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
/*import { library } from '@fortawesome/fontawesome-svg-core';*/
import { faPlus, faKey, faPencilAlt, faTrash, faSearch, faCalendarAlt, faListOl,
         faNewspaper, faInfoCircle, faDotCircle, faCheck, faUserEdit, faEdit, faFileAlt, faTimes,
       } from '@fortawesome/free-solid-svg-icons';
import { FaConfig } from '@fortawesome/angular-fontawesome';
import { GrupoProfesorComponent } from './grupo-profesor/grupo-profesor.component';
import { ActivarExamenComponent } from './activar-examen/activar-examen.component';
import { VerNotasComponent } from './ver-notas/ver-notas.component';
import { EntrarNotasComponent } from './entrar-notas/entrar-notas.component';
//import { QuillModule } from 'ngx-quill';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
//import { InfoPreguntaComponent } from './info-pregunta/info-pregunta.component';
//import { NgxQuillModule } from '@dimpu/ngx-quill'
//import {} from quill


@NgModule({
  declarations: [
    AppComponent,
    CatAdminComponent,
    CatProfComponent,
    CatAlumComponent,
    CatCursosComponent,
    HomeComponent,
    PruebasComponent,
    MatriculaComponent,
    LoginComponent,
    NavbarComponent,
    PreguntasComponent,
    LoaderComponent,
    CambioContrasComponent,
    GrupoComponent,
    PruebasentComponent,
    ContadorComponent,
    PruebaComponent,
    ResultadoComponent,
    NotasEstComponent,
    GrupoProfesorComponent,
    ActivarExamenComponent,
    VerNotasComponent,
    EntrarNotasComponent,
   // InfoPreguntaComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    FontAwesomeModule,
    CKEditorModule
   ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    LoaderService,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    CanDeactivateGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

  constructor(library: FaIconLibrary) {
    // Add an icon to the library for convenient access in other components
    library.addIcons(
      faPlus,
      faKey,
      faPencilAlt,
      faTrash,
      faSearch,
      faCalendarAlt,
      faListOl,
      faNewspaper,
      faInfoCircle,
      faDotCircle,
      faCheck,
      faUserEdit,
      faEdit,
      faFileAlt,
      faTimes);
  }
 }
