import { NotasService } from './../shared/services/notas.service';
import { Component, OnInit, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
//import { PruebaService } from '../shared/services/prueba.service';
import { ImprimirService } from '../shared/services/imprimir.service';

@Component({
  selector: 'app-ver-notas',
  templateUrl: './ver-notas.component.html',
  styleUrls: ['./ver-notas.component.css']
})
export class VerNotasComponent implements OnInit {
  vacio;
  error = false;
  origen;
  carnet;
  notas;
  grupo;
  idPrueba;
  alumnos;
  titulo;
  nombreCurso;
  detalles;
  @ViewChild('detalleResultado', { static: true }) detalleResultado: ElementRef;
  @ViewChild('entrarNotas', { static: true }) entrarNotas: ElementRef;
  constructor(
    private ruta: ActivatedRoute,
    private notasService: NotasService,
    private router: Router,
    private renderizar: Renderer2,
    private imprimirSrv: ImprimirService) { }
  cerrar() {
    switch (this.origen) {
      case '0':
        this.router.navigate(['grupo'], { skipLocationChange: true, replaceUrl: true });
        break;
      case '1':
        this.router.navigate(['grupo-prof'], { skipLocationChange: true, replaceUrl: true });
    }
  }
  cerrarVentanaInfo() {
    this.renderizar.removeClass(this.detalleResultado.nativeElement, 'show');
    this.renderizar.removeStyle(this.detalleResultado.nativeElement, 'display');
  }
  cerrarVentanaNotas() {
    this.renderizar.removeClass(this.entrarNotas.nativeElement, 'show');
    this.renderizar.removeStyle(this.entrarNotas.nativeElement, 'display');
  }
  mostrarVentanaNotas(carnet) {
    console.log(carnet);
    this.notasService.editarNotasAlumnos(this.grupo, carnet)
      .then(
        snapshot => {
          snapshot.subscribe(
            data => {
              if (data) {
                this.notas = data;
                const me = this;
                if (me.notas) {
                  me.notas = Object.keys(me.notas).map( key => {
                    return me.notas[key];
                  });
              } else {
                  this.notas = null;
              }
              }
            }
          );
        }
      );
    this.renderizar.addClass(this.entrarNotas.nativeElement, 'show');
    this.renderizar.setStyle(this.entrarNotas.nativeElement, 'display', 'block');
  }
  mostrarVentanaInfo(idDetalle, curso) {
    // this.detalle = idDetalle;
     this.nombreCurso = curso;
     this.notasService.obtenerDetalleNotas(idDetalle)
       .then(
         snapshot => {
           snapshot.subscribe(
             data => {
               this.detalles = data;
               const me = this;
               if (data) {
                 me.detalles = Object.keys(me.detalles).map( key => {
                   return me.detalles[key];
                 });
               }
             }
           );
         }
       );
     this.renderizar.addClass(this.detalleResultado.nativeElement, 'show');
     this.renderizar.setStyle(this.detalleResultado.nativeElement, 'display', 'block');
  }
  onImprimir() {

    const columna = [['Carnet', 'Id', 'Nombre', 'Promedio']];
    const fila = [];
    console.log(this.alumnos);
    for (const prop in this.alumnos) {
      if (prop) {
        const dato = [
          this.alumnos[prop].carnet,
          this.alumnos[prop].identificacion,
          this.alumnos[prop].nombre,
          this.alumnos[prop].promedio
        ];
        fila.push(dato);
      }
    }

    console.log(fila);
    this.imprimirSrv.imprimir(columna, fila, 'Resultados del Grupo '+this.grupo);
  }  
  ngOnInit() {
    this.ruta.params.subscribe(params => {
       this.grupo = params.grupo;
       this.origen = params.origen;
       }
     );
    this.notasService.verNotasAlumnos(this.grupo)
      .then(
        snapshot => {
          snapshot.subscribe(
            data => {
              this.vacio = !Boolean(data[1]);
              if (data && !this.vacio) {
                this.alumnos = data;
                const me = this;
                if (me.alumnos) {
                  me.alumnos = Object.keys(me.alumnos).map( key => {
                    return me.alumnos[key];
                  });
              } else {
                  this.alumnos = null;
              }
              }
            }
          );
        }
      );
  }

}
