import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from './shared/services/authentication.service';
import { LocationStrategy } from '@angular/common';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit  {
    rol =  this.asrv.currentUserValue == null ? -1 : this.asrv.currentUserValue.Rol;
    constructor(
    private asrv: AuthenticationService,
    private locationStrategy: LocationStrategy
  ) {}
  ngOnInit() {
   this.preventBackButton();
  }
  preventBackButton() {
    history.pushState(null, null, location.href);
    this.locationStrategy.onPopState(() => {
      history.pushState(null, null, location.href);
    });
  }
}
