<div class="container">
  <div class="card">
    <div class="card-header bg-light">
       <h2 class="text-center">Listado de Notas</h2>
    </div>
    <div class="card-body">
      <div class="alert alert-warning"*ngIf="!hayNotas">No tienes calificaciones que mostrar</div>
 <div class="table-responsive" *ngIf="hayNotas">
   <table class="table table-sm table-hover table-striped">
     <thead class="thead-light">
       <tr>
         <th>Grupo</th>
         <th>Cod Curso</th>
         <th>Curso</th>
         <th>Nota</th>
         <th>Estado</th>
         <th>&nbsp;</th>
     </tr>
     </thead>
     <tbody>
       <tr *ngFor="let item of calificaciones">
         <td>{{item.codGrupo}}</td>
         <td>{{item.codCurso}}</td>
         <td>{{item.nombre}}</td>
         <td>{{item.promedio}}</td>
         <td>{{item.estado}}</td>
         <td class="text-center">
            <button class="btn btn-outline btn-primary btn-sm" (click)="mostrarVentanaInfo(item.idDetalle, item.nombre)" title="Ver detalle de Trabajos">
              <fa-icon [icon]="['fas', 'info-circle']"></fa-icon>
              <span class="ml-2 d-none d-md-inline-block">Detalles</span>
            </button>
          </td>
       </tr>
     </tbody>
   </table>
 </div>
   <!--div class="d-flex">
     <div class="mr-3">
       <select name="" id="" class="form-control"  (change)="cambioCant($event)">
           <option value="2">2</option>
           <option value="5" selected>5</option>
           <option value="10">10</option>
           <option value="15">15</option>
           <option value="20">20</option>
           <option value="30">30</option>
           <option value="50">50</option>
           <option value="75">75</option>
           <option value="100">100</option>
       </select>
     </div>
     <div>
      <ul class="pagination pagination-md">
        <li class="page-item" [ngClass]="{'disabled':paginacion.actual==1}"><a class="page-link" (click)="cambiarPagina(-1)" >&laquo;</a></li>
        <li class="page-item" *ngIf="paginacion.primero > 1"><a class="page-link" (click)="cambiarPagina(-2)">...</a></li>
        <li class="page-item" [ngClass]="{'active':paginacion.actual==n}"
        *ngFor="let n of paginacion.pags | slice:paginacion.primero-1:paginacion.ultimo; let i = index"><a class="page-link"
          (click)="cambiarPagina(n)">{{n}}</a></li>
          <li class="page-item" *ngIf="paginacion.ultimo < paginacion.numPags"><a class="page-link" (click)="cambiarPagina(-3)">...</a></li>
        <li class="page-item" [ngClass]="{'disabled':paginacion.actual==paginacion.numPags}"><a class="page-link" (click)="cambiarPagina(0)">&raquo;</a></li>
      </ul>
     </div>
   </div-->
 </div>
 </div>
 <div class="card-footer bg-light">
     <div class="botones d-flex justify-content-end">
         <!--button class="btn btn-primary mr-2" (click)="mostrarFiltro();" [disabled]='true'>  Filtrar  </button-->
         <!--button class="btn btn-success mr-2" [disabled]='true'>Imprimir</button-->
         <button class="btn btn-danger" (click)="cerrarCatalogo()">  Cerrar  </button>
     </div>
 </div>
 </div>
 <!--Ventana Información Calificación-->
 <div class="modal backdrop" tabindex="-1" role="dialog" name="detalleResultado" #detalleResultado>
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Detalles resultados del curso {{nombreCurso}} </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="cerrarVentanaInfo()" >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="table-responsive">
          <table class="table table-sm table-hover table-striped">
            <thead class="thead-light">
              <tr>
                <th>Fecha</th>
                <th>Prueba</th>
                <th>Valor</th>
                <th>Obtenido</th>
            </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of detalles">
                <td>{{item.fecha | date: 'dd/MM/yyyy'}}</td>
                <td>{{item.titulo}}</td>
                <td>{{item.valor}}%</td>
                <td class='center-block'>{{item.obtenido}}%</td>
              </tr>
            </tbody>
          </table>
        </div>
       </div>
      <div class="modal-footer d-flex justify-content-end">
        <div>
        <button type="button" class="btn btn-secondary ml-2" data-dismiss="modal"  (click)="cerrarVentanaInfo()" >Cerrar</button>
      </div>
      </div>
    </div>
  </div>
</div>
