import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AuthenticationService } from '../shared/services/authentication.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  returnUrl: string;
  error = '';
  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService
  ) { }
  ngOnInit() {
    this.loginForm = this.fb.group({
      usuario: ['', Validators.required],
      passw: ['', Validators.required]
    });
    // resetear status de loggeo
    this.authenticationService.logout();
    // this.authenticationService.sendMessage('Login', -1);
    // get return url from route parameters or defalt to '/'
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/';  //// OJO AQUÍ ESTÁ LA COSA
    // console.log(this.route.snapshot.queryParams.returnUrl);
  }
onSubmit() {
  if (this.loginForm.invalid) {
    return;
  }
  this.authenticationService.login(
    this.loginForm.controls.usuario.value,
    this.loginForm.controls.passw.value)
      .pipe(first())
      .subscribe(
        data => {
          this.router.navigate([this.returnUrl]);  //// OJO AQUÍ ESTÁ LA COSA
          this.authenticationService.sendMessage(true, data.rol);
          this.error = '';
        },
        error => {
          this.error = error;
          console.log(error);
          this.authenticationService.sendMessage(false, -1);
        }
      );
}
}
