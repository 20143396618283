import { FiltrarService } from './../shared/services/filtrar.service';
import { Component, OnInit,  ViewChild, Renderer2, ElementRef  } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CatalogoService } from './../shared/services/catalogo.service';
import { Pregunta } from '../shared/models/pregunta.model';
import { Router, ActivatedRoute } from '@angular/router';
import { FileOploadService } from './../shared/services/file-opload.service';
import { PaginacionService } from './../shared/services/paginacion.service';
//import { EditorChangeContent, EditorChangeSelection } from 'ngx-quill';
//import * as InlineEditor from '../../app/build/ckeditor';
import * as CKEDITOR from '../../app/build/ckeditor';
// import * as customBuild from '../ckeditor/ckeditor';


@Component({
  selector: 'app-preguntas',
  templateUrl: './preguntas.component.html'
  // styleUrls: ['./preguntas.component.css','../../mathquill/mathquill.css']
})

// TODO: Actualizar la paginación, enviando los datos que se modifican, enviando toda la variable pagina y
// trayendo todos los datos a página para no usar servicio.paginacion.etc....

export class PreguntasComponent implements OnInit {
  public Editor = CKEDITOR.ClassicEditor;
  public EditorInfo = CKEDITOR.InlineEditor;
  regs;
  mensajeExcel = '';
  archivo = {
    nombreArchivo: null,
    base64textString: null
  };
  idPrueba;
  campoFiltro = 'texto';
  tituloCatalogo; // = 'Catálogo Preguntas';
  pregunta = new Pregunta();
  lista = [];
  frmPrincipal: FormGroup;
  frmExcel: FormGroup;

  tituloForm = '';
  errorDup = '';
  vacio;
  filtroActual;
  cantidadRegs: number;
  subirVisible = false;
 /* configEdit = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{'list': 'ordered'}, {'list':'bullet'}],
      [{'indent': '-1'}, {'indent':'+1'}],
      ['image'],
      ['table']
    ]
  }*/
  @ViewChild('ventanaPrincipal', { static: true }) ventanaPrincipal: ElementRef;
  @ViewChild('ventanaInfo', { static: true }) ventanaInfo: ElementRef;
  @ViewChild('ventanaConfirm', { static: true }) ventanaConfirm: ElementRef;
  @ViewChild('ventanaSubir', { static: true }) ventanaSubir: ElementRef;
  @ViewChild('bloqueFiltro') bloqueFiltro: ElementRef;
  constructor(private fb: FormBuilder, private renderizar: Renderer2,
              private catalogoService: CatalogoService, private router: Router,
              private ruta: ActivatedRoute,
              private uploadService: FileOploadService,
           //   private _data:DataService,
              public paginacion: PaginacionService,
              public filtrar: FiltrarService) {
      this.frmPrincipal = this.fb.group({
        idPregunta: [''],
        idPrueba: [''],
        texto: [''], // [Validators.required, Validators.minLength(7)]],
        Opcion1: ['', Validators.required ],
        Opcion2: ['', Validators.required ],
        Opcion3: ['', Validators.required ],
        Opcion4: [''],
        opcionCorrecta: ['', Validators.required ],
        funcion: ['N']
      });
      this.frmExcel = this.fb.group({
        file: ['', Validators.required]
      });
  }

 
  strip_html_tags(str) {
    if ((str===null) || (str===''))
        return false;
    else
    str = str.toString();
    str = str.replace(":",': ');
    str = str.replace(".",'. ');
    return (str.replace(/<[^>]*>/g, '').slice(0,100))+"...";
  }
  
  onReady( editor) {
    editor.isReadOnly = true;
  }
/*
  public onFocus(editor) {
    console.log(editor);
    //editor.ui.getEditableElement().parentElement.insertBefore(
      //editor.ui.view.toolbar.element
    //  editor.ui.getEditableElement(),
  // );
  
  //document.querySelector( '#sidebar' ).style.display = 'none';
}
  editor(quill) {
   // console.log(quill);
    this.editorInstance = quill;
    let toolbar = quill.getModule('toolbar');
    toolbar.addHandler('image', this.imageEditor.bind(this))
  }
  imageEditor() {
    let data: any = this.editorInstance;
    if (this.editorInstance != null) {
      let range = this.editorInstance.getSelection();
      if (range != null) {
        let input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept','image/*');
        input.addEventListener('change', ()=>{
          if(input.files != null) {
            let file = input.files[0]
            if(file != null) {
              let dataFile = new FormData();
              dataFile.append('image', file);
              let reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = function() {
                if(reader.readyState == 2) {
                  let base64rsult = reader.result;
                  data.insertEmbed(range.index, 'image', base64rsult);
                }
              }
            }
          }
        });
        input.click();
      }
    }
      console.log(this.editorInstance)
  }
  cambioPregunta(e: EditorChangeContent | EditorChangeSelection) {
    // this.frmPrincipal.value.texto=  e.html
    this.frmPrincipal.controls.texto.patchValue(e.editor.root.innerHTML);
   //console.log(e.editor.root.innerHTML)
  // console.log(this.frmPrincipal.controls.texto.value);
  }*/
  cambioCant(e) {
    this.paginacion.cambioCant(e.target.value);
    this.filtrarM({});
  }
  cambiarPagina(p) {
    this.paginacion.cambiarPagina(p);
    if (!this.filtrar.visible) {
      this.filtrarM({idPrueba: this.idPrueba});
    } else {
      this.filtrarM(this.filtrar.filtro);
    }
  }
  guardar() {
   let resultado;
   if (this.frmPrincipal.invalid) {return; }
   const pregunta = new Pregunta(this.frmPrincipal.value);
   pregunta.idPrueba = this.idPrueba;
  // pregunta.texto = this.frmPrincipal.value.texto;
   switch (this.frmPrincipal.value.funcion) {
      case 'N':
        this.catalogoService.guardar(pregunta)
         .subscribe(
          res => resultado = res,
           err => console.log(err),
           () => {
            if (resultado[0] === '1') {
              this.errorDup = 'Código duplicado';
            } else {
              this.errorDup = '';
              this.refrescar();
              this.cerrarVentana(1);
            }
            }
           );
        break;
      case 'E':
       // prueba.idPrueba = this.codigo;
        this.catalogoService.guardar(pregunta, pregunta.idPregunta)
          .subscribe(
            res => {res},
            err => {return console.log(err);
            },
            () => {
              this.refrescar();
              this.cerrarVentana(1);
            }
            );
   }
  }
  nuevo() {
    this.tituloForm = 'Nueva Pregunta';
    this.frmPrincipal.reset({funcion: 'N', idPrueba: this.idPrueba});
   // this.limpiarTexto();

    // this.frmPrincipal.controls.idPrueba.enable();
    this.errorDup = ''; // <-- 2- para error de código

    this.renderizar.addClass(this.ventanaPrincipal.nativeElement, 'show');
    this.renderizar.setStyle(this.ventanaPrincipal.nativeElement, 'display', 'block');
  }
  editar(codigo) {
   this.tituloForm = 'Modificar Pregunta';
   this.errorDup = '';
   this.filtrar.ejecutar({idPregunta: codigo})
     .then(
       snapshot => {
         snapshot.subscribe(
            data => {
              data = data[1];
              this.frmPrincipal.setValue({
                idPregunta: (data as Pregunta).idPregunta,
                idPrueba : (data as Pregunta).idPrueba,
                texto : (data as Pregunta).texto,
                Opcion1: (data as Pregunta).Opcion1,
                Opcion2: (data as Pregunta).Opcion2,
                Opcion3: (data as Pregunta).Opcion3,
                Opcion4: (data as Pregunta).Opcion4,
                opcionCorrecta: (data as Pregunta).opcionCorrecta,
                funcion : 'E'
              });
              //this.limpiarTexto();
              //this.agregarTexto((data as Pregunta).texto);
            }
          );
        }
      );
   this.renderizar.addClass(this.ventanaPrincipal.nativeElement, 'show');
   this.renderizar.setStyle(this.ventanaPrincipal.nativeElement, 'display', 'block');
  }
  mostrarSubir() {
    this.subirVisible = true;
    this.renderizar.addClass(this.ventanaSubir.nativeElement, 'show');
    this.renderizar.setStyle(this.ventanaSubir.nativeElement, 'display', 'block');
  }
  info(codigo) {
    this.tituloForm = 'Información de pregunta con código ' + codigo;
    this.filtrar.ejecutar({idPregunta: codigo})
    .then(
      snapshot => {
        snapshot.subscribe(
            data => {
              this.pregunta = (data[1] as Pregunta);
            });
        }
    );
    
    this.renderizar.addClass(this.ventanaInfo.nativeElement, 'show');
    this.renderizar.setStyle(this.ventanaInfo.nativeElement, 'display', 'block');
  }
  eliminar() {
    this.catalogoService.eliminar(this.pregunta.idPregunta)
        .subscribe(
           () => this.refrescar() // this.cursos = this.cursos.filter(e => e.codigo !== this.curso.codigo)
        );
    this.cerrarVentana(2);
  }
  confirmarEliminar(pregunta) {
    this.tituloForm = 'Confirmación';
    this.renderizar.addClass(this.ventanaConfirm.nativeElement, 'show');
    this.renderizar.setStyle(this.ventanaConfirm.nativeElement, 'display', 'block');
    this.pregunta.idPregunta = pregunta.idPregunta;
    this.pregunta.texto = pregunta.texto;
  }
  refrescar() {
    this.filtrar.cantidad({idPrueba: this.idPrueba})
    .then(
      snapshot => {
        snapshot.subscribe(
          data => {
            this.cantidadRegs = Number(data);
            this.paginacion.calcularPiecera(Number(data));
          }
        );
      }
    );
    this.filtrarM({idPrueba: this.idPrueba});
  }
  cerrarVentana(v: number) {
    switch (v) {
      case 1:
        this.renderizar.removeClass(this.ventanaPrincipal.nativeElement, 'show');
        this.renderizar.removeStyle(this.ventanaPrincipal.nativeElement, 'display');
        break;
      case 2:
        this.renderizar.removeClass(this.ventanaConfirm.nativeElement, 'show');
        this.renderizar.removeStyle(this.ventanaConfirm.nativeElement, 'display');
        break;
      case 3:
        this.renderizar.removeClass(this.ventanaInfo.nativeElement, 'show');
        this.renderizar.removeStyle(this.ventanaInfo.nativeElement, 'display');
        break;
      case 4:
          this.renderizar.removeClass(this.ventanaSubir.nativeElement, 'show');
          this.renderizar.removeStyle(this.ventanaSubir.nativeElement, 'display');

    }
  }
  cerrarCatalogo() {
    this.router.navigate(['/'], { skipLocationChange: true, replaceUrl: true });
  }
  regresarPrueba() {
    this.router.navigate(['pruebas']);
  }
  seleccionarArchivo(event) {
    const files = event.target.files;
    const file = files[0];
    this.archivo.nombreArchivo = file.name;
    this.frmExcel.setValue({file: this.archivo.nombreArchivo});

    if (files && file) {
      const reader = new FileReader();
      reader.onload = this._handleReaderLoaded.bind(this);
      reader.readAsBinaryString(file);
    }
  }
  _handleReaderLoaded(readerEvent) {
    const binaryString = readerEvent.target.result;
    this.archivo.base64textString = btoa(binaryString);
  }
  upload() {
    let regs = 0;
    this.mensajeExcel = '';
    this.uploadService.uploadFile(this.idPrueba, this.archivo)
    .subscribe(
      datos => {
        if ((datos as any).mensaje) {
          this.mensajeExcel = (datos as any).mensaje;
        }
        if ((datos as any).regs) {
          regs = (datos as any).regs;
        }
        if (regs > 0) {
          this.regs = regs;
          this.subirVisible = false;
          setTimeout(() => {
              this.cerrarVentana(4);
            }, 3000);
        }

        this.refrescar();
      }
    );
  }
  mostrarFiltro() {
    this.filtrar.toggleVisible();
    this.refrescar();
  }
  ordenar(campo) {
    this.filtrar.ordenar(campo);
    this.filtrarM(this.filtrar.filtro);
  }
  filtrarM(filtro, e?) {
    filtro.idPrueba = this.idPrueba;
    this.filtroActual = filtro;
    if ((e) && this.filtrar.invalidChar( e.key )) {
      return;
    }
    if (e) {
      this.paginacion.actual = 1;
    }
    this.filtrar.cantidad(filtro)
    .then(
      res => {
        res.subscribe(
          data => this.paginacion.calcularPiecera(Number(data))
        );
      }
    );
    this.filtrar.ejecutar(filtro, this.paginacion.actual, this.paginacion.limite)
      .then(
        snapshot => {
          snapshot.subscribe(
            data => {
               this.lista.length = 0;
              for (const prop in data) {
                if (prop) {
                  const dato = {
                    idPregunta: data[prop].idPregunta,
                    texto : data[prop].texto
                  };
                  this.lista.push(dato);
                }
              }
              this.vacio = this.lista.length === 0;
            }
          );
        }
      );
  }
  ngOnInit() {
    this.paginacion.iniciar();
    
    this.ruta.params.subscribe(params => {
      this.idPrueba = params.id;
      }
     );
    this.catalogoService.setServidor('pregunta');
    this.catalogoService.filtroEspecial(this.idPrueba)
      .subscribe (
        res => {
          if (res) {
            this.tituloCatalogo = (res as any)[1].titulo + ' de ' + (res as any)[1].curso;
          }
        } ,
        err => console.log(err)
      );
    this.filtrar.inicializar({idPrueba: this.idPrueba}, 'preguntas', 'texto');

    this.refrescar();
  }
}
