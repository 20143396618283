import { Injectable } from '@angular/core';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { UserOptions } from 'jspdf-autotable';

// tslint:disable-next-line: class-name
interface jsPDFWithPlugin extends jsPDF {
  autoTable: (options: UserOptions) => jsPDF;
}
@Injectable({
  providedIn: 'root'
})
export class ImprimirService {

  constructor() { }
  debugBase64(base64URL) {
    const win = window.open();
    win.document.write('<iframe src="' +
      base64URL  +
      '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>');
  }
  imprimir(columna, filas, encabezado, guardar?) {
    const doc  = new jsPDF('portrait', 'px', 'letter') as jsPDFWithPlugin;
    doc.text(encabezado, (doc.internal.pageSize.width / 2), 25, {align: 'center'});
    //var datos = doc.autoTableHtmlToJson(filas);
    doc.autoTable({
      head: columna,
    body: filas  // Aquí está el cuerpo de la prueba (texto, elegido, correcto,...)
    });
    if (guardar) {
      const hoy = new Date();
      doc.save(hoy.getDate() + hoy.getMonth() + hoy.getFullYear() + hoy.getTime() + '.pdf');
    } else {
      const cadena = doc.output('datauristring');
      this.debugBase64(cadena);
    }
}
}
