export class Pregunta {
  idPregunta?: number;
  idPrueba: number;
  texto: string;
  Opcion1: string;
  Opcion2: string;
  Opcion3: string;
  Opcion4?: string;
  opcionCorrecta: number;
  constructor(prueba?) {
    if (prueba) {
      if (prueba.idPregunta) {
        this.idPregunta = prueba.idPregunta;
      }
      this.idPrueba = prueba.idPrueba;
      this.idPrueba = prueba.titulo;
      this.texto = prueba.texto;
      this.Opcion1 = prueba.Opcion1;
      this.Opcion2 = prueba.Opcion2;
      this.Opcion3 = prueba.Opcion3;
      this.Opcion4 = prueba.Opcion4;
      this.opcionCorrecta = prueba.opcionCorrecta;
    }
  }
}
