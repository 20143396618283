<div class="container">
  <div class="card">
    <div class="card-header bg-light">
       <h2 class="text-center">Catálogo de Profesor</h2>
    </div>
    <div class="card-body">
     <div *ngIf="filtrar.visible" class="container animated slideInDown d-flex mb-2"
       name="bloqueFiltro" #bloqueFiltro>
         <form action="" class="form-inline d-flex">
           <div class="form-group me-2">
               <input type="text" class="form-control  form-control-sm" placeholder="Nombre"
               #fNombre (keyup)="filtrarM({nombre:fNombre.value, apellido1:fApellido1.value, apellido2:fApellido2.value},$event)"
               >
           </div>
           <div class="form-group me-2">
            <input type="text" class="form-control  form-control-sm" placeholder="Apellido 1"
            #fApellido1 (keyup)="filtrarM({nombre:fNombre.value, apellido1:fApellido1.value, apellido2:fApellido2.value},$event)"
            >
            </div>
            <div class="form-group">
              <input type="text" class="form-control  form-control-sm" placeholder="Apellido 2"
              #fApellido2 (keyup)="filtrarM({nombre:fNombre.value, apellido1:fApellido1.value, apellido2:fApellido2.value},$event)"
              >
            </div>
          </form>
          <button class="btn btn-danger btn-sm ms-3" (click)="mostrarFiltro()">&times;</button>
     </div>
     <div class="table-responsive">
   <table class="table table-sm table-hover table-striped">
     <thead class="thead-light">
      <tr [ngClass]="{'dropup':filtrar.orden.modo===1}">
         <th>Id</th>
         <th class="cursor-orden" (click)="ordenar('nombre')">Nombre
          <i [ngClass]="{'dropdown-toggle':filtrar.orden.campo==='nombre'}"></i>
         </th>
         <th class="cursor-orden" (click)="ordenar('apellido1')">Apellido 1
          <i [ngClass]="{'dropdown-toggle':filtrar.orden.campo==='apellido1'}"></i>
         </th>
         <th class="cursor-orden" (click)="ordenar('apellido2')">Apellido 2
          <i [ngClass]="{'dropdown-toggle':filtrar.orden.campo==='apellido2'}"></i>
         </th>
         <th>Teléfono</th>
         <th>Correo</th>
         <th colspan="3" class="text-center">
           <button class="btn btn-outline btn-sm btn-success"(click)="nuevo()" title="Nuevo">
              <fa-icon [icon]="['fas', 'plus']"></fa-icon>
            </button>
         </th>
     </tr>
     </thead>
     <tbody *ngIf="!vacio">
       <tr *ngFor="let item of lista">
         <td>{{item.ID}}</td>
         <td>{{item.nombre}}</td>
         <td>{{item.apellido1}}</td>
         <td>{{item.apellido2}}</td>
         <td>{{item.telefono}}</td>
         <td>{{item.correo}}</td>         
         <td class="text-center">
            <button class="btn btn-outline btn-info btn-sm " (click)="confirmar(item, 1)" title="Resetear contraseña">
                <fa-icon [icon]="['fas', 'key']"></fa-icon>
            </button>
          </td>
         <td class="text-center">
           <button class="btn btn-outline btn-warning btn-sm " (click)="editar(item.ID)" title="Modificar">
              <fa-icon [icon]="['fas', 'pencil-alt']"></fa-icon>
           </button>
         </td>
         <td class="text-center">
           <button class="btn btn-outline btn-danger btn-sm" (click)="confirmar(item, 2)" title="Eliminar">
             <fa-icon [icon]="['fas', 'trash']"></fa-icon>
           </button>
         </td>
       </tr>
     </tbody>
   </table>
  </div>
  <div class="alert alert-warning text-center"*ngIf="vacio">No se encontraron coincidencias</div>
   <div class="d-flex" *ngIf="!vacio">
     <div class="me-3">
       <select name="" id="" class="form-control"  (change)="cambioCant($event)">
           <option value="2" [selected]="paginacion.limite===2">2</option>
           <option value="5" [selected]="paginacion.limite===5">5</option>
           <option value="10" [selected]="paginacion.limite===10">10</option>
           <option value="15" [selected]="paginacion.limite===20">15</option>
           <option value="20" [selected]="paginacion.limite===15">20</option>
           <option value="30" [selected]="paginacion.limite===30">30</option>
           <option value="50" [selected]="paginacion.limite===50">50</option>
           <option value="75" [selected]="paginacion.limite===75">75</option>
           <option value="100" [selected]="paginacion.limite===100">100</option>
       </select>
     </div>
     <div>
      <ul class="pagination pagination-md">
        <li class="page-item" [ngClass]="{'disabled':paginacion.actual==1}"><a class="page-link" (click)="cambiarPagina(-1)" >&laquo;</a></li>
        <li class="page-item" *ngIf="paginacion.primero > 1"><a class="page-link" (click)="cambiarPagina(-2)">...</a></li>
        <li class="page-item" [ngClass]="{'active':paginacion.actual==n}"
        *ngFor="let n of paginacion.pags | slice:paginacion.primero-1:paginacion.ultimo; let i = index"><a class="page-link"
          (click)="cambiarPagina(n)">{{n}}</a></li>
          <li class="page-item" *ngIf="paginacion.ultimo < paginacion.numPags"><a class="page-link" (click)="cambiarPagina(-3)">...</a></li>
        <li class="page-item" [ngClass]="{'disabled':paginacion.actual==paginacion.numPags}"><a class="page-link" (click)="cambiarPagina(0)">&raquo;</a></li>
      </ul>
     </div>
   </div>
 </div>
 </div>
 <div class="card-footer bg-light">
     <div class="botones d-flex justify-content-end">
         <button class="btn btn-primary me-2" (click)="mostrarFiltro();">  Filtrar  </button>
         <button class="btn btn-success me-2" (click)="onImprimir()">Imprimir</button>
         <button class="btn btn-danger" (click)="cerrarCatalogo()">  Cerrar  </button>
     </div>
 </div>
 </div>
 <!--Ventana Profesor-->
 <div class="modal backdrop" tabindex="-1" role="dialog" name="ventanaProfesor" #ventanaProfesor>
   <div class="modal-dialog animated fadeInDown" role="document">
     <div class="modal-content">
       <div class="modal-header bg-success">
         <h5 class="modal-title">{{tituloForm}}</h5>
         <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close" (click)="cerrarVentana(1)">
         </button>
       </div>
       <div class="modal-body bg-light">
         <form [formGroup]="frmProfesor" name="frmProfesor" id="frmProfesor"
           (ngSubmit)="guardar()" class="form-fluid">
           <div class="row mb-2">
              <label for="" class="col-sm-3 col-form-label">Id</label>
              <div class="col-sm-9">
              <input type="text" formControlName="ID" name="ID" class="form-control col-8" placeholder="Id">
            </div>
           </div>
           <div class="alert alert-danger" *ngIf="frmProfesor.controls['ID'].invalid && frmProfesor.controls['ID'].touched">
             <div *ngIf="frmProfesor.controls['ID'].errors['required']">El campo Id es requerido</div>
             <div *ngIf="frmProfesor.controls['ID'].errors['errordup']">Id de Profesor duplicado</div>
          </div>
          <div class="row mb-2">
            <label for="" class="col-sm-3 col-form-label">Nombre</label>
            <div class="col-sm-9">
              <input type="text" formControlName="nombre" name="nombre" class="form-control col-8" placeholder="Nombre">
            </div>
          </div>
          <div class="alert alert-danger" *ngIf="frmProfesor.controls['nombre'].invalid && frmProfesor.controls['nombre'].touched">
             <div *ngIf="frmProfesor.controls['nombre'].errors['required']">El campo nombre es requerido</div>
             <div *ngIf="frmProfesor.controls['nombre'].errors['minlength']">El Nombre debe tener al menos 3 caracteres</div>
           </div>
           <div class="row mb-2">             
            <label for="" class="col-sm-3 col-form-label">Apellido 1</label>
            <div class="col-sm-9">
              <input type="text" formControlName="apellido1" name="apellido1" class="form-control col-8" placeholder="Apellido 1">
            </div>
           </div>
           <div class="alert alert-danger" *ngIf="frmProfesor.controls['apellido1'].invalid && frmProfesor.controls['apellido1'].touched">
              <div *ngIf="frmProfesor.controls['apellido1'].errors['required']">El campo apellido 1 es requerido</div>
              <div *ngIf="frmProfesor.controls['apellido1'].errors['minlength']">El Nombre debe tener al menos 3 caracteres</div>
           </div>
           <div class="row mb-2">
            <label for="" class="col-sm-3 col-form-label">Apellido 2</label>
            <div class="col-sm-9">
              <input type="text" formControlName="apellido2" name="apellido2" class="form-control col-8" placeholder="Apellido 2">
            </div>
          </div>
           <div class="alert alert-danger" *ngIf="frmProfesor.controls['apellido2'].invalid && frmProfesor.controls['apellido2'].touched">
              <div *ngIf="frmProfesor.controls['apellido2'].errors['required']">El campo apellido 2 es requerido</div>
              <div *ngIf="frmProfesor.controls['apellido2'].errors['minlength']">El Nombre debe tener al menos 3 caracteres</div>
          </div>
          <div class="row mb-2">
            <label for="" class="col-sm-3 col-form-label">Correo</label>
            <div class="col-sm-9">
              <input type="text" formControlName="correo" name="correo" class="form-control col-8" placeholder="Correo">
            </div>
        </div>
         <div class="alert alert-danger" *ngIf="frmProfesor.controls['correo'].invalid && frmProfesor.controls['correo'].touched">
            <div *ngIf="frmProfesor.controls['correo'].errors['required']">El campo correo es requerido</div>
            <div *ngIf="frmProfesor.controls['correo'].errors['email']">Formato del correo no válido</div>
        </div>          
          <div class="row">
            <label for="" class="col-sm-3 col-form-label">Teléfono</label>
            <div class="col-sm-9">
              <input type="text" formControlName="telefono" name="telefono" class="form-control col-8" placeholder="Teléfono">
            </div>
          </div>
          <div class="alert alert-danger mt-2" *ngIf="frmProfesor.controls['telefono'].invalid && frmProfesor.controls['telefono'].touched">
              <div *ngIf="frmProfesor.controls['telefono'].errors['required']">El campo Teléfono es requerido</div>
              <div *ngIf="frmProfesor.controls['telefono'].errors['minlength']">El Teléfono debe tener al menos 8 caracteres</div>
           </div>

           <div class="form-check form-switch d-flex justify-content-center mt-2">
            <input class="form-check-input" type="checkbox" value="" id="Estado" name="Estado" formControlName="Estado">
            <label class="form-check-label ms-1" for="Estado">
              Activar Usuario
            </label>
          </div>
             <input type="hidden" formControlName="funcion" name="funcion">
         </form>
       </div>
       <div class="modal-footer bg-success">
         <button type="submit" form="frmProfesor" class="btn btn-primary"
         [disabled]="!frmProfesor.valid">Guardar</button>
         <button type="button" class="btn btn-secondary" data-dismiss="modal"(click)="cerrarVentana(1)">Cerrar</button>
       </div>
     </div>
   </div>
 </div>
 <!--Ventana Confirmación-->
 <div class="modal backdrop" tabindex="-1" role="dialog" name="ventanaConfirm" #ventanaConfirm>
   <div class="modal-dialog animated fadeInDown" role="document">
     <div class="modal-content">
       <div class="modal-header" [ngClass]="{'bg-danger': accionConfirmar == 2, 'bg-info': accionConfirmar == 1}">
        <h5 class="modal-title">{{tituloForm}}</h5>
         <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close" (click)="cerrarVentana(2)">
         </button>
       </div>
       <div class="modal-body">
        <p>{{mensajeEliminar}} {{profesor.nombre}} {{profesor.apellido1}} {{profesor.apellido2}}</p>
        <p *ngIf="tituloForm === 'Advertencia'">Se encontró registro en grupo(s)</p>
       </div>
       <div class="modal-footer">
         <button type="button" class="btn btn-primary" (click)="onConfirmar()"
         *ngIf="tituloForm === 'Confirmación' || tituloForm === 'Contraseña'">Aceptar</button>
         <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="cerrarVentana(2)">Cancelar</button>
       </div>
     </div>
   </div>
 </div>
