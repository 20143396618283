<div class="container">
  <div class="card">
    <div class="card-header bg-light">
       <h2 class="text-center">{{tituloCatalogo}}</h2>
    </div>
    <div class="card-body">
     <div *ngIf="filtrar.visible" class="container animated slideInDown d-flex mb-2"
       name="bloqueFiltro" #bloqueFiltro>
         <form action="" class="form-inline d-flex">
           <div class="form-group me-2">
               <input type="text" class="form-control form-control-sm" placeholder="cod Curso" #fcodCurso
                      (keyup)="filtrarM({codCurso:fcodCurso.value, titulo:fTitulo.value}, $event)">
           </div>
           <div class="form-group">
               <input type="text" class="form-control form-control-sm" placeholder="Título"
               #fTitulo (keyup)="filtrarM({codCurso:fcodCurso.value, titulo:fTitulo.value}, $event)">
           </div>
          </form>
          <button class="btn btn-sm btn-danger ms-3" (click)="mostrarFiltro()">&times;</button>
     </div>
 <div class="table-responsive">
   <table class="table table-sm table-hover table-striped">
     <thead class="thead-light">
      <tr [ngClass]="{'dropup':filtrar.orden.modo===1}">
        <th class="cursor-orden" (click)="ordenar('codCurso')">Curso
          <i [ngClass]="{'dropdown-toggle':filtrar.orden.campo==='codCurso'}"></i>
         </th>
        <th class="cursor-orden" (click)="ordenar('titulo')">Título
          <i [ngClass]="{'dropdown-toggle':filtrar.orden.campo==='titulo'}"></i>
         </th>
         <th>Valor</th>
         <th colspan="2" class="text-center">
           <button class="btn btn-outline btn-sm btn-success"(click)="nuevo()" title="Nueva Prueba">
              <fa-icon [icon]= "['fas', 'plus']"></fa-icon>
           </button>
         </th>
     </tr>
     </thead>
     <tbody *ngIf="!vacio">
       <tr *ngFor="let item of lista">
        <td>{{item.codCurso}}</td>
        <td>{{item.titulo}}</td>
         <td>{{item.valor}}</td>
         <td class="text-center">
           <button class="btn btn-outline btn-warning btn-sm " (click)="editar(item.idPrueba)" title="Editar">
              <fa-icon [icon]= "['fas', 'pencil-alt']"></fa-icon>
           </button>
         </td>
         <td class="text-center">
           <button class="btn btn-outline btn-danger btn-sm" (click)="confirmarEliminar(item)" title="Eliminar">
              <fa-icon [icon]= "['fas', 'trash']"></fa-icon>
           </button>
         </td>
       </tr>
     </tbody>
   </table>
 </div>
 <div class="alert alert-warning text-center" *ngIf="vacio">No se encontraron coincidencias</div>
   <div class="d-flex" *ngIf="!vacio">
     <div class="me-3">
       <select name="" id="" class="form-control"  (change)="cambioCant($event)">
           <option value="2">2</option>
           <option value="5" selected>5</option>
           <option value="10">10</option>
           <option value="15">15</option>
           <option value="20">20</option>
           <option value="30">30</option>
           <option value="50">50</option>
           <option value="75">75</option>
           <option value="100">100</option>
       </select>
     </div>
     <div>
      <ul class="pagination pagination-md">
        <li class="page-item" [ngClass]="{'disabled':paginacion.actual==1}"><a class="page-link" (click)="cambiarPagina(-1)" >&laquo;</a></li>
        <li class="page-item" *ngIf="paginacion.primero > 1"><a class="page-link" (click)="cambiarPagina(-2)">...</a></li>
        <li class="page-item" [ngClass]="{'active':paginacion.actual==n}"
        *ngFor="let n of paginacion.pags | slice:paginacion.primero-1:paginacion.ultimo; let i = index"><a class="page-link"
          (click)="cambiarPagina(n)">{{n}}</a></li>
          <li class="page-item" *ngIf="paginacion.ultimo < paginacion.numPags"><a class="page-link" (click)="cambiarPagina(-3)">...</a></li>
        <li class="page-item" [ngClass]="{'disabled':paginacion.actual==paginacion.numPags}"><a class="page-link" (click)="cambiarPagina(0)">&raquo;</a></li>
      </ul>
     </div>
   </div>
 </div>
 </div>
 <div class="card-footer bg-light">
     <div class="botones d-flex justify-content-end">
         <button class="btn btn-primary me-2" (click)="mostrarFiltro();">  Filtrar  </button>
         <button class="btn btn-danger" (click)="cerrarCatalogo()">  Cerrar  </button>
     </div>
 </div>
 </div>
 <!--Ventana Principal-->
 <div class="modal backdrop" tabindex="-1" role="dialog" name="ventanaPrincipal" #ventanaPrincipal>
   <div class="modal-dialog animated fadeInDown" role="document">
     <div class="modal-content">
       <div class="modal-header bg-success">
         <h5 class="modal-title">{{tituloForm}}</h5>
         <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close" (click)="cerrarVentana(1)">
         </button>
       </div>
       <div class="modal-body bg-light">
         <form [formGroup]="frmPrincipal" name="frmPrincipal" id="frmPrincipal"
           (ngSubmit)="guardar()" class="form-fluid">
           <input type="hidden" formControlName="idPrueba" name="idPrueba">
           <div class="mb-2" style="display: grid; grid-template-columns: 1fr 3fr;">
                 <label for="" class="">Título</label>
                 <input type="text" formControlName="titulo" name="titulo" class="form-control" placeholder="Título de Prueba">
           </div>
           <div class="alert alert-danger" *ngIf="frmPrincipal.controls['titulo'].invalid && frmPrincipal.controls['titulo'].touched">
              <div *ngIf="frmPrincipal.controls['titulo'].errors['required']">El campo Título es requerido</div>
              <div *ngIf="frmPrincipal.controls['titulo'].errors['minlength']">El Título debe tener al menos 7 caracteres</div>
           </div>

           <div class="mb-2" style="display: grid; grid-template-columns: 1fr 3fr;">
              <label for="">Cod Curso</label>
              <input type="text" formControlName="codCurso" name="codCurso" class="form-control text-uppercase"
              placeholder="cod Curso" (keyup)="onKeyUp();">
            </div>
           <div class="alert alert-danger" *ngIf="frmPrincipal.controls['codCurso'].invalid && frmPrincipal.controls['codCurso'].touched">
              <div *ngIf="frmPrincipal.controls['codCurso'].errors['required']">El campo cod-Curso es requerido</div>
              <div *ngIf="frmPrincipal.controls['codCurso'].errors['pattern']">Formato del codigo Curso AAA-####</div>
              <div *ngIf="frmPrincipal.controls['codCurso'].errors['noexiste']">El Curso no existe</div>
           </div>
           <div class="alert alert-success" *ngIf="nombreCurso !== ''">
            {{nombreCurso}}
           </div>
           <div class="mb-1" style="display: grid; grid-template-columns: 1fr 3fr;">
              <label for="valor">Valor</label>
              <input type="text" formControlName="valor" name="valor" id="valor" class="form-control" placeholder="valor">
           </div>
           <div class="alert alert-danger" *ngIf="frmPrincipal.controls['valor'].invalid && frmPrincipal.controls['valor'].touched">
              <div *ngIf="frmPrincipal.controls['valor'].errors['required']">El campo Valor es requerido</div>
              <div *ngIf="frmPrincipal.controls['valor'].errors['pattern']">Debe ser un número con o sin decimales (10.5)</div>
           </div>
             <input type="hidden" formControlName="tipo" name="tipo">
             <input type="hidden" formControlName="funcion" name="funcion">
         </form>
       </div>
       <div class="modal-footer bg-success">
         <button type="submit" form="frmPrincipal" class="btn btn-primary"
         [disabled]="!frmPrincipal.valid">Guardar</button>
         <button type="button" class="btn btn-secondary" data-dismiss="modal"(click)="cerrarVentana(1)">Cerrar</button>
       </div>
     </div>
   </div>
 </div>
 <!--Ventana Confirmación-->
 <div class="modal backdrop" tabindex="-1" role="dialog" name="ventanaConfirm" #ventanaConfirm>
   <div class="modal-dialog" role="document">
     <div class="modal-content">
       <div class="modal-header" [ngClass]="{'bg-warning': tituloForm == 'Advertencia', 'bg-danger': tituloForm !== 'Advertencia'}">
         <h5 class="modal-title">{{tituloForm}}</h5>
         <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close" (click)="cerrarVentana(2)">
         </button>
       </div>
       <div class="modal-body">
        <p>{{mensajeEliminar}}</p>
        <p>{{prueba.titulo}}</p>
        <p *ngIf="tituloForm === 'Advertencia'">La prueba tiene calificaciones asignadas</p>
       </div>
       <div class="modal-footer">
         <button type="button" class="btn btn-primary" (click)="eliminar()"
         *ngIf="tituloForm === 'Confirmación'">Eliminar</button>
         <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="cerrarVentana(2)">Cancelar</button>
       </div>
     </div>
   </div>
 </div>
