import { environment } from './../../environments/environment';
// /environments/environment';
// import * as urlservidor from 'src/assets/json/config.json';
export class Servidor {
  public URL: string;
  constructor() {
    this.URL = environment.servidor;
  //  this.URL = urlservidor.default.servidor;
  }
}
