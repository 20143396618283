import { EntrarNotasComponent } from './entrar-notas/entrar-notas.component';
import { ActivarExamenComponent } from './activar-examen/activar-examen.component';
import { GrupoProfesorComponent } from './grupo-profesor/grupo-profesor.component';
import { CanDeactivateGuard } from './shared/guards/can-deactivate.guard';
import { NotasEstComponent } from './notas-est/notas-est.component';
import { ResultadoComponent } from './resultado/resultado.component';
import { PruebasentComponent } from './pruebasent/pruebasent.component';
import { CambioContrasComponent } from './cambio-contras/cambio-contras.component';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './shared/guards/auth.guard';
import { CatAlumComponent } from './cat-alum/cat-alum.component';
import { HomeComponent } from './home/home.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CatAdminComponent } from './cat-admin/cat-admin.component';
import { CatProfComponent } from './cat-prof/cat-prof.component';
import { CatCursosComponent } from './cat-cursos/cat-cursos.component';
import { PruebasComponent } from './pruebas/pruebas.component';
import { Role } from './shared/models/roles.model';
import { PreguntasComponent } from './preguntas/preguntas.component';
import { GrupoComponent } from './grupo/grupo.component';
import { MatriculaComponent } from './matricula/matricula.component';
import { PruebaComponent } from './prueba/prueba.component';
import { VerNotasComponent } from './ver-notas/ver-notas.component';
//import { InfoPreguntaComponent } from './info-pregunta/info-pregunta.component';


const routes: Routes = [
  { path: '', component : HomeComponent },
  { path: 'login', component: LoginComponent },
  { path: 'cat-admin',
    component : CatAdminComponent,
    canActivate: [AuthGuard],
    data: {roles: [Role.Admin]}
  },
  { path: 'cat-prof',
    component : CatProfComponent,
    canActivate: [AuthGuard],
    data: {roles: [Role.Admin]}
  },
  { path: 'cat-alum',
    component : CatAlumComponent,
    canActivate: [AuthGuard],
    data: {roles: [Role.Admin]}
  },
  { path: 'cat-curso',
    component : CatCursosComponent,
    canActivate: [AuthGuard],
    data: {roles: [Role.Admin]}
  },
  { path: 'pruebas',
  component : PruebasComponent,
  canActivate: [AuthGuard],
  data: {roles: [Role.Admin]}
  },
  { path: 'preguntas/:id',
  component : PreguntasComponent,
  canActivate: [AuthGuard],
  data: {roles: [Role.Admin]}
  },
  { path: 'cambio-contras',
  component : CambioContrasComponent,
  canActivate: [AuthGuard],
  data: {roles: [Role.Admin, Role.Profesor, Role.Alumno]}
  },
  { path: 'grupo',
  component : GrupoComponent,
  canActivate: [AuthGuard],
  data: {roles: [Role.Admin]}
  },
  { path: 'grupo-prof',
  component : GrupoProfesorComponent,
  canActivate: [AuthGuard],
  data: {roles: [Role.Admin, Role.Profesor]}
  },
  { path: 'matricula/:grupo',
  component : MatriculaComponent,
  canActivate: [AuthGuard],
  data: {roles: [Role.Admin]}
  },
  { path: 'activar-examen/:grupo/:idPrueba/:titulo/:origen',
  component : ActivarExamenComponent,
  canActivate: [AuthGuard],
  data: {roles: [Role.Admin, Role.Profesor]}
  },
  { path: 'ver-notas/:grupo/:origen',
  component : VerNotasComponent,
  canActivate: [AuthGuard],
  data: {roles: [Role.Admin, Role.Profesor]}
  },
  { path: 'entrar-notas/:grupo/:idPrueba/:titulo/:valor', // 'entrar-notas/:grupo/:carnet',
  component : EntrarNotasComponent,
  canActivate: [AuthGuard],
  data: {roles: [Role.Admin, Role.Profesor]}
  },
  { path: 'pruebasent',
  component : PruebasentComponent,
  canActivate: [AuthGuard],
  data: {roles: [Role.Admin]}
  },
  { path: 'prueba',
  component : PruebaComponent,
  canActivate: [AuthGuard],
  // canDeactivate: [CanDeactivateGuard],
  data: {roles: [Role.Admin, Role.Alumno]}
  },
  { path: 'resultado',
  component : ResultadoComponent,
  canActivate: [AuthGuard],
  data: {roles: [Role.Admin, Role.Alumno]}
  },
  { path: 'notasest',
  component : NotasEstComponent,
  canActivate: [AuthGuard],
  data: {roles: [Role.Admin, Role.Alumno]}
  },
 /* { path: 'info/:codigoPregunta',
  component : InfoPreguntaComponent,
  canActivate: [AuthGuard],
  data: {roles: [Role.Admin]}
  }*/
  { path: '**', redirectTo: '', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
