import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Servidor } from '../servidor';

@Injectable({
  providedIn: 'root'
})
export class NotasService {
  servidor = new Servidor();
  constructor(private http: HttpClient) {  }
  async verNotasAlumnos(grupo) {
    const respuesta = await this.http.get(`${this.servidor.URL}/notas/lista/${grupo}`);
    return respuesta;
  }
  async editarNotasAlumnos(grupo, carnet) {
    const respuesta = await this.http.get(`${this.servidor.URL}/notas/grupo/${grupo}|${carnet}`);
    return respuesta;
  }
  async notasAlumnos(codGrupo, idPrueba) {
    const respuesta = await this.http.get(`${this.servidor.URL}/notas/editar/${codGrupo}/${idPrueba}`);
    return respuesta;
  }
  async notasGuardar(idPrueba, datos) {
    const respuesta = await this.http.post(`${this.servidor.URL}/notas/guardar/${idPrueba}`, datos);
    return respuesta;
  }
  async obtenerNotas(carnet) {
    const respuesta = await this.http.get(`${this.servidor.URL}/notas/${carnet}`);
    return respuesta;
  }
  async obtenerDetalleNotas(idDetalle) {
    const respuesta = await this.http.get(`${this.servidor.URL}/notas/detalle/${idDetalle}`);
    return respuesta;
  }
}
