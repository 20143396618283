import { Component, OnInit, ViewChild, Renderer2, ElementRef,
         OnDestroy } from '@angular/core';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { PruebaService } from './../shared/services/prueba.service';
import { AuthenticationService } from './../shared/services/authentication.service';
import { Router } from '@angular/router';
import { Resultado } from './../shared/models/resultado';
import { ContadorComponent } from './../contador/contador.component';
import * as CKEDITOR from '../../app/build/ckeditor';

@Component({
  selector: 'app-prueba',
  templateUrl: './prueba.component.html',
  styleUrls: ['./prueba.component.css']
})
export class PruebaComponent implements OnInit, OnDestroy {
  public Editor = CKEDITOR.ClassicEditor;
  public EditorInfo = CKEDITOR.InlineEditor;
  textotemp = '';
  prueba = {
    tiempo: '',
    activa: '0',
    valor: 0,
    idDetalle: 0
  };
  pruebaActual;
  carnet;
  lista;
  listas = [];
  isLoaded = false;
  frmExamen: FormGroup;
  aviso = false;
  enviado = false;
  resultados  = [];
  resultados2: Resultado[] = [];
  numCorrectos = 0;
  noPreguntas;
  resultado;
  @ViewChild('ventanaConfirmEnvio', { static: true }) ventanaConfirmEnvio: ElementRef;
  @ViewChild('ventanaConfirm', { static: true }) ventanaConfirm: ElementRef;
  @ViewChild('ventanaResultado') ventanaResultado: ElementRef;
  @ViewChild('contador', { read: ContadorComponent }) private contador: ContadorComponent;


  constructor(private fb: FormBuilder,
              private pruebaService: PruebaService,
              private authService: AuthenticationService,
              private renderizar: Renderer2,
              private router: Router,
            ) {
    }
  onReady( editor ) {
      editor.isReadOnly = true;
  }
  armarExamen(datos) {
    const group = {};
    for (const prop in datos) {
      if (prop) {
        const dato = {
          label : 'p' + (datos[prop]) .idPregunta,
          texto : (datos[prop]).texto,
          opcion1 : (datos[prop]).opcion1,
          opcion2 : (datos[prop]).opcion2,
          opcion3 : (datos[prop]).opcion3,
          opcion4 : (datos[prop]).opcion4 ? (datos[prop]).opcion4 : null
        };
        this.listas.push(dato);

        group['p' + (datos[prop]).idPregunta] = new FormControl('');
        group['p' + (datos[prop]).idPregunta] = new FormControl('');
        group['p' + (datos[prop]).idPregunta] = new FormControl('');

        if ((datos[prop]).opcion4) {
          group['p' + (datos[prop]) .idPregunta] = new FormControl('');
        }
      }
    }
    this.noPreguntas = this.listas.length;
    (group as any).valor = new FormControl(this.prueba.valor);
    this.frmExamen = this.fb.group(group);
  }
  armarResultados(datos) {
    const group = {};
    for (const prop in datos) {
      if (prop && prop !== 'correctos' && prop !== 'resultado') {
        this.resultados2.push(new Resultado(
          (datos[prop]).texto,
          (datos[prop]).rCorrecta,
          (datos[prop]).rElegida,
          (datos[prop]).opcionCorrecta === (datos[prop]).opcionElegida
        ));
        this.resultados.push(
          {
          texto : (datos[prop]) .texto,
          rCorrecta : (datos[prop]).rCorrecta,
          rElegida : (datos[prop]).rElegida,
          correcta : (datos[prop]).opcionCorrecta === (datos[prop]).opcionElegida
        });
      }
    }
    this.resultados.push({
      datos: {
        numCorrectos: datos.correctos,
        resultado: datos.resultado,
        noPreguntas: this.noPreguntas,
        pruebaActual: this.pruebaActual,
     //   pruebaActiva: this.prueba.activa,
     //   idDetalle: this.prueba.idDetalle
      }
    });
    this.numCorrectos = datos.correctos;
    this.resultado = datos.resultado;
  }
  revisar() {
    this.enviado = true;
    this.contador.subscripcionActual.unsubscribe();
    const pruebaEnvio = this.prueba.idDetalle + '-' + this.prueba.activa;
    this.pruebaService.revisarPrueba(this.frmExamen.value, pruebaEnvio)
      .then(
        snapshot => {
          snapshot.subscribe(
            res => {
              this.armarResultados(res);
              localStorage.setItem('resultados', JSON.stringify(this.resultados));
              this.router.navigate(['/resultado']);
              }
          );
        }
      );
  }
  onSubmit() {
    // if (confirm('Seguro que deseas enviar la prueba para revisión'))
    this.renderizar.addClass(this.ventanaConfirmEnvio.nativeElement, 'show');
    this.renderizar.setStyle(this.ventanaConfirmEnvio.nativeElement, 'display', 'block');
    //  this.revisar();
  }
  onConfirmarEnvio() {
    this.revisar();
  }
  cerrarConfirmarEnvio() {
    this.renderizar.removeClass(this.ventanaConfirmEnvio.nativeElement, 'show');
    this.renderizar.removeStyle(this.ventanaConfirmEnvio.nativeElement, 'display');
  }
  inicio() {
    this.renderizar.addClass(this.ventanaConfirm.nativeElement, 'show');
    this.renderizar.setStyle(this.ventanaConfirm.nativeElement, 'display', 'block');
  }
  extraerTiempo(t): number {
    const tA = t.split(':');
    return parseInt(tA[0], 10) * 60 * 60 + parseInt(tA[1], 10) * 60;
  }
  empezar() {
    this.pruebaService.armarPrueba(this.prueba.activa, this.carnet)
    .then(
      snapshot2 => {
          snapshot2.subscribe(
              res2 => {
                  this.armarExamen(res2);
                  this.isLoaded = true;
                  const t = this.extraerTiempo(this.prueba.tiempo);
                  // const t = this.extrarTiempo('00:06');
                  // const t = 20;
                  this.contador.startAt =  t;
                  this.contador.Iniciar();
                  this.contador.estadoContador
                    .subscribe(
                      estado => {
                        if (estado === 'COMPLETO') {
                          this.autoEnvio();
                        }
                        if (estado === 'AVISO') {
                          this.aviso = true;
                        }
                      }
                    );

              }
          );
        }
  );
    this.cerrarVentana();
  }
  cerrarVentana() {
        this.renderizar.removeClass(this.ventanaConfirm.nativeElement, 'show');
        this.renderizar.removeStyle(this.ventanaConfirm.nativeElement, 'display');
  }
  salir() {
    this.cerrarVentana();
    this.router.navigate(['/'], { skipLocationChange: true, replaceUrl: true });
  }
  autoEnvio() {
    this.revisar();
  }
  ngOnInit() {
    this.carnet = this.authService.currentUserValue.id;
    this.pruebaService.pruebaActiva(this.carnet)
    .then(
      snapshot => {
        snapshot.subscribe(
          res => {
            if ((res as any).status && (res as any).status === 204) {
              this.pruebaActual = 'No tienes prueba Activa';
              this.prueba.activa = '0';
            } else {
              this.prueba.valor = res[1].valor;
              this.prueba.activa = res[1].pruebaActiva;
              this.prueba.idDetalle = res[1].detalleActivo;
              this.prueba.tiempo = res[1].duracion;
              this.pruebaActual = res[1].titulo + ', curso ' + res[1].codCurso;
              this.inicio();
            }
          }
        );
      }
    );
  }
  ngOnDestroy() {
    if (this.contador && this.contador.subscripcionActual) {
      this.contador.subscripcionActual.unsubscribe();
    }
  }
}

