<div class="container">
  <div class="card">
    <div class="card-header bg-light">
       <h2 class="text-center">Catálogo de Grupos</h2>
    </div>
    <div class="card-body">
     <div *ngIf="filtrar.visible" class="container animated slideInDown d-flex mb-2"
       name="bloqueFiltro" #bloqueFiltro>
         <form class="form-inline d-flex">
           <div class="form-group me-2">
               <input type="text" class=" form-control form-control-sm" placeholder="cod Grupo" #fCodGrupo
               (keyup)="filtrarM({codGrupo:fCodGrupo.value, codProfesor:fcodProfesor.value,
                        codCurso:fcodCurso.value})">
           </div>
           <div class="form-group me-2">
               <input type="text" class=" form-control form-control-sm" placeholder="id Profesor" #fcodProfesor
               (keyup)="filtrarM({codGrupo:fCodGrupo.value, codProfesor:fcodProfesor.value,
                        codCurso:fcodCurso.value})">
           </div>
           <div class="form-group">
              <input type="text" class=" form-control form-control-sm" placeholder="cod Curso" #fcodCurso
              (keyup)="filtrarM({codGrupo:fCodGrupo.value, codProfesor:fcodProfesor.value,
                      codCurso:fcodCurso.value})">
          </div>
          <!--div class="form-group me-3">
              <label for="" class="me-1">Inicio</label>
              <input type="date" class="form-control-sm" #ffechaInicio
              (change)="filtrarM({codGrupo:fCodGrupo.value, codProfesor:fcodProfesor.value,
                      codCurso:fcodCurso.value, fechaInicio: ffechaInicio.value})">
          </div-->
          </form>
          <button class="btn btn-sm btn-danger ms-3" (click)="mostrarFiltro()">&times;</button>
     </div>
     <div class="table-responsive">
   <table class="table table-sm table-hover table-striped">
     <thead class="thead-light">
      <tr [ngClass]="{'dropup':filtrar.orden.modo===1}">
        <th class="cursor-orden" (click)="ordenar('codGrupo')">Grupo
          <i [ngClass]="{'dropdown-toggle':filtrar.orden.campo==='codGrupo'}"></i>
        </th>
        <th class="cursor-orden" (click)="ordenar('codProfesor')">Profesor
          <i [ngClass]="{'dropdown-toggle':filtrar.orden.campo==='codProfesor'}"></i>
        </th>
        <th class="cursor-orden" (click)="ordenar('codCurso')">Curso
          <i [ngClass]="{'dropdown-toggle':filtrar.orden.campo==='codCurso'}"></i>
        </th>
        <th class="cursor-orden" (click)="ordenar('fechaInicio')">Inicio
          <i [ngClass]="{'dropdown-toggle':filtrar.orden.campo==='fechaInicio'}"></i>
        </th>
         <th>Horario</th>
         <th class="text-center">
           <button class="btn btn-outline btn-sm btn-success"(click)="nuevo()" title="Nuevo grupo">
              <fa-icon [icon]="['fas', 'plus']"></fa-icon>
            </button>
         </th>
     </tr>
     </thead>
     <tbody *ngIf="!vacio">
       <tr *ngFor="let item of lista">
         <td>{{item.codGrupo}}</td>
         <td>{{item.codProfesor}}</td>
         <td>{{item.codCurso}}</td>
         <td>{{item.fechaInicio | date: 'dd/MM/yyyy'}}</td>
         <td>{{item.horario}}</td>
         <td class="text-center">
           <button class="btn btn-outline btn-warning btn-sm me-3 " (click)="editar(item.codGrupo)" title="Editar grupo">
              <fa-icon [icon]="['fas', 'pencil-alt']"></fa-icon>
           </button>
           <button class="btn btn-outline btn-danger btn-sm me-3" (click)="confirmarEliminar(item)" title="Eliminar grupo">
             <fa-icon [icon]="['fas', 'trash']"></fa-icon>
           </button>
           <button class="btn btn-outline btn-primary btn-sm me-3" (click)="matricular(item.codGrupo)" title="Matricular en este grupo">
            <fa-icon [icon]="['fas', 'calendar-alt']"></fa-icon>
          </button>
          <button class="btn btn-outline btn-info btn-sm me-3" (click)="mostrarPruebas(item.codGrupo)" title="Activar Examen">
            <fa-icon [icon]="['fas', 'user-edit']"></fa-icon>
          </button>
          <button class="btn btn-outline btn-secondary btn-sm" (click)="verNotas(item.codGrupo)" title="Ver Notas">
            <fa-icon [icon]="['fas', 'newspaper']"></fa-icon>
          </button>
        </td>
       </tr>
     </tbody>
   </table>
 </div>
 <div class="alert alert-warning text-center"*ngIf="vacio">No se encontraron coincidencias</div>
   <div class="d-flex" *ngIf="!vacio">
     <div class="me-3">
       <select name="" id="" class="form-control"  (change)="cambioCant($event)">
           <option value="2">2</option>
           <option value="5" selected>5</option>
           <option value="10">10</option>
           <option value="15">15</option>
           <option value="20">20</option>
           <option value="30">30</option>
           <option value="50">50</option>
           <option value="75">75</option>
           <option value="100">100</option>
       </select>
     </div>
     <div>
         <ul class="pagination pagination-md">
             <li class="page-item" [ngClass]="{'disabled':paginacion.actual==1}"><a class="page-link" (click)="cambiarPagina(-1)" >&laquo;</a></li>
             <li class="page-item" *ngIf="paginacion.primero > 1"><a class="page-link" (click)="cambiarPagina(-2)">...</a></li>
             <li class="page-item" [ngClass]="{'active':paginacion.actual==n}"
             *ngFor="let n of paginacion.pags | slice:paginacion.primero-1:paginacion.ultimo; let i = index"><a class="page-link"
               (click)="cambiarPagina(n)">{{n}}</a></li>
               <li class="page-item" *ngIf="paginacion.ultimo < paginacion.numPags"><a class="page-link" (click)="cambiarPagina(-3)">...</a></li>
             <li class="page-item" [ngClass]="{'disabled':paginacion.actual==paginacion.numPags}"><a class="page-link" (click)="cambiarPagina(0)">&raquo;</a></li>
           </ul>
     </div>
   </div>
 </div>
 </div>
 <div class="card-footer bg-light">
     <div class="botones d-flex justify-content-end">
         <button class="btn btn-primary me-2" (click)="mostrarFiltro();">  Filtrar  </button>
         <button class="btn btn-success me-2" (click)="onImprimir()">Imprimir</button>
         <button class="btn btn-danger" (click)="cerrarCatalogo();">  Cerrar  </button>
     </div>
 </div>
 </div>
 <!--Ventana Principal-->
 <div class="modal backdrop" tabindex="-1" role="dialog" name="ventanaPrincipal" #ventanaPrincipal>
   <div class="modal-dialog modal-md animated fadeInDown" role="document">
     <div class="modal-content">
       <div class="modal-header bg-success">
         <h5 class="modal-title">{{tituloForm}}</h5>
         <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close" (click)="cerrarVentana(1)">
         </button>
       </div>
       <div class="modal-body bg-light">
         <form [formGroup]="frmPrincipal" name="frmPrincipal" id="frmPrincipal"
           (ngSubmit)="guardar()" class="form-fluid">
           <div class="form-group row mb-1">
              <label for="" class="col-sm-3 col-form-label">Código</label>
              <div class="col-sm-9">
                 <input type="text" formControlName="codGrupo" name="codGrupo" class="form-control" placeholder="Código Grupo">
              </div>
           </div>
           <div class="alert alert-danger" *ngIf="frmPrincipal.controls['codGrupo'].invalid && frmPrincipal.controls['codGrupo'].touched">
              <div *ngIf="frmPrincipal.controls['codGrupo'].errors['required']">El campo codigo grupo es necesario</div>
              <div *ngIf="frmPrincipal.controls['codGrupo'].errors['minlength']">El Nombre debe tener al menos 7 caracteres</div>
           </div>
           <div class="form-group row mb-1">
            <label for="" class="col-sm-3 col-form-label">Id Profesor</label>
            <div class="col-sm-9">
                <input type="text" formControlName="codProfesor" name="codProfesor"
              class="form-control col-8" placeholder="Código Profesor"
              (keyup)="onKeyUp(1);" (blur)="onBlur(1)">
            </div>
          </div>
          <div class="alert alert-danger" *ngIf="frmPrincipal.controls['codProfesor'].invalid && frmPrincipal.controls['codProfesor'].touched">
            <div *ngIf="frmPrincipal.controls['codProfesor'].errors['required']">El campo codigo profesor es necesario</div>
            <div *ngIf="frmPrincipal.controls['codProfesor'].errors['minlength']">El Id debe tener al menos 7 caracteres</div>
            <div *ngIf="frmPrincipal.controls['codProfesor'].errors['noexiste']">El Profesor no existe</div>
          </div>
          <div class="alert alert-success" *ngIf="bProfesor.nombre !== ''">
            {{bProfesor.nombre}}
        </div>
          <div class="form-group row mb-1">
            <label for="" class="col-sm-3 col-form-label">Cod Curso</label>
            <div class="col-sm-9">
              <input type="text" formControlName="codCurso" name="codCurso" class="form-control text-uppercase" 
              placeholder="Código Curso" (keyup)="onKeyUp(2);">
            </div>
          </div>
          <div class="alert alert-danger" *ngIf="frmPrincipal.controls['codCurso'].invalid && frmPrincipal.controls['codCurso'].touched">
            <div *ngIf="frmPrincipal.controls['codCurso'].errors['required']">El campo codigo curso es necesario</div>
            <div *ngIf="frmPrincipal.controls['codCurso'].errors['minlength']">El codigo debe tener 8 caracteres</div>
            <div *ngIf="frmPrincipal.controls['codCurso'].errors['pattern']">Formato incorrecto (AAA-####)</div>
            <div *ngIf="frmPrincipal.controls['codCurso'].errors['noexiste']">El Curso no existe</div>
          </div>
        <div class="alert alert-success" *ngIf="bCurso.nombre !== ''">
          {{bCurso.nombre}}
      </div>
          <div class="form-group row mb-1">
            <label for="" class="col-sm-3 col-form-label">Fecha Inicio</label>
            <div class="col-sm-9">
              <input type="date" formControlName="fechaInicio" name="fechaInicio" class="form-control" 
              style="cursor:pointer;" placeholder="Código Curso">
            </div>
          </div>
          <div class="alert alert-danger" *ngIf="frmPrincipal.controls['fechaInicio'].invalid && frmPrincipal.controls['fechaInicio'].touched">
            <div *ngIf="frmPrincipal.controls['fechaInicio'].errors['required']">El campo fecha inicio es necesario</div>
          </div>
          <div class="form-group row mb-1">
            <label for="" class="col-sm-3 col-form-label">Hora|</label>
            <div class="col-sm-9">
              <input type="time" formControlName="horario" name="horario" class="form-control" 
              style="cursor:pointer;" placeholder="Hora curso">
            </div>
          </div>
          <div class="alert alert-danger" *ngIf="frmPrincipal.controls['horario'].invalid && frmPrincipal.controls['horario'].touched">
            <div *ngIf="frmPrincipal.controls['horario'].errors['required']">El campo Hora es necesario</div>
          </div>
          <input type="hidden" formControlName="funcion" name="funcion">
         </form>
       </div>
       <div class="modal-footer bg-success">
         <button type="submit" form="frmPrincipal" class="btn btn-primary"
         [disabled]="!frmPrincipal.valid">Guardar</button>
         <button type="button" class="btn btn-secondary" data-dismiss="modal"(click)="cerrarVentana(1)">Cerrar</button>
       </div>
     </div>
   </div>
 </div>
 <!--Ventana Confirmación-->
 <div class="modal backdrop" tabindex="-1" role="dialog" name="ventanaConfirm" #ventanaConfirm>
   <div class="modal-dialog" role="document">
     <div class="modal-content">
       <div class="modal-header bg-warning">
         <h5 class="modal-title">{{tituloForm}}</h5>
         <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close" (click)="cerrarVentana(2)">
         </button>
       </div>
       <div class="modal-body">
         <p>{{mensajeEliminar}} {{grupo.codGrupo}}</p>
         <p *ngIf="tituloForm === 'Advertencia'">Tiene matrículas asignadas</p>
       </div>
       <div class="modal-footer">
         <button type="button" class="btn btn-primary" (click)="eliminar()"
          *ngIf="tituloForm === 'Confirmación'"
         >Eliminar</button>
         <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="cerrarVentana(2)">Cancelar</button>
       </div>
     </div>
   </div>
 </div>
 <!--Ventana Pruebas de grupo-->
 <div class="modal backdrop" tabindex="-1" role="dialog" name="ventanaPruebas" #ventanaPruebas>
  <div class="modal-dialog modal-md animated fadeInDown" role="document">
    <div class="modal-content">
      <div class="modal-header bg-info">
        <h5 class="modal-title">{{tituloForm}}</h5>
        <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close" (click)="cerrarVentana(3)">
        </button>
      </div>
      <div class="modal-body">
        <table class="table table-sm">
          <tr>
           <th>Examen</th>
           <th>Valor</th>
           <th>Duración</th>
           <th>&nbsp;</th>
          </tr>
          <tr *ngFor="let item of pruebas">
            <td>{{item.titulo}}</td>
            <td>{{item.valor}}</td>
            <td>{{item.duracion}}</td>
            <td>
              <button type="button" class="btn btn-info" (click)="activarExamen(item.idPrueba, item.titulo)">
                Activar
              </button>
            </td>
          </tr>
        </table>
      </div>
      <div class="modal-footer">
        <!--button type="button" class="btn btn-primary" (click)="eliminar()"
         *ngIf="tituloForm === 'Confirmación'"
        >Eliminar</button-->
        <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="cerrarVentana(3)">Cancelar</button>
      </div>
    </div>
  </div>
</div>