import { ImprimirService } from './../shared/services/imprimir.service';
import { FiltrarService } from './../shared/services/filtrar.service';
import { PruebaService } from './../shared/services/prueba.service';
import { Grupo } from './../shared/models/grupo.model';
import { Component, OnInit,  ViewChild, Renderer2, ElementRef  } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CatalogoService } from './../shared/services/catalogo.service';
import { map } from 'rxjs/operators';
import { PaginacionService } from './../shared/services/paginacion.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-grupo',
  templateUrl: './grupo.component.html',
  styleUrls: ['./grupo.component.css']
})
export class GrupoComponent implements OnInit {
  regs;
  vacio;
  mensajeEliminar = '';
  bProfesor = {
    nombre: '',
    ID: ''
  };
  bCurso = {
    nombre: '',
    codigo: ''
  };
//  nombreProfesor = '';
//  nombreCurso = '';

  codGrupo: string;
  campoFiltro = 'texto';
  tituloCatalogo: string;
  grupo = new Grupo();
  lista = [];
  pruebas;
  idPrueba;
  frmPrincipal: FormGroup;
  tituloForm = '';
  filtroActual;
  cantidadRegs: number;
  @ViewChild('ventanaPrincipal', { static: true }) ventanaPrincipal: ElementRef;
  @ViewChild('ventanaConfirm', { static: true }) ventanaConfirm: ElementRef;
  @ViewChild('ventanaPruebas', { static: true }) ventanaPruebas: ElementRef;
  @ViewChild('bloqueFiltro') bloqueFiltro: ElementRef;
  constructor(private fb: FormBuilder, private renderizar: Renderer2,
              private catalogoService: CatalogoService,
              private pruebaService: PruebaService,
              public paginacion: PaginacionService,
              public filtrar: FiltrarService,
              private router: Router,
              private imprimirSrv: ImprimirService) {
      this.frmPrincipal = this.fb.group({
        codGrupo: ['', [ Validators.required, Validators.minLength(7), Validators.pattern('[0-9]{2}-[0-9]{4}')]],
        codProfesor: ['', [Validators.required, Validators.minLength(7)]],
        codCurso: ['', [Validators.required, Validators.minLength(8), Validators.pattern('[0-9A-Za-z]{3}-[0-9]{4}')]],
        fechaInicio: ['', Validators.required],
        horario: ['', Validators.required],
        funcion: ['N']
      });
      this.frmPrincipal.controls.codGrupo.disable();
      this.catalogoService.setServidor('grupo');
  }
  onBlur(n) {
    switch (n) {
      case 1:
        if (this.frmPrincipal.controls.codProfesor.valid) {
          if (this.frmPrincipal.controls.codProfesor.value !== this.bProfesor.ID) {
            this.frmPrincipal.controls.codProfesor.setValue(this.bProfesor.ID);
          }
        }
        break;
      case 2:
    }
  }
  onKeyUp(n) {
    switch (n) {
      case 1:
          if (this.frmPrincipal.controls.codProfesor.valid) {
            this.buscarProfesor(this.frmPrincipal.controls.codProfesor.value);
          } else {
            this.bProfesor.nombre = '';
          }
          break;
      case 2:
          if (this.frmPrincipal.controls.codCurso.valid) {
            this.buscarCurso(this.frmPrincipal.controls.codCurso.value);
          } else {
            this.bCurso.nombre = '';
          }
    }
  }
  buscarProfesor(ID) {
    this.filtrar.buscarProfesor(ID)
     .then(
        snapshot => {
          snapshot.subscribe(
            res => {
              if (res !== null) {
                res = res[1];
                this.bProfesor.nombre = (res as any).nombre + ' ' + (res as any).apellido1 + ' ' + (res as any).apellido2;
                this.bProfesor.ID = (res as any).ID;
                this.frmPrincipal.controls.codProfesor.setErrors(null);
              } else {
                this.bProfesor.nombre = '';
                this.bProfesor.ID = '';
                this.frmPrincipal.controls.codProfesor.setErrors({noexiste: true});
              }
            }
          );
        }

     );
  }
  buscarCurso(curso) {
    this.filtrar.buscarCurso(curso)
     .then(
        snapshot => {
          snapshot.subscribe(
            res => {
              if (res !== null) {
                res = res[1];
                this.bCurso.nombre = (res as any).nombre;
                this.bCurso.codigo = (res as any).codigo;
                this.frmPrincipal.controls.codCurso.setErrors(null);
              } else {
                this.bCurso.nombre = '';
                this.bCurso.codigo = '';
                this.frmPrincipal.controls.codCurso.setErrors({noexiste: true});
              }
            }
          );
        }
     );
  }
  cambioCant(e) {
    this.paginacion.cambioCant(e.target.value);
    this.filtrarM({});
  }
  cambiarPagina(p) {
    this.paginacion.cambiarPagina(p);
    if (!this.filtrar.visible) {
      this.filtrarM({});
    } else {
      this.filtrarM(this.filtrar.filtro);
    }
  }
  refrescar() {
    this.filtrar.cantidad({})
    .then(
      res => {
        res.subscribe(
          data => {
          this.cantidadRegs = Number(data);
          this.paginacion.calcularPiecera(Number(data));
        }
        );
      }
    );
    this.filtrarM({});
  }
  mostrarFiltro() {
    this.filtrar.toggleVisible();
    this.refrescar();
  }
  ordenar(campo) {
    this.filtrar.ordenar(campo);
    this.filtrarM(this.filtrar.filtro);
  }
  filtrarM(filtro, e?) {
    this.filtroActual = filtro;
    if ((e) && this.filtrar.invalidChar( e.key )) {
      return;
    }
    if (e) {
      this.paginacion.actual = 1;
    }
    this.filtrar.cantidad(filtro)
    .then(
      res => {
        res.subscribe(
          data => this.paginacion.calcularPiecera(Number(data))
        );
      }
    );
    this.filtrar.ejecutar(filtro, this.paginacion.actual, this.paginacion.limite)
      .then(
        snapshot => {
          snapshot.subscribe(
            data => {
              this.lista.length = 0;
              for (const prop in data) {
                if (prop) {
                  const dato = {
                    codGrupo: data[prop].codGrupo,
                    codProfesor : data[prop].codProfesor,
                    fechaInicio: data[prop].fechaInicio,
                    codCurso: data[prop].codCurso,
                    horario: data[prop].horario,
                  };
                  this.lista.push(dato);
                }
              }
              this.vacio = this.lista.length === 0;
            }
          );
        }
      );
  }
  // Manipulación de datos
  guardar() {
    let resultado;
    if (this.frmPrincipal.invalid) {return; }
    const grupo = new Grupo(this.frmPrincipal.value);
    grupo.codGrupo = this.codGrupo;
    grupo.codCurso = grupo.codCurso.toUpperCase();
    switch (this.frmPrincipal.value.funcion) {
       case 'N':
         this.catalogoService.guardar(grupo)
          .subscribe(
           res => resultado = res,
            err => console.log(err),
            () => {
             if (resultado[0] === '1') {
               this.frmPrincipal.controls.codGrupo.setErrors({errordup: true});
             } else {
               this.refrescar();
               this.cerrarVentana(1);
             }
             }
            );
         break;
       case 'E':
         this.catalogoService.guardar(grupo, grupo.codGrupo)
           .subscribe(
             // res => console.log(res),
             // err => console.log(err),
             () => {
               this.refrescar();
               this.cerrarVentana(1);
             }
             );
    }
  }
  nuevo() {
     this.tituloForm = 'Nuevo Grupo';
     this.bProfesor.nombre = '';
     this.bCurso.nombre = '';
     this.catalogoService.sigCodGrupo()
     .pipe(
      map(res => res)
    )
    .subscribe(
      data => {
        this.codGrupo = (data as any).codGrupo;
        this.frmPrincipal.reset({funcion: 'N', codGrupo : (data as any).codGrupo});
      }
    );
     // this.frmPrincipal.controls.idPrueba.enable();
     this.renderizar.addClass(this.ventanaPrincipal.nativeElement, 'show');
     this.renderizar.setStyle(this.ventanaPrincipal.nativeElement, 'display', 'block');
  }
  editar(codigo) {
    this.tituloForm = 'Modificar Grupo';
    this.filtrar.ejecutar({codGrupo: codigo, UsuarioEstado: codigo})
      .then(
        snapshot => {
          snapshot.subscribe(
            data => {
              data = data[1];
              this.codGrupo = (data as Grupo).codGrupo;
              this.frmPrincipal.setValue({
                codGrupo: (data as Grupo).codGrupo,
                codProfesor : (data as Grupo).codProfesor,
                codCurso : (data as Grupo).codCurso,
                fechaInicio: (data as Grupo).fechaInicio,
                horario: (data as Grupo).horario,
                funcion : 'E'
              });
            }
          );
        }
      );
    this.renderizar.addClass(this.ventanaPrincipal.nativeElement, 'show');
    this.renderizar.setStyle(this.ventanaPrincipal.nativeElement, 'display', 'block');
  }
  eliminar() {
    this.catalogoService.eliminar(this.grupo.codGrupo)
      .subscribe(
        () => this.refrescar() // this.cursos = this.cursos.filter(e => e.codigo !== this.curso.codigo)
      );
    this.cerrarVentana(2);
  }
  confirmarEliminar(grupo) {
    this.filtrar.buscarRegistro(grupo.codGrupo, false, false, false, false, false)
    .then (
      snapshot => {
        snapshot.subscribe(
          data => {
            this.grupo.codGrupo = grupo.codGrupo;
            if (data === null) {
              this.tituloForm = 'Confirmación';
              this.mensajeEliminar = 'Eliminar grupo';
            } else {
              this.mensajeEliminar = 'No se puede eliminar el grupo';
              this.tituloForm = 'Advertencia';
            }
            this.renderizar.addClass(this.ventanaConfirm.nativeElement, 'show');
            this.renderizar.setStyle(this.ventanaConfirm.nativeElement, 'display', 'block');
          }
        );
      }
    );
  }
  matricular(grupo) {
    this.router.navigate(['matricula', grupo]);
  }
  mostrarPruebas(grupo) {
    this.codGrupo = grupo;
    this.pruebaService.mostrarPruebas(grupo, 1)
      .then(
        snapshot => {
          snapshot.subscribe(
            data => {
              if (data) {
                this.pruebas = data;
                const me = this;
                if (me.pruebas) {
                  me.pruebas = Object.keys(me.pruebas).map( key => {
                    return me.pruebas[key];
                  });
                  this.tituloForm = this.pruebas[0].curso;
                  this.idPrueba = this.pruebas[0].idPrueba;
              } else {
                  this.pruebas = null;
              }
              }
            }
          );
        }
      );
    this.renderizar.addClass(this.ventanaPruebas.nativeElement, 'show');
    this.renderizar.setStyle(this.ventanaPruebas.nativeElement, 'display', 'block');
  }
  activarExamen(idPrueba, titulo) {
    this.router.navigate(['activar-examen', this.codGrupo, idPrueba, titulo, '0']);
  }
  verNotas(grupo) {
    this.router.navigate(['ver-notas', grupo, '0']);
  }
  // Manejo de Ventanas
  cerrarVentana(v: number) {
    switch (v) {
      case 1:
        this.renderizar.removeClass(this.ventanaPrincipal.nativeElement, 'show');
        this.renderizar.removeStyle(this.ventanaPrincipal.nativeElement, 'display');
        break;
      case 2:
        this.renderizar.removeClass(this.ventanaConfirm.nativeElement, 'show');
        this.renderizar.removeStyle(this.ventanaConfirm.nativeElement, 'display');
        break;
      case 3:
            this.renderizar.removeClass(this.ventanaPruebas.nativeElement, 'show');
            this.renderizar.removeStyle(this.ventanaPruebas.nativeElement, 'display');

    }
  }
  cerrarCatalogo() {
    this.router.navigate(['/'], { skipLocationChange: true, replaceUrl: true });
  }
  onImprimir() {
    const columna = [['Grupo', 'Profesor', 'Curso', 'Inicio', 'Horario']];
    const fila = [];
    this.filtrar.ejecutar(this.filtroActual, 1, this.cantidadRegs)
    .then(
      snapshot => {
        snapshot.subscribe(
          data => {
            for (const prop in data) {
              if (prop) {
                const dato = [
                  data[prop].codGrupo,
                  data[prop].codProfesor,
                  data[prop].codCurso,
                  data[prop].fechaInicio,
                  data[prop].horario
                ];
                fila.push(dato);
              }
            }
            this.imprimirSrv.imprimir(columna, fila, 'Listado de Grupos');
          }
        );
      }
    );
  }
  ngOnInit() {
    this.paginacion.iniciar();
    this.filtrar.inicializar({}, 'grupos', 'codGrupo');
    this.refrescar();
  }

}
