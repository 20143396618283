<div class="container">
  <div class="card">
    <div class="card-header bg-light">
       <h2 class="text-center">Grupos</h2>
    </div>
    <div class="card-body">
     <div *ngIf="filtrar.visible" class="container animated slideInDown d-flex mb-3"
       name="bloqueFiltro" #bloqueFiltro>
         <form class="form-inline">
           <div class="form-group me-3">
               <label for="" class="me-1">Grupo</label>
               <input type="text" class="form-control-sm" placeholder="cod Grupo" #fCodGrupo
               (keyup)="filtrarM({codGrupo:fCodGrupo.value, codCurso:fcodCurso.value, codProfesor:idProfesor}, $event)">
           </div>
           <div class="form-group me-3">
              <label for="" class="me-1">Cod Curso</label>
              <input type="text" class="form-control-sm" placeholder="cod Curso" #fcodCurso
              (keyup)="filtrarM({codGrupo:fCodGrupo.value, codCurso:fcodCurso.value, codProfesor:idProfesor}, $event)">
          </div>
          <!--div class="form-group me-3">
              <label for="" class="me-1">Curso</label>
              <input type="text" class="form-control-sm" placeholder="Nombre Curso" #fnombreCurso
              (keyup)="filtrarM({codGrupo:fCodGrupo.value, codCurso:fcodCurso.value, nomCurso: fnombreCurso.value})">
          </div-->
          <!--div class="form-group me-3">
              <label for="" class="me-1">Inicio</label>
              <input type="date" class="form-control-sm" #ffechaInicio
              (change)="filtrarM({codGrupo:fCodGrupo.value, codProfesor:fcodProfesor.value,
                      codCurso:fcodCurso.value, fechaInicio: ffechaInicio.value})">
          </div-->
          </form>
          <button class="btn btn-danger ms-4" (click)="mostrarFiltro()">&times;</button>
     </div>
 <div class="table-responsive" >
   <table class="table table-sm table-hover table-striped">
     <thead class="thead-light">
      <tr [ngClass]="{'dropup':filtrar.orden.modo===1}">
        <th class="cursor-orden" (click)="ordenar('codGrupo')">Grupo
          <i [ngClass]="{'dropdown-toggle':filtrar.orden.campo==='codGrupo'}"></i>
        </th>
        <th class="cursor-orden" (click)="ordenar('codCurso')">Curso
          <i [ngClass]="{'dropdown-toggle':filtrar.orden.campo==='codCurso'}"></i>
        </th>
        <th class="cursor-orden" (click)="ordenar('fechaInicio')">Inicio
          <i [ngClass]="{'dropdown-toggle':filtrar.orden.campo==='fechaInicio'}"></i>
        </th>
        <th>Horario</th>
        <th class="text-center">&nbsp;</th>
     </tr>
     </thead>
     <tbody *ngIf="!vacio">
       <tr *ngFor="let item of lista">
         <td>{{item.codGrupo}}</td>
         <td>{{item.codCurso}}</td>
         <td>{{item.fechaInicio | date: 'dd/MM/yyyy'}}</td>
         <td>{{item.horario}}</td>
         <!--td class="text-center">
           <button class="btn btn-outline btn-info btn-sm " title="Información de Grupo" (click)="infoGrupo(item.codGrupo)">
              <fa-icon [icon]="['fas', 'info-circle']"></fa-icon> <span class="d-none d-lg-inline-block">&nbsp;Información</span>
           </button>
         </td-->
         <td class="text-center">
            <button class="btn btn-outline btn-success btn-sm me-3" title="Activar examen" (click)="mostrarPruebas(item.codGrupo)">
              <fa-icon [icon]="['fas', 'user-edit']"></fa-icon> <span class="d-none d-lg-inline-block">&nbsp; Activar Exámen</span>
            </button>
            <button class="btn btn-outline btn-primary btn-sm me-3" title="Ver Notas" (click)="verNotas(item.codGrupo)">
              <fa-icon [icon]="['fas', 'calendar-alt']"></fa-icon> <span class="d-none d-lg-inline-block">&nbsp;Ver Notas</span>
            </button>
            <button class="btn btn-outline btn-warning btn-sm ms-3" title="Notas" (click)="elegirPrueba(item.codGrupo)">
              <fa-icon [icon]="['fas', 'edit']"></fa-icon>
            <span class="d-none d-lg-inline-block">&nbsp;Entrar Notas</span>
           </button>
          </td>
       </tr>
     </tbody>
   </table>
 </div>
 <div class="alert alert-warning text-center"*ngIf="vacio">No se encontraron coincidencias</div>
   <div class="d-flex" *ngIf="!vacio">
     <div class="me-3">
       <select name="" id="" class="form-control"  (change)="cambioCant($event)">
           <option value="2">2</option>
           <option value="5" selected>5</option>
           <option value="10">10</option>
           <option value="15">15</option>
           <option value="20">20</option>
           <option value="30">30</option>
           <option value="50">50</option>
           <option value="75">75</option>
           <option value="100">100</option>
       </select>
     </div>
     <div>
         <ul class="pagination pagination-md">
             <li class="page-item" [ngClass]="{'disabled':paginacion.actual==1}"><a class="page-link" (click)="cambiarPagina(-1)" >&laquo;</a></li>
             <li class="page-item" *ngIf="paginacion.primero > 1"><a class="page-link" (click)="cambiarPagina(-2)">...</a></li>
             <li class="page-item" [ngClass]="{'active':paginacion.actual==n}"
             *ngFor="let n of paginacion.pags | slice:paginacion.primero-1:paginacion.ultimo; let i = index"><a class="page-link"
               (click)="cambiarPagina(n)">{{n}}</a></li>
               <li class="page-item" *ngIf="paginacion.ultimo < paginacion.numPags"><a class="page-link" (click)="cambiarPagina(-3)">...</a></li>
             <li class="page-item" [ngClass]="{'disabled':paginacion.actual==paginacion.numPags}"><a class="page-link" (click)="cambiarPagina(0)">&raquo;</a></li>
           </ul>
     </div>
   </div>
 </div>
 </div>
 <div class="card-footer bg-light">
     <div class="botones d-flex justify-content-end">
         <button class="btn btn-primary me-2" (click)="mostrarFiltro();">  Filtrar  </button>
         <button class="btn btn-success me-2" (click)="onImprimir()">Imprimir</button>
         <button class="btn btn-danger" (click)="cerrarCatalogo();">  Cerrar  </button>
     </div>
 </div>
 </div>
 <!--Ventana Pruebas de grupo-->
 <div class="modal backdrop" tabindex="-1" role="dialog" name="ventanaPruebas" #ventanaPruebas>
  <div class="modal-dialog modal-md animated fadeInDown" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{tituloForm}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="cerrarVentana(1)">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <table class="table table-sm table-striped">
          <tr>
           <th>Examen</th>
           <th>Valor</th>
           <th>Duración</th>
           <th>&nbsp;</th>
          </tr>
          <tr *ngFor="let item of pruebas">
            <td>{{item.titulo}}</td>
            <td>{{item.valor}}</td>
            <td>{{item.duracion}}</td>
            <td>
              <button class="btn btn-info" (click)="activarExamen(item.idPrueba, item.titulo)">
                Activar
              </button>
            </td>
          </tr>
        </table>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="cerrarVentana(1)">Cancelar</button>
      </div>
    </div>
  </div>
</div>
 <!--Ventana Elegir prueba-->
 <div class="modal backdrop" tabindex="-1" role="dialog" name="ventanaElegir" #ventanaElegir>
  <div class="modal-dialog modal-md animated fadeInDown" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{tituloForm}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="cerrarVentana(2)">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <table class="table table-sm table-striped">
          <tr>
          <th>Id Prueba</th>
           <th>Examen</th>
           <th>Valor</th>
           <th>&nbsp;</th>
          </tr>
          <tr *ngFor="let item of pruebas">
            <td>{{item.idPrueba}}</td>
            <td>{{item.titulo}}</td>
            <td>{{item.valor}}</td>
            <td>
              <button class="btn btn-warning" (click)="entrarNotas(item.idPrueba, item.titulo, item.valor)">
                <fa-icon [icon]="['fas', 'edit']"></fa-icon>
                <span class="d-none d-lg-inline-block">&nbsp;Entrar Notas</span>
              </button>
            </td>
          </tr>
        </table>
      </div>
      <div class="modal-footer">
        <!--button type="button" class="btn btn-primary" (click)="eliminar()"
         *ngIf="tituloForm === 'Confirmación'"
        >Eliminar</button-->
        <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="cerrarVentana(2)">Cancelar</button>
      </div>
    </div>
  </div>
</div>
