import { FiltrarService } from './../shared/services/filtrar.service';
import { NotasService } from './../shared/services/notas.service';
import { AuthenticationService } from '../shared/services/authentication.service';
import { PruebaService } from './../shared/services/prueba.service';
import { Component, OnInit, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { PaginacionService } from './../shared/services/paginacion.service';
import { CatalogoService } from './../shared/services/catalogo.service';
// import { map } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-notas-est',
  templateUrl: './notas-est.component.html',
  styleUrls: ['./notas-est.component.css']
})
export class NotasEstComponent implements OnInit {
  campoFiltro = 'Curso';
 // tituloCatalogo = 'Notas Estudiante';
  nombreCurso = '';
  // prueba = new Prueba();
  // lista;
  hayNotas;
  /*
  filtro = {
    visible : false,
    filtro : 0,
    valor : ''
  };
  */
  tituloForm = '';
  calificaciones;
  detalles;
  idPrueba;
  valor;
  /*
  filtroActual;
  vacio;
  cantidadRegs: number;
  */
  @ViewChild('detalleResultado', { static: true }) detalleResultado: ElementRef;
  constructor(
        public paginacion: PaginacionService,
        private notasService: NotasService,
        private catalogoService: CatalogoService,
        private router: Router,
        private renderizar: Renderer2,
        private authService: AuthenticationService,
        private filtrar: FiltrarService
      ) { }
      /*
  cambioCant(e) {
    this.paginacion.cambioCant(e.target.value);
    this.obtenerDatos();
  }
  cambiarPagina(p) {
    this.paginacion.cambiarPagina(p);
    if (!this.filtro.visible) {
      this.obtenerDatos();
    } else {
      this.filtrar(
        {
          filtro: this.filtro.filtro,
          valor: this.filtro.valor
        }, false, this.paginacion.actual
      );
    }
  }
  filtrarM(filtro, e?) {
    filtro.tipo = 2;
    this.filtroActual = filtro;
    if ((e) && this.filtrar.invalidChar( e.key )) {
      return;
    }
    if (e) {
      this.paginacion.actual = 1;
    }
    filtro.tipo = 2;
    this.filtrar.cantidad(filtro)
    .then(
      res => {
        res.subscribe(
          data => this.paginacion.calcularPiecera(Number(data))
        );
      }
    );
    this.filtrar.ejecutar(filtro, this.paginacion.actual, this.paginacion.limite)
      .then(
        snapshot => {
          snapshot.subscribe(
            data => {
              this.lista.length = 0;
              for (const prop in data) {
                if (prop) {
                  const dato = {
                    idPrueba: data[prop].idPrueba,
                    codCurso: data[prop].codCurso,
                    titulo: data[prop].titulo,
                    valor: data[prop].valor,
                  };
                  this.lista.push(dato);
                }
              }
              this.vacio = this.lista.length === 0;
            }
          );
        }
      );
  }
/*
  filtrar(filtro, gp, p?) {
    this.filtro.filtro = filtro.filtro;
    this.filtro.valor = filtro.valor;

    if (!p) {
      this.paginacion.actual = 1; // *
    } else {
      this.paginacion.actual = p;
    }
    switch (filtro.filtro) {
      case 1:
        this.paginacion.calcularPiecera(1);

        this.catalogoService.buscar(filtro.valor)
          .pipe(
            map(res => res)
          )
          .subscribe(
            data => {
              this.pruebas = data;
              const me = this;
              if (data) {
                me.pruebas = Object.keys(me.pruebas).map( key => {
                  return me.pruebas[key];
                });
              }
            }
          );
        break;
      case 2:
        if (filtro.valor.length > 0) {
          if (gp) {
            this.catalogoService.cant({campo: this.campoFiltro, valor: filtro.valor})
              .subscribe(
                res => this.paginacion.calcularPiecera(Number(res))
            );
          }
          this.catalogoService.filtrar(this.paginacion.actual, this.paginacion.limite,
                                        {campo: this.campoFiltro, valor: filtro.valor})
              .pipe(
                map(res => res)
              )
              .subscribe(
                data => {
                  if (data) {
                    this.pruebas = data;
                    const me = this;
                    me.pruebas = Object.keys(me.pruebas).map( key => {
                      return me.pruebas[key];
                    });
                  }
              });
          } else {
            this.refrescar();
          }
        break;
    }
  }
*/
/*
refrescar() {
  this.filtrar.cantidad({tipo: 2})
  .then(
    res => {
      res.subscribe(
        data => {
        this.cantidadRegs = Number(res);
        this.paginacion.calcularPiecera(Number(data));
      }
      );
    }
  );
  this.filtrarM({tipo: 2 });
}
  obtenerDatos() {
    this.catalogoService.filtroMultiple(this.paginacion.actual, this.paginacion.limite, {tipo: 1 } )
      .pipe(
            map(res => res)
      )
        .subscribe(
              data => {
                if (data) {
                  this.pruebas = data;
                  const me = this;
                  me.pruebas = Object.keys(me.pruebas).map( key => {
                    return me.pruebas[key];
                  });
                } else {
                  this.pruebas = null;
                }
              }
        );
  }
  mostrarFiltro() {
    if (this.filtro.filtro === 0) {
      this.filtro.visible = false;
      this.filtro.filtro = -1;
    } else {
      this.filtro.visible = !this.filtro.visible;
    }
    this.refrescar();
  }
  infoCalificacion(codCurso) {

  }*/
  cerrarCatalogo() {
    this.router.navigate(['/'], { skipLocationChange: true, replaceUrl: true });
  }
  mostrarVentanaInfo(idDetalle, curso) {
   // this.detalle = idDetalle;
    this.nombreCurso = curso;
    this.notasService.obtenerDetalleNotas(idDetalle)
      .then(
        snapshot => {
          snapshot.subscribe(
            data => {
              this.detalles = data;
              const me = this;
              if (data) {
                me.detalles = Object.keys(me.detalles).map( key => {
                  return me.detalles[key];
                });
              }
            }
          );
        }
      );
    this.renderizar.addClass(this.detalleResultado.nativeElement, 'show');
    this.renderizar.setStyle(this.detalleResultado.nativeElement, 'display', 'block');
  }
  cerrarVentanaInfo() {
    this.renderizar.removeClass(this.detalleResultado.nativeElement, 'show');
    this.renderizar.removeStyle(this.detalleResultado.nativeElement, 'display');
  }
  ngOnInit() {
    this.notasService.obtenerNotas(this.authService.currentUserValue.id)
    .then (
      snapshot => {
        snapshot.subscribe(
          data => {
                // console.log(data[1].carnet);
            this.hayNotas = (data[1].carnet !== null);
            if (this.hayNotas) {
              this.calificaciones = data;
              const me = this;
              if (data) {
                    me.calificaciones = Object.keys(me.calificaciones).map( key => {
                  return me.calificaciones[key];
                });
              }
            }
          }
        );
     });
    }
}
