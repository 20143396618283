import { ImprimirService } from './../shared/services/imprimir.service';
import { ContadorComponent } from './../contador/contador.component';
import { FiltrarService } from './../shared/services/filtrar.service';
import { Grupo } from './../shared/models/grupo.model';
import { Matricula } from './../shared/models/matricula.model';
import { Component, OnInit,  ViewChild, Renderer2, ElementRef  } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CatalogoService } from './../shared/services/catalogo.service';
import { map } from 'rxjs/operators';
//import { PaginacionService } from './../shared/services/paginacion.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-matricula',
  templateUrl: './matricula.component.html',
  styleUrls: ['./matricula.component.css']
})
export class MatriculaComponent implements OnInit {
  regs;
  nombreAlumno = '';
  idDetalle;
  grupo;
  campoFiltro = 'texto';
  tituloCatalogo = 'Matrícula';
  matricula = new Matricula();
  matriculas;
  frmPrincipal: FormGroup;
  tituloForm = '';
  vacio;
  mensajeEliminar;

  @ViewChild('ventanaPrincipal', { static: true }) ventanaPrincipal: ElementRef;
  @ViewChild('ventanaConfirm', { static: true }) ventanaConfirm: ElementRef;
  @ViewChild('bloqueFiltro') bloqueFiltro: ElementRef;
  constructor(private fb: FormBuilder, private renderizar: Renderer2,
              private catalogoService: CatalogoService,
             // public paginacion: PaginacionService,
              private router: Router,
              private ruta: ActivatedRoute,
              private imprimirSrv: ImprimirService,
              private filtrar: FiltrarService) {
      this.frmPrincipal = this.fb.group({
        codGrupo: ['', [ Validators.required, Validators.minLength(7), Validators.pattern('[0-9]{2}-[0-9]{4}')]],
        carnet: ['', [ Validators.required, Validators.minLength(9), Validators.pattern('[0-9]{4}-[0-9]{4}')]],
        fecha: ['', Validators.required],
        nota: [''],
        funcion: ['N']
      });
      this.catalogoService.setServidor('matricula');
  }
  revisar() {
    if (this.frmPrincipal.controls.carnet.valid) {
      this.buscarAlumno(this.frmPrincipal.controls.carnet.value);
    } else {
      this.nombreAlumno = '';
    }
  }
  buscarAlumno(carnet) {
    this.filtrar.buscarAlumno(carnet)
     .then(
        snapshot => {
          snapshot.subscribe(
            res => {
              if (res !== null) {
                res = res[1];
                this.nombreAlumno = (res as any).Nombre + ' ' + (res as any).Apellido1 + ' ' + (res as any).Apellido2;
              } else {
                this.nombreAlumno = '';
                this.frmPrincipal.controls.carnet.setErrors({noexiste: true});
              }
            }
          );
        }
     );
  }
  /*
  cambioCant(e) {
    this.paginacion.cambioCant(e.target.value);
    this.obtenerDatos();
  }
  cambiarPagina(p) {
    this.paginacion.cambiarPagina(p);
    this.obtenerDatos();
  }
  */
  refrescar() {
 /* this.filtrar.cantidad({codGrupo: this.grupo.codGrupo })
  .then(
    snapshot => {
      snapshot.subscribe(
        res => {
//           this.cantidadRegs = Number(res);
          this.paginacion.calcularPiecera(Number(res));
        }
      );
    }
  ); */
  this.obtenerDatos();
}
obtenerDatos() {
    this.catalogoService.matriculaGrupo(this.grupo.codGrupo)
      .pipe(
        map(res => res)
      )
      .subscribe(
        data => {
          this.vacio = data === null;
          if (data) {
            this.matriculas = data;
            const me = this;
            if (me.matriculas) {

              me.matriculas = Object.keys(me.matriculas).map( key => {
                return me.matriculas[key];
              });
          } else {
              this.matriculas = null;
          }
          }
        }
      );
  }
  // Manipulación de datos
  guardar() {
    let resultado;
    if (this.frmPrincipal.invalid) {return; }
    if (this.nombreAlumno === '') {
      this.frmPrincipal.controls.carnet.setErrors({noexiste: true});
      return;
    }
    const matricula = new Matricula(this.frmPrincipal.value);
    if (this.idDetalle) {
      matricula.idDetalle = this.idDetalle;
    }
    switch (this.frmPrincipal.value.funcion) {
       case 'N':
         matricula.nota = 0;
         this.catalogoService.guardar(matricula)
          .subscribe(
           res => resultado = res,
            err => console.log(err),
            () => {
             if (resultado[0] === '1') {
               this.frmPrincipal.controls.carnet.setErrors({matriculado: true});
             } else {
               this.refrescar();
               this.cerrarVentana(1);
             }
             }
            );
         break;
       case 'E':
         this.catalogoService.guardar(matricula, matricula.idDetalle)
           .subscribe(
             // res => console.log(res),
             // err => console.log(err),
             () => {
               this.refrescar();
               this.cerrarVentana(1);
             }
             );
    }
  }
  nuevo() {
     this.tituloForm = 'Nueva Matricula';
     this.nombreAlumno = '';
     const fecha = new Date();

     const mes = (fecha.getMonth() + 1) < 10 ?  `0${(fecha.getMonth() + 1).toString()}` : (fecha.getMonth() + 1).toString();
     const dia = fecha.getDate() < 10 ?  `0${fecha.getDate().toString()}` : fecha.getDate().toString();
     const fechaCadena = `${fecha.getFullYear().toString()}-${mes}-${dia}`;

     this.frmPrincipal.reset({funcion: 'N', codGrupo: this.grupo.codGrupo, fecha: fechaCadena});
     this.frmPrincipal.controls.codGrupo.disable();

     this.renderizar.addClass(this.ventanaPrincipal.nativeElement, 'show');
     this.renderizar.setStyle(this.ventanaPrincipal.nativeElement, 'display', 'block');
  }
  eliminar() {
    this.catalogoService.eliminar(this.matricula.idDetalle)
        .subscribe(
            () => this.refrescar() // this.cursos = this.cursos.filter(e => e.codigo !== this.curso.codigo)
        );
    this.cerrarVentana(2);
  }
  confirmarEliminar(idDetalle) {
    this.filtrar.buscarRegistro(false, idDetalle, false, false, false, false)
    .then (
      snapshot => {
        snapshot.subscribe(
          data => {
            this.matricula.idDetalle = idDetalle;
            if (data === null) {
              this.tituloForm = 'Confirmación';
              this.mensajeEliminar = 'Eliminar Matrícula de alumno';
            } else {
              this.mensajeEliminar = 'No se puede eliminar Matrícula.';
              this.tituloForm = 'Advertencia';
            }
            this.renderizar.addClass(this.ventanaConfirm.nativeElement, 'show');
            this.renderizar.setStyle(this.ventanaConfirm.nativeElement, 'display', 'block');
                  }
        );
      }
    );
  }

  // Manejo de Ventanas
  cerrarVentana(v: number) {
    switch (v) {
      case 1:
        this.renderizar.removeClass(this.ventanaPrincipal.nativeElement, 'show');
        this.renderizar.removeStyle(this.ventanaPrincipal.nativeElement, 'display');
        break;
      case 2:
        this.renderizar.removeClass(this.ventanaConfirm.nativeElement, 'show');
        this.renderizar.removeStyle(this.ventanaConfirm.nativeElement, 'display');
    }
  }
  cerrarCatalogo() {
    this.router.navigate(['/'], { skipLocationChange: true, replaceUrl: true });
  }
  regresarGrupos() {
    this.router.navigate(['grupo']);
  }
  onImprimir() {
    const columna = [['Carnet', 'Nombre', 'Teléfono', 'Fecha', 'Nota']];
    const fila = [];
    this.catalogoService.matriculaGrupo(this.grupo.codGrupo)
      .subscribe(
        res => {
          for (const prop in res) {
            if (prop) {
                const dato = [
                  res[prop].carnet,
                  `${res[prop].nombre} ${res[prop].Apellido1} ${res[prop].Apellido2}`,
                  res[prop].Telefono,
                  res[prop].fecha,
                  res[prop].nota
                ];
                fila.push(dato);
            }
          }
          this.imprimirSrv.imprimir(columna, fila, `Matrícula de Grupo ${this.grupo.codGrupo}`);
        }
      );
  }
  ngOnInit() {
    // this.paginacion.iniciar();
    this.grupo = new Grupo();
    this.ruta.params.subscribe(params => {
      this.grupo.codGrupo = params.grupo;
      }
    );
    this.filtrar.mostrarMatricula(this.grupo.codGrupo)
      .then (
        snapshot => {
          snapshot.subscribe(
            res => {
              if (res) {
                this.grupo = res[1];
              }
            } ,
            err => console.log(err)
          );
        }
      );
    this.refrescar();
  }
}
