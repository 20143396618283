<div class="container">
 <div class="card">
   <div class="card-header bg-light">
      <h2 class="text-center">Catálogo de Cursos</h2>
   </div>
   <div class="card-body">
    <div *ngIf="filtrar.visible" class="container animated slideInDown d-flex mb-2"
      name="bloqueFiltro" #bloqueFiltro>
        <form action="" class="form-inline d-flex">
          <div class="form-group me-2">
              <div class="input-group">
              <input type="text" class="form-control form-control-sm" placeholder="Código" #fCodigo
              (keyup)="filtrarM({codigo:fCodigo.value, nombre:fNombre.value})">
              </div>
          </div>
          <div class="form-group">
              <input type="text" class="form-control form-control-sm" placeholder="Nombre"
              #fNombre (keyup)="filtrarM({codigo:fCodigo.value, nombre:fNombre.value})">
          </div>
         </form>
         <button class="btn btn-sm btn-danger ms-3" (click)="mostrarFiltro()">&times;</button>
    </div>
<div class="table-responsive">
  <table class="table table-sm table-hover table-striped">
    <thead class="thead-light">
      <tr [ngClass]="{'dropup':filtrar.orden.modo===1}">
        <th class="cursor-orden" (click)="ordenar('codigo')">Código
          <i [ngClass]="{'dropdown-toggle':filtrar.orden.campo==='codigo'}"></i>
         </th>
         <th class="cursor-orden" (click)="ordenar('nombre')">Nombre
          <i [ngClass]="{'dropdown-toggle':filtrar.orden.campo==='nombre'}"></i>
         </th>
        <th colspan="2" class="text-center">
          <button class="btn btn-outline btn-sm btn-success"(click)="nuevoCurso()" title="Nuevo">
              <fa-icon [icon]="['fas', 'plus']"></fa-icon>
          </button>
        </th>
    </tr>
    </thead>
    <tbody  *ngIf="!vacio">
      <tr *ngFor="let item of lista">
        <td>{{item.codigo}}</td>
        <td>{{item.nombre}}</td>
        <td class="text-center">
          <button class="btn btn-outline btn-warning btn-sm " (click)="editarCurso(item.codigo)">
            <fa-icon [icon]="['fas', 'pencil-alt']"></fa-icon>
          </button>
        </td>
        <td class="text-center">
          <button class="btn btn-outline btn-danger btn-sm" (click)="confirmarEliminarCurso(item)">
             <fa-icon [icon]="['fas', 'trash']"></fa-icon>
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<div class="alert alert-warning text-center"*ngIf="vacio">No se encontraron coincidencias</div>
  <div class="d-flex" *ngIf="!vacio">
    <div class="me-3">
      <select name="" id="" class="form-control"  (change)="cambioCant($event)">
          <option value="2">2</option>
          <option value="5" selected>5</option>
          <option value="10">10</option>
          <option value="15">15</option>
          <option value="20">20</option>
          <option value="30">30</option>
          <option value="50">50</option>
          <option value="75">75</option>
          <option value="100">100</option>
      </select>
    </div>
    <div>
      <ul class="pagination pagination-md">
        <li class="page-item" [ngClass]="{'disabled':paginacion.actual==1}"><a class="page-link" 
          (click)="cambiarPagina(-1)" >&laquo;</a></li>
        <li class="page-item" *ngIf="paginacion.primero > 1"><a class="page-link" (click)="cambiarPagina(-2)">...</a></li>
        <li class="page-item" [ngClass]="{'active':paginacion.actual==n}"
        *ngFor="let n of paginacion.pags | slice:paginacion.primero-1:paginacion.ultimo; let i = index"><a class="page-link"
         (click)="cambiarPagina(n)">{{n}}</a></li>
         <li class="page-item" *ngIf="paginacion.ultimo < paginacion.numPags"><a class="page-link" (click)="cambiarPagina(-3)">...</a></li>
         <li class="page-item" [ngClass]="{'disabled':paginacion.actual==paginacion.numPags}">
            <a class="page-link" (click)="cambiarPagina(0)">&raquo;</a></li>
      </ul>
    </div>
  </div>
</div>
</div>
<div class="card-footer bg-light">
    <div class="botones d-flex justify-content-end">
        <button class="btn btn-primary me-2" (click)="mostrarFiltro();">  Filtrar  </button>
        <button class="btn btn-success me-2" (click)="onImprimir()">Imprimir</button>
        <button class="btn btn-danger" (click)="cerrarCatalogo()">  Cerrar  </button>
    </div>
</div>
</div>
<!--Ventana Cursos-->
<div class="modal backdrop" tabindex="-1" role="dialog" name="ventanaCurso" #ventanaCurso>
  <div class="modal-dialog animated fadeInDown" role="document">
    <div class="modal-content">
      <div class="modal-header bg-success">
        <h5 class="modal-title">{{tituloForm}}</h5>
        <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close" (click)="cerrarVentana(1)">
        </button>
      </div>
      <div class="modal-body bg-light">
        <form [formGroup]="frmCurso" name="frmCurso" id="frmCurso"
          (ngSubmit)="guardarCurso()" class="form-fluid">
          <div class="form-group row mb-2">
            <label for="" class="col-sm-3 col-form-label">Código</label>
              <div class="col-sm-9">
                  <input type="text" formControlName="codigo" name="codigo" class="form-control text-uppercase" placeholder="Código">
              </div>
          </div>
          <div class="alert alert-danger" *ngIf="frmCurso.controls['codigo'].invalid && frmCurso.controls['codigo'].touched">
            <div *ngIf="frmCurso.controls['codigo'].errors['required']">El campo código es requerido</div>
            <div *ngIf="frmCurso.controls['codigo'].errors['minlength']">El código debe tener 8 caracteres</div>
            <div *ngIf="frmCurso.controls['codigo'].errors['pattern']">Formato de código inválido XXX-####</div>
            <div *ngIf="frmCurso.controls['codigo'].errors['errordup']">Código de curso duplicado</div>
         </div>
         <div class="form-group row">
         <label for="" class="col-sm-3 col-form-label">Nombre</label>
         <div class="col-sm-9">
            <input type="text" formControlName="nombre" name="nombre" class="form-control" placeholder="Nombre">
         </div>
        </div>
          <div class="alert alert-danger" *ngIf="frmCurso.controls['nombre'].invalid && frmCurso.controls['nombre'].touched">
             <div *ngIf="frmCurso.controls['nombre'].errors['required']">El campo nombre es requerido</div>
             <div *ngIf="frmCurso.controls['nombre'].errors['minlength']">El Nombre debe tener al menos 7 caracteres</div>
          </div>
            <input type="hidden" formControlName="funcion" name="funcion">
        </form>
      </div>
      <div class="modal-footer bg-success">
        <button type="submit" form="frmCurso" class="btn btn-primary"
        [disabled]="!frmCurso.valid">Guardar</button>
        <button type="button" class="btn btn-secondary" data-dismiss="modal"(click)="cerrarVentana(1)">Cerrar</button>
      </div>
    </div>
  </div>
</div>
<!--Ventana Confirmación-->
<div class="modal backdrop" tabindex="-1" role="dialog" name="ventanaConfirm" #ventanaConfirm>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header" [ngClass]="{'bg-danger': tituloForm == 'Confirmación', 'bg-info': tituloForm == 'Advertencia'}">        
        <h5 class="modal-title">{{tituloForm}}</h5>
        <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close" (click)="cerrarVentana(1)">
        </button>
      </div>
      <div class="modal-body">
        <p>{{mensajeEliminar}} {{curso.nombre}}</p>
        <p *ngIf="tituloForm === 'Advertencia'">Curso ya registrado en grupo</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="eliminarCurso()"
        *ngIf="tituloForm === 'Confirmación'">Eliminar</button>
        <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="cerrarVentana(2)">Cancelar</button>
      </div>
    </div>
  </div>
</div>
