<div class="card" id="imprimir">
  <div class="card-header d-flex justify-content-between">
    <h5 class="modal-title">Resultado de {{pruebaActual}}</h5>
    <h4>Correctos: {{numCorrectos}} de {{noPreguntas}} ( {{resultado | number : '1.2-2'}}% )</h4>
    <!--button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="salir()" >
      <span aria-hidden="true">&times;</span>
    </button-->
  </div>
  <div class="card-body">
    <ol class="list-group" *ngFor="let item of resultados; let i = index">
      <li class="list-group-item">
        <div class="d-flex align-items-start" style="font-size: 1.2rem;">
          <div class="font-weight:bold; align-self-center">{{i+1}}-&nbsp;</div>
          <ckeditor style="zoom:0.88;"  class="align-self-center"
          [editor]="EditorInfo" id="editor" data="{{item.texto}}">
        </ckeditor>
      </div>
        <ng-container *ngIf="item.correcta; then correcto; else noCorrecto"></ng-container>

        <!--span *ngIf="item.correcta; then correcta; else noCorrecto">
          <i class="fas fa-check text-success" ></i></span-->
        <ng-template #correcto>
          <ol class="list-unstyled alert alert-success">
            <li style="font-size: 2rem; font-weight: bold;"><fa-icon [icon]= "['fas', 'check']"></fa-icon></li>
            <li>
            <ckeditor style="background-color:#fff; zoom:0.85;"  
            [editor]="EditorInfo" id="editor" data="Respuesta Correcta : {{item.rElegida}}">
          </ckeditor>
        </li>
            <!--li>Respuesta Correcta : {{item.rElegida}}</li-->
          </ol>
        </ng-template>
        <ng-template #noCorrecto>
          <!--span><i class="fas fa-times text-secondary" ></i></span-->
          <ol class="list-unstyled alert alert-danger">
            <li style="font-size: 2rem;"><fa-icon [icon]= "['fas', 'times']"></fa-icon></li>

            <li><span style="font-size: 1.2rem; font-weight: bold;">Respuesta Elegida : </span>
              <ckeditor style="background-color:#fff; zoom:0.85;" 
              [editor]="EditorInfo" id="editor" data="{{item.rElegida}}">
              </ckeditor>
            </li>

            <li><span style="font-size: 1.2rem; font-weight: bold;">Respuesta correcta: </span>
              <ckeditor style="background-color:#fff; zoom:0.85;" 
              [editor]="EditorInfo" id="editor" data="{{item.rCorrecta}}">
              </ckeditor>
              <!--fa-icon [icon]= "['fas', 'check']"></fa-icon-->
            </li>             
          </ol>
  
        </ng-template>
      </li>
  </ol>
  </div>
<div class="card-footer d-flex justify-content-between">
  <div>Correctos: {{numCorrectos}} de {{noPreguntas}} ( {{resultado | number : '1.2-2'}}% )</div>
  <div>
    <!--button type="button" class="btn btn-success" (click)="descargarPDF()">Descargar PDF</button-->
    <button type="button" class="btn btn-secondary ml-2" data-dismiss="modal"  (click)="salir()" >Cerrar</button>
  </div>
</div>
</div>
<div class="mt-5"></div>
