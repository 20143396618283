import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Servidor } from '../servidor';


@Injectable({
  providedIn: 'root'
})
export class FileOploadService {
  servidor = new Servidor();
  URI_BASE = `${this.servidor.URL}/pregunta/subir/prueba`;
  constructor( private http: HttpClient ) { }
  uploadFile(codigo, archivo) {
    return this.http.post(`${this.URI_BASE}/${codigo}`, archivo);
  }
  uploadImg(codigo, imagen) {
    return this.http.post(`${this.URI_BASE}/img/${codigo}`, imagen);
  }

}
