<div class="container">
  <div class="card">
    <div class="card-header bg-light">
       <h2 class="text-center">{{tituloCatalogo}}</h2>
    </div>
    <div class="card-body">
     <div *ngIf="filtrar.visible" class="container animated slideInDown d-flex mb-1"
       name="bloqueFiltro" #bloqueFiltro>
         <form class="form-inline d-flex">
           <div class="form-group">
               <input type="text" class="form-control form-control-sm" placeholder="Texto Pregunta"
               #fTexto (keyup)="filtrarM({texto: fTexto.value}, $event)">
           </div>
          </form>
          <button class="btn btn-sm btn-danger ms-2" (click)="mostrarFiltro()">&times;</button>
     </div>
 <div class="table-responsive">
   <table class="table table-sm table-hover table-striped">
     <thead class="thead-light">
       <tr>
         <th>Id Pregunta</th>
         <th>Pregunta</th>
         <th colspan="3" class="text-center">
           <button class="btn btn-outline btn-sm btn-success"(click)="nuevo()" title="Nueva pregunta">
              <fa-icon [icon]="['fas', 'plus']"></fa-icon>
           </button>&nbsp;
           <button class="btn btn-outline btn-sm btn-success"(click)="mostrarSubir()"title="Importar de Excel">
             <fa-icon [icon]="['fas', 'newspaper']"></fa-icon>
           </button>
         </th>
     </tr>
     </thead>
     <tbody *ngIf="!vacio">
       <tr *ngFor="let item of lista">
         <td>{{item.idPregunta}}</td>
         <td>{{strip_html_tags(item.texto)}}</td>
         <td class="text-center">
          <button class="btn btn-outline btn-success btn-sm " (click)="info(item.idPregunta)" title="Más Información">
              <fa-icon [icon]= "['fas', 'info-circle']"></fa-icon>
          </button>
        </td>
         <td class="text-center">
           <button class="btn btn-outline btn-warning btn-sm " (click)="editar(item.idPregunta)" title="Editar">
              <fa-icon [icon]="['fas', 'pencil-alt']"></fa-icon>
           </button>
         </td>
         <td class="text-center">
           <button class="btn btn-outline btn-danger btn-sm" (click)="confirmarEliminar(item)">
             <fa-icon [icon]="['fas', 'trash']"></fa-icon>
           </button>
         </td>
       </tr>
     </tbody>
   </table>
 </div>
 <div class="alert alert-warning text-center" *ngIf="vacio">No se encontraron coincidencias</div>
   <div class="d-flex" *ngIf="!vacio">
     <div class="me-3">
       <select name="" id="" class="form-control"  (change)="cambioCant($event)">
           <option value="2">2</option>
           <option value="5" selected>5</option>
           <option value="10">10</option>
           <option value="15">15</option>
           <option value="20">20</option>
           <option value="30">30</option>
           <option value="50">50</option>
           <option value="75">75</option>
           <option value="100">100</option>
       </select>
     </div>
     <div>
         <ul class="pagination pagination-md">
             <li class="page-item" [ngClass]="{'disabled':paginacion.actual==1}"><a class="page-link" (click)="cambiarPagina(-1)" >&laquo;</a></li>
             <li class="page-item" *ngIf="paginacion.primero > 1"><a class="page-link" (click)="cambiarPagina(-2)">...</a></li>
             <li class="page-item" [ngClass]="{'active':paginacion.actual==n}"
             *ngFor="let n of paginacion.pags | slice:paginacion.primero-1:paginacion.ultimo; let i = index"><a class="page-link"
               (click)="cambiarPagina(n)">{{n}}</a></li>
               <li class="page-item" *ngIf="paginacion.ultimo < paginacion.numPags"><a class="page-link" (click)="cambiarPagina(-3)">...</a></li>
             <li class="page-item" [ngClass]="{'disabled':paginacion.actual==paginacion.numPags}"><a class="page-link" (click)="cambiarPagina(0)">&raquo;</a></li>
           </ul>
     </div>
   </div>
 </div>
 </div>
 <div class="card-footer bg-light">
     <div class="botones d-flex justify-content-end">
         <button class="btn btn-primary me-2" (click)="mostrarFiltro();">  Filtrar  </button>
         <button class="btn btn-success me-2">Imprimir</button>
         <button class="btn btn-danger" (click)="regresarPrueba()">  Cerrar  </button>
     </div>
 </div>
 </div>
 <!--Ventana Principal-->
 <div class="modal backdrop" tabindex="-1" role="dialog" name="ventanaPrincipal" #ventanaPrincipal>
   <div class="modal-dialog modal-xl animated fadeInDown modal-dialog-scrollable" role="document">
     <div class="modal-content">
       <div class="modal-header bg-success">
         <h5 class="modal-title">{{tituloForm}}</h5>
         <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close" (click)="cerrarVentana(1)">
         </button>
       </div>
       <div class="modal-body bg-light">
        <!--div class="form-group row">
          <label for="" class="col-3">Pregunta</label-->
          <!--quill-editor [styles]="{height:'100px'}" 
           (onContentChanged)="cambioPregunta($event)" [modules]="configEdit" 
           class="col-12" (onEditorCreated)="editor($event)"></quill-editor-->
        
            <!-- rows="2"  class="form-control col-8" placeholder="Pregunta"></textarea-->
    <!--/div-->
    
         <form [formGroup]="frmPrincipal" name="frmPrincipal" id="frmPrincipal"
           (ngSubmit)="guardar()" class="form-fluid">
           <input type="hidden" formControlName="idPrueba" name="idPrueba">
           <input type="hidden" formControlName="idPregunta" name="idPregunta">
           <div class="mb-2"
            style="min-height:'100px';border:1px solid rgb(223, 222, 222); border-radius:5px; background-color:#fff;">
            <ckeditor style="zoom:0.80;" 
                [editor]="Editor" formControlName="texto" name="texto" id="texto">
          </ckeditor>
        </div>

           <!--input type hidden name="texto" formControlName="texto"-->
           <div class="alert alert-danger" *ngIf="frmPrincipal.controls['texto'].invalid && frmPrincipal.controls['texto'].touched">
              <div *ngIf="frmPrincipal.controls['texto'].errors['required']">La pregunta es requerida</div>
              <div *ngIf="frmPrincipal.controls['texto'].errors['minlength']">La pregunta debe tener al menos 7 caracteres</div>
           </div>
           <div class="form-group row">
              <div class="col-1">
                  <input type="radio" name="opcionCorrecta" formControlName="opcionCorrecta" value="1">&nbsp;
                  <label for="Opcion1">1</label>
                </div>
              <!--textarea formControlName="Opcion1" name="Opcion1"
              class="form-control col-8" placeholder="Opción 1" rows="2" >
            </textarea-->

            <ckeditor style="border:1px solid rgb(223, 222, 222); border-radius:5px; background-color:#fff; zoom:0.75;" 
              class="col-10" [editor]="Editor" formControlName="Opcion1" name="Opcion1" id="Opcion1">
            </ckeditor>

           </div>
           <div class="alert alert-danger" *ngIf="frmPrincipal.controls['Opcion1'].invalid && frmPrincipal.controls['Opcion1'].touched">
              <div *ngIf="frmPrincipal.controls['Opcion1'].errors['required']">La Opción 1 es requerida</div>
           </div>
           <div class="form-group row">
             <div class="col-1">
              <input type="radio" name="opcionCorrecta" formControlName="opcionCorrecta" value="2">&nbsp;
              <label for="Opcion2">2</label>
            </div>
              <!--textarea formControlName="Opcion2" name="Opcion2" class="form-control col-8" placeholder="Opción 2"
              rows="2"></textarea-->
              <ckeditor style="border:1px solid rgb(223, 222, 222); border-radius:5px; background-color:#fff; zoom:0.75;" 
                  class="col-10" [editor]="Editor" formControlName="Opcion2" name="Opcion2" id="Opcion2">
              </ckeditor>
           </div>
           <div class="alert alert-danger" *ngIf="frmPrincipal.controls['Opcion2'].invalid && frmPrincipal.controls['Opcion2'].touched">
              <div *ngIf="frmPrincipal.controls['Opcion2'].errors['required']">La Opción 2 es requerida</div>
           </div>
           <div class="form-group row">
             <div class="col-1">
              <input type="radio" name="opcionCorrecta" formControlName="opcionCorrecta" value="3">&nbsp;
              <label for="Opcion3">3</label>
            </div>
              <!--textarea formControlName="Opcion3" name="Opcion3" class="form-control col-8" placeholder="Opción 3"
              rows="2"></textarea-->
              <ckeditor style="border:1px solid rgb(223, 222, 222); border-radius:5px; background-color:#fff; zoom:0.75;" 
              class="col-10" [editor]="Editor" formControlName="Opcion3" name="Opcion3" id="Opcion3">
            </ckeditor>
           </div>
           <div class="alert alert-danger" *ngIf="frmPrincipal.controls['Opcion3'].invalid && frmPrincipal.controls['Opcion3'].touched">
              <div *ngIf="frmPrincipal.controls['Opcion3'].errors['required']">La Opción 3 es requerida</div>
           </div>
           <div class="form-group row">
             <div class="col-1">
                <input type="radio" name="opcionCorrecta" formControlName="opcionCorrecta" value="4">&nbsp;
              <label for="Opción4">4</label>
            </div>
              <!--textarea type="text" formControlName="Opcion4" name="Opcion4" class="form-control col-8"
              placeholder="Opción 4" rows="2"></textarea-->
              <ckeditor style="border:1px solid rgb(223, 222, 222); border-radius:5px; background-color:#fff; zoom:0.75;" 
              class="col-10" [editor]="Editor" formControlName="Opcion4" name="Opcion4" id="Opcion4">
            </ckeditor>
           </div>
           <div class="alert alert-warning text-center" *ngIf="frmPrincipal.controls['opcionCorrecta'].invalid">
              <div *ngIf="frmPrincipal.controls['opcionCorrecta'].errors['required']">Asegurese de elegir la opción que será la correcta</div>
           </div>
             <input type="hidden" formControlName="funcion" name="funcion">
         </form>
       </div>
       <div class="modal-footer bg-success">
         <button type="submit" form="frmPrincipal" class="btn btn-primary"
         [disabled]="!frmPrincipal.valid">Guardar</button>
         <button type="button" class="btn btn-secondary" data-dismiss="modal"(click)="cerrarVentana(1)">Cerrar</button>
       </div>
     </div>
   </div>
 </div>
 <!--Ventana Confirmación-->
 <div class="modal backdrop" tabindex="-1" role="dialog" name="ventanaConfirm" #ventanaConfirm>
   <div class="modal-dialog" role="document">
     <div class="modal-content">
       <div class="modal-header bg-danger">
         <h5 class="modal-title">{{tituloForm}}</h5>
         <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close" (click)="cerrarVentana(2)">
         </button>
       </div>
       <div class="modal-body">
         <p>Eliminar pregunta</p>
         <div class="alert alert-primary">
          <ckeditor style="zoom:0.80;" 
            [editor]="EditorInfo" [ngModel]=pregunta.texto
            (ready)="onReady($event);">
          </ckeditor>
        </div>
       </div>
       <div class="modal-footer">
         <button type="button" class="btn btn-primary" (click)="eliminar()">Eliminar</button>
         <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="cerrarVentana(2)">Cancelar</button>
       </div>
     </div>
   </div>
 </div>

<!--Información de Pregunta-->
 <div class="modal backdrop" tabindex="-1" role="dialog" name="ventanaInfo" #ventanaInfo>
    <div class="modal-dialog modal-xl animated fadeInDown" role="document">
      <div class="modal-content">
        <div class="modal-header bg-success">
          <h5 class="modal-title">{{tituloForm}}</h5>
          <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close" (click)="cerrarVentana(3)">
          </button>
        </div>
        <div class="modal-body bg-light" *ngIf="pregunta.texto !=null">
          <div class="alert alert-primary">
              <ckeditor style="zoom:0.80;" 
                [editor]="EditorInfo" [ngModel]=pregunta.texto
                (ready)="onReady($event);">
              </ckeditor>
          </div>
          <div class="d-flex alert"
              [ngClass]="{'alert-success':pregunta.opcionCorrecta == 1,'alert-secondary':pregunta.opcionCorrecta != 1}">
              <fa-icon [icon]= "['fas', 'dot-circle']"></fa-icon>&nbsp;&nbsp;
              <ckeditor style="zoom:0.80;" 
                [ngClass]="{'alert-success':pregunta.opcionCorrecta == 1,'alert-secondary':pregunta.opcionCorrecta != 1}"
                [editor]="EditorInfo" name="Opcion1" id="Opcion1" [(ngModel)]=pregunta.Opcion1
                (ready)="onReady($event);">
              </ckeditor>              
              &nbsp;&nbsp;<span *ngIf="pregunta.opcionCorrecta == 1"><fa-icon [icon]= "['fas', 'check']"></fa-icon></span>
           </div>
           <div class="d-flex alert"
              [ngClass]="{'alert-success':pregunta.opcionCorrecta == 2,'alert-secondary':pregunta.opcionCorrecta != 2}">
              <fa-icon [icon]= "['fas', 'dot-circle']"></fa-icon>&nbsp;&nbsp;
              <ckeditor style="zoom:0.80;"
                [ngClass]="{'alert-success':pregunta.opcionCorrecta == 2,'alert-secondary':pregunta.opcionCorrecta != 2}"
                [editor]="EditorInfo" name="Opcion2" id="Opcion2" [(ngModel)]=pregunta.Opcion2
                (ready)="onReady($event);">
              </ckeditor>              

              &nbsp;&nbsp;<fa-icon *ngIf="pregunta.opcionCorrecta == 2" [icon]= "['fas', 'check']"></fa-icon>
           </div>
           <div class="d-flex alert"
           [ngClass]="{
            'alert-success':pregunta.opcionCorrecta == 3,
            'alert-secondary':pregunta.opcionCorrecta != 3
          }"><fa-icon [icon]= "['fas', 'dot-circle']"></fa-icon>&nbsp;&nbsp;
          <ckeditor style="zoom:0.80;"
          [ngClass]="{'alert-success':pregunta.opcionCorrecta == 3,'alert-secondary':pregunta.opcionCorrecta != 3}"
          [editor]="EditorInfo" name="Opcion3" id="Opcion3" [(ngModel)]=pregunta.Opcion3
          (ready)="onReady($event);">
        </ckeditor> 
          &nbsp;&nbsp;<span *ngIf="pregunta.opcionCorrecta == 3"><fa-icon [icon]= "['fas', 'check']"></fa-icon></span>
           </div>
           <div *ngIf="pregunta.Opcion4" class="d-flex alert"
           [ngClass]="{
            'alert-success':pregunta.opcionCorrecta == 4,
            'alert-secondary':pregunta.opcionCorrecta != 4
          }">
          <fa-icon [icon]= "['fas', 'dot-circle']"></fa-icon>&nbsp;&nbsp;

          <ckeditor style="zoom:0.80;"
          [ngClass]="{'alert-success':pregunta.opcionCorrecta == 4,'alert-secondary':pregunta.opcionCorrecta != 4}"
          [editor]="EditorInfo" name="Opcion4" id="Opcion4" [(ngModel)]=pregunta.Opcion4
          (ready)="onReady($event);">
        </ckeditor> 
          &nbsp;&nbsp;<span *ngIf="pregunta.opcionCorrecta == 4">
            <fa-icon [icon]= "['fas', 'check']"></fa-icon></span>
           </div>
        </div>
        <div class="modal-footer bg-success">
            <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="cerrarVentana(3)">Cerrar</button>
        </div>
      </div>
    </div>
 </div>

 <!--Ventana Subir Excel-->
 <div class="modal backdrop" tabindex="-1" role="dialog" name="ventanaSubir" #ventanaSubir>
  <div class="modal-dialog animated fadeInDown" role="document">
    <div class="modal-content">
      <div class="modal-header bg-success">
        <h5 class="modal-title">Importar de Excel Prueba: {{idPrueba}}</h5>
        <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close" (click)="cerrarVentana(4)">
        </button>
      </div>
      <div class="modal-body bg-light">
        <form [formGroup]="frmExcel" name="frmExcel" id="frmExcel"
          (ngSubmit)="upload()" class="form-fluid">
          
          <!--div class="mb-3">
            <label for="formFile" class="form-label">Default file input example</label>
            <input class="form-control" type="file" id="formFile">
          </div-->

          <div class="form-group my-3">
            <!--label for="" class="form-label">Elija el archivo</label-->
            <input class="form-control" type="text" formControlName="file" name="file" style="display: none;">

            <input type="file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            (change)="seleccionarArchivo($event)" class="form-control">

          </div>
          <div class="alert alert-danger" *ngIf="mensajeExcel !== ''">
            {{mensajeExcel}}
         </div>
         <div class="alert alert-success" *ngIf="regs > 0">
            Se insertaron {{regs}} preguntas
         </div>
            <!--input type="hidden" formControlName="funcion" name="funcion"> -->
        </form>
      </div>
      <div class="modal-footer bg-success">
        <button type="submit" form="frmExcel" class="btn btn-primary" *ngIf="subirVisible"
        [disabled]="!frmExcel.valid">Guardar</button>
        <button type="button" class="btn btn-secondary" data-dismiss="modal"(click)="cerrarVentana(4)">Cerrar</button>
      </div>
    </div>
  </div>
</div>
