import { NotasService } from './../shared/services/notas.service';
import { FormGroup, FormControl, FormBuilder, FormArray, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-entrar-notas',
  templateUrl: './entrar-notas.component.html',
  styleUrls: ['./entrar-notas.component.css']
})
export class EntrarNotasComponent implements OnInit {
  hayError;
  msjError;
  msjGuardado;
  carnet;
  grupo;
  notas;
  nombreCurso;
  isLoaded = false;
  titulo;
  alumnos;
  idPrueba;
  valor;
  frmNotas: FormGroup;
  listas = [];
  vacio;
  constructor(
          private ruta: ActivatedRoute,
          private notasService: NotasService,
          private router: Router,
          private fb: FormBuilder
  ) { }

  ngOnInit() {
    this.hayError = false;
    this.msjGuardado = false;
    this.ruta.params.subscribe(params => {
      this.grupo = params.grupo;
      this.idPrueba = params.idPrueba;
      this.titulo = params.titulo;
      this.valor = params.valor;
      }
    );
    this.notasService.notasAlumnos(this.grupo, this.idPrueba)
      .then(
        snapshot => {
          snapshot.subscribe(
            data => {
              this.vacio = !Boolean(data[1]);
              if (!this.vacio) {
                this.armarFormulario(data);
                this.isLoaded = true;
              }
            }
          );
        }
      );
  }
  async armarFormulario(datos) {
    const group = {};
    for (const prop in datos) {
      if (prop) {
         const dato = await {
          label : 'N' + (datos[prop].idDetalle), // ((datos[prop]).carnet).replace('-', 'A'),
          carnet : (datos[prop]).carnet,
          identificacion : (datos[prop]).identificacion,
          nombre : (datos[prop]).nombre,
          idDetalle: (datos[prop]).idDetalle,
          obtenido:  (datos[prop]).obtenido
        };
         this.listas.push(dato);
         group[dato.label] = new FormControl(Number(dato.obtenido),
          Validators.required);
      }
    }
    this.frmNotas = this.fb.group(group);
  }
  cerrar() {
    this.router.navigate(['grupo-prof'], { skipLocationChange: true, replaceUrl: true });
  }
  onCambioNota(e) {
    this.hayError = ((e.target.value === '') || (Number(e.target.value) > this.valor) || (Number(e.target.value) < 0));

    if (e.target.value === '') {
      this.msjError = 'No pueden haber campos vacios, debe ingresar un valor entre 0 y ' + this.valor;
    }
    if (Number(e.target.value) > this.valor) {
      this.msjError = 'El porcentaje obtenido no puede ser mayor a ' + this.valor;
    }
    if (Number(e.target.value) < 0)  {
      this.msjError = 'El porcentaje obtenido no puede ser menor que 0';
    }
  }
  onBlur(e) {
    if (Number(e.target.value) < 0 || Number(e.target.value) > this.valor || e.target.value === '') {
      e.target.value = '0';
    }
  }
  onSubmit() {
    if (this.frmNotas.invalid) {
      return;
    }
    const resultados = [];
    const datos = this.frmNotas.value;
    for (const prop in datos) {
      if (prop) {
        const resultado = {
          idDetalle: prop.replace('N', ''),
          obtenido: datos[prop]
        };
        resultados.push(resultado);
      }
    }
    this.notasService.notasGuardar(this.idPrueba, resultados)
      .then(
        snapshot => {
          snapshot.subscribe(
            data => {if (data) {
                this.msjGuardado = true;
              }
            }
          );
        }
      );
  }

}
