import { ImprimirService } from './../shared/services/imprimir.service';
import { FiltrarService } from './../shared/services/filtrar.service';
import { PaginacionService } from './../shared/services/paginacion.service';
import { Component, OnInit,  ViewChild, Renderer2, ElementRef  } from '@angular/core';
import { CatalogoService } from './../shared/services/catalogo.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { map } from 'rxjs/operators';
import { Profesor } from './../shared/models/profesor.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cat-prof',
  templateUrl: './cat-prof.component.html',
  styleUrls: ['./cat-prof.component.css']
})
export class CatProfComponent implements OnInit {
  vacio;
  mensajeEliminar = '';
  accionConfirmar;
  profesor = new Profesor();
  lista = [];
  frmProfesor: FormGroup;
  ID;
  tituloForm = '';
  filtroActual;
  cantidadRegs: number;
  @ViewChild('ventanaProfesor', { static: true }) ventanaProfesor: ElementRef;
  @ViewChild('ventanaConfirm', { static: true }) ventanaConfirm: ElementRef;
  @ViewChild('bloqueFiltro') bloqueFiltro: ElementRef;
  constructor(private fb: FormBuilder, private renderizar: Renderer2,
              private catalogoService: CatalogoService,
              private router: Router,
              public paginacion: PaginacionService,
              public filtrar: FiltrarService,
              private imprimirSrv: ImprimirService) {
              this.frmProfesor = this.fb.group({
              ID: ['', Validators.required],
              nombre: ['', [Validators.required, Validators.minLength(3)]],
              apellido1: ['', [Validators.required, Validators.minLength(3)]],
              apellido2: ['', [Validators.required, Validators.minLength(3)]],
              correo: ['', [Validators.required, Validators.email]],
              telefono: ['', [Validators.required, Validators.minLength(8)]],
              Estado: [false],
              funcion: ['N']
              });
              catalogoService.setServidor('profesor');
  }
  cambioCant(e) {
    this.paginacion.cambioCant(e.target.value);
    this.filtrarM({});
  }
  cambiarPagina(p) {
    this.paginacion.cambiarPagina(p);
    if (!this.filtrar.visible) {
      this.filtrarM({});
    } else {
      this.filtrarM(this.filtrar.filtro);
    }
  }
  guardar() {
  let resultado;
  if (this.frmProfesor.invalid) {return; }
  const datos = new Profesor(this.frmProfesor.value);
  switch (this.frmProfesor.value.funcion) {
      case 'N':
        this.catalogoService.guardar(datos)
        .subscribe(
          res =>  { resultado = res; console.log(res); },
          err => console.log(err),
          () => {
            if (resultado[0] === '1') {
              this.frmProfesor.controls.ID.setErrors({errordup: true});
            } else {
              this.refrescar();
              this.cerrarVentana(1);
            }
          }
          );
        break;
      case 'E':
        datos.ID = this.ID;
        this.catalogoService.guardar(datos, datos.ID)
          .subscribe(
           /* res => console.log('OK'),
            err => console.log(err),*/
            () => {
              this.refrescar();
              this.cerrarVentana(1);
            }
            );
  }
  }
  nuevo() {
    this.tituloForm = 'Nuevo Profesor';
    this.frmProfesor.reset({funcion: 'N'});
    this.frmProfesor.controls.ID.enable();

    this.renderizar.addClass(this.ventanaProfesor.nativeElement, 'show');
    this.renderizar.setStyle(this.ventanaProfesor.nativeElement, 'display', 'block');
  }
  editar(id) {
  this.tituloForm = 'Modificar Profesor';
  this.ID = id;
  this.filtrar.ejecutar({ID: id, UsuarioEstado: id})
    .then (
      snapshot => {
        snapshot.subscribe(
          data => {
            data = data[1];
            this.frmProfesor.setValue({
              ID : (data as Profesor).ID,
              nombre : (data as Profesor).nombre,
              apellido1 : (data as Profesor).apellido1,
              apellido2 : (data as Profesor).apellido2,
              correo: (data as Profesor).correo || '@',
              telefono : (data as Profesor).telefono,
              Estado : (data as Profesor).Estado === 'A' ? true : false,
              funcion : 'E'
            });
            this.frmProfesor.controls.ID.disable();
            }
        );
      }
    );
  this.renderizar.addClass(this.ventanaProfesor.nativeElement, 'show');
  this.renderizar.setStyle(this.ventanaProfesor.nativeElement, 'display', 'block');
  }
  eliminar() {
  this.catalogoService.eliminar(this.profesor.ID)
      .subscribe(
  //           res => emp = res,
  //         err => console.log(err),
          () => this.refrescar() // this.cursos = this.cursos.filter(e => e.codigo !== this.curso.codigo)
      );
  }
  confirmar(profesor, accion) {
    this.accionConfirmar = accion;
    switch (accion) {
      case 1:
        this.tituloForm = 'Contraseña';
        this.mensajeEliminar = 'Resetear contraseña de ';
        break;
      case 2:
        this.filtrar.buscarRegistro(false, false, false, profesor.ID, false, false)
          .then (
            snapshot => {
              snapshot.subscribe(
                data => {
                  this.profesor.ID = profesor.ID;
                  if (data === null) {
                    this.tituloForm = 'Confirmación';
                    this.mensajeEliminar = 'Eliminar a';
                  } else {
                    this.mensajeEliminar = 'No se puede eliminar a';
                    this.tituloForm = 'Advertencia';
                  }
                }
              );
            }
          );
    }
    this.renderizar.addClass(this.ventanaConfirm.nativeElement, 'show');
    this.renderizar.setStyle(this.ventanaConfirm.nativeElement, 'display', 'block');
    this.profesor.ID = profesor.ID;
    this.profesor.nombre = profesor.nombre;
    this.profesor.apellido1 = profesor.apellido1;
    this.profesor.apellido2 = profesor.apellido2;
    this.profesor.correo = profesor.correo;
  }
  onConfirmar() {
    switch (this.accionConfirmar) {
      case 1:
        this.resetearPassw();
        break;
      case 2:
        this.eliminar();
    }
    this.cerrarVentana(2);
  }
  resetearPassw() {
      this.catalogoService.resetearPassw(this.profesor.ID, this.profesor.correo, this.profesor.nombre)
        .subscribe(
          res => res
        );
  }
  refrescar() {
    this.filtrar.cantidad({})
    .then(
      snapshot => {
        snapshot.subscribe(
          data => {
            this.cantidadRegs = Number(data);
            this.paginacion.calcularPiecera(Number(data));
          }
        );
      }
    );
    this.filtrarM({});
  }
  mostrarFiltro() {
    this.filtrar.toggleVisible();
    this.refrescar();
  }
  ordenar(campo) {
    this.filtrar.ordenar(campo);
    this.filtrarM(this.filtrar.filtro);
  }
  filtrarM(filtro, e?) {
    this.filtroActual = filtro;
    if ((e) && this.filtrar.invalidChar( e.key )) {
      return;
    }
    if (e) {
      this.paginacion.actual = 1;
    }
    this.filtrar.cantidad(filtro)
    .then(
      res => {
        res.subscribe(
          data => this.paginacion.calcularPiecera(Number(data))
        );
      }
    );
    this.filtrar.ejecutar(filtro, this.paginacion.actual, this.paginacion.limite)
      .then(
        snapshot => {
          snapshot.subscribe(
            data => {
              this.lista.length = 0;
              for (const prop in data) {
                if (prop) {
                  const dato = {
                    ID : data[prop].ID,
                    nombre: data[prop].nombre,
                    apellido1: data[prop].apellido1,
                    apellido2: data[prop].apellido2,
                    correo: data[prop].correo,
                    telefono: data[prop].telefono
                  };
                  this.lista.push(dato);
                }
              }
              this.vacio = this.lista.length === 0;
            }
          );
        }
      );
  }
  cerrarVentana(v: number) {
    switch (v) {
      case 1:
        this.renderizar.removeClass(this.ventanaProfesor.nativeElement, 'show');
        this.renderizar.removeStyle(this.ventanaProfesor.nativeElement, 'display');
        break;
      case 2:
        this.renderizar.removeClass(this.ventanaConfirm.nativeElement, 'show');
        this.renderizar.removeStyle(this.ventanaConfirm.nativeElement, 'display');
    }
  }
  cerrarCatalogo() {
    this.router.navigate(['/'], { skipLocationChange: true, replaceUrl: true });
  }
  onImprimir() {
    const columna = [['Id', 'Nombre', 'Teléfono', 'Correo']];
    const fila = [];
    this.filtrar.ejecutar(this.filtroActual, 1, this.cantidadRegs)
    .then(
      snapshot => {
        snapshot.subscribe(
          data => {
            for (const prop in data) {
              if (prop) {
                const dato = [
                  data[prop].ID,
                  `${data[prop].nombre} ${data[prop].apellido1} ${data[prop].apellido2}`,
                  data[prop].telefono,
                  data[prop].correo
                ];
                fila.push(dato);
              }
            }
            this.imprimirSrv.imprimir(columna, fila, 'Listado de Profesores');
          }
        );
      }
    );
  }
  ngOnInit() {
    this.paginacion.iniciar();
    this.filtrar.inicializar({}, 'profesor', 'nombre');
    this.refrescar();
  }
}
