import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Servidor } from '../servidor';


@Injectable({
  providedIn: 'root'
})
export class CatalogoService {
  servidor = new Servidor();
  URI_BASE = this.servidor.URL;
  constructor(private http: HttpClient) { }

  setServidor(api) {
    this.URI_BASE = `${this.servidor.URL}/${api}`;
  }
  reseteoTodos() {
    return this.http.get(`${this.servidor.URL}/aut/reseteo/todos`);
    
  }
  guardar(datos, codigo?) {
    if (codigo) {
      // Modificando
      return this.http.put(this.URI_BASE, datos);
    } else {
      // Guardando Nuevo
      return this.http.post(this.URI_BASE, datos);
    }
  }
  eliminar(codigo) {
    return this.http.delete(`${this.URI_BASE}/${codigo}`);
  }
  sigCarnet() {
    return this.http.get(`${this.servidor.URL}/carnet`);
  }
  sigCodGrupo() {
    return this.http.get(`${this.servidor.URL}/codgrupo/sigcod`);
  }
  sigCodPregunta() {
    return this.http.get(`${this.servidor.URL}/pregunta/sigcod`);
  }
  filtroEspecial(codigo) {
    return this.http.get(`${this.URI_BASE}/inicio/${codigo}`);
  }
  matriculaGrupo(grupo) {
    return this.http.get(`${this.URI_BASE}/grupo/${grupo}`);
  }
  resetearPassw(id, correo, nombre) {
    const datos = {
      id,
      correo,
      nombre
    }
    //console.log(datos);
    return this.http.post(`${this.servidor.URL}/aut/reseteo/passw`, datos);
    // return this.http.get(`${this.servidor.URL}/aut/reseteo/${id}/${correo}`);
  }
  cambiarPassw(datos) {
    return this.http.post(`${this.servidor.URL}/aut/cambiar`, datos);
  }
}
