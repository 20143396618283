<div class="card text-center mt-4 mx-auto" style="width: 28rem;" mt10>
  <h4 class="card-header">
    <i class="fa fa-sign-in-alt"></i> Iniciar Sesión
  </h4>
  <div class="card-body">
    <form class="form" [formGroup]= "loginForm" name="loginForm" id="loginForm"
      (ngSubmit)="onSubmit()">
      <div class="form-group">
        <label class="label" for="">Usuario</label>
        <input type="text" class="form-control" formControlName="usuario" name="usuario"
        [ngClass]="{'border-danger':loginForm.controls['usuario'].invalid && loginForm.controls['usuario'].touched}">
      </div>
      <div class="alert alert-danger" *ngIf="loginForm.controls['usuario'].invalid && loginForm.controls['usuario'].touched">
        <div *ngIf="loginForm.controls['usuario'].errors['required']">El campo usuario es necesario</div>
     </div>
      <div class="form-group">
        <label for="">Contraseña</label>
        <input type="password" class="form-control" formControlName="passw" name="passw"
          [ngClass]="{'border-danger':loginForm.controls['passw'].invalid && loginForm.controls['passw'].touched}">
          <div class="alert alert-danger" *ngIf="loginForm.controls['passw'].invalid && loginForm.controls['passw'].touched">
            <div *ngIf="loginForm.controls['passw'].errors['required']">El campo contraseña es necesario</div>
         </div>
      </div>
      <div class="alert alert-danger"
        [ngStyle]="{'display':error ? 'block' : 'none'}">
        Usuario o contraseña no valido</div>
    </form>
  </div>
  <div class="card-footer text-right">
    <button class="btn btn-success btn-block" type="submit" form="loginForm"
    [disabled]="!loginForm.valid">Inicar</button>
  </div>
</div>
