import { CatalogoService } from './../shared/services/catalogo.service';
//import { Router } from '@angular/router';
import { AuthenticationService } from './../shared/services/authentication.service';
import { Component, OnInit } from '@angular/core';
import { FiltrarService } from './../shared/services/filtrar.service';
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  rol = this.authService.currentUserValue !== null ? this.authService.currentUserValue.Rol : -1;
  nomUsuario;
  constructor(
    private authService: AuthenticationService,
   // private catalogoService: CatalogoService,
    private filtrar: FiltrarService,
    /*private router: Router*/) { }

  salir() {
      this.authService.logout();
      window.location.replace('/');
  }
  ngOnInit() {
  
    this.nomUsuario = 'Invitado';
    const tabla: string[] = [, 'administra', 'profesor', 'alumnos'];
    if (this.authService.currentUserValue) {
      const rol = Number((this.authService.currentUserValue.Rol).toString().trim());
      this.filtrar.buscarUsuario(rol, this.authService.currentUserValue.id, tabla[rol])
      .then (
        snapshot => {
          snapshot.subscribe(
            res => {
              res = res[1];
              switch (rol) {
                case 1:
                  this.nomUsuario = (res as any).nombre;
                  break;
                case 2:
                  this.nomUsuario = (res as any).nombre + ' ' + (res as any).apellido1 + ' ' + (res as any).apellido2;
                  break;
                case 3:
                  this.nomUsuario = (res as any).Nombre + ' ' + (res as any).Apellido1 + ' ' + (res as any).Apellido2;
              }
            }
          );
        }
      );
    }
  }
}
