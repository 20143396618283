export class Curso {
  codigo: string;
  nombre: string;
  constructor(curso?) {
    if (curso) {
      this.codigo = curso.codigo;
      this.nombre = curso.nombre;
    }
  }
}
