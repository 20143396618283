import { Servidor } from './../servidor';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FiltrarService {
  visible: boolean;
  tabla: string;
  filtro: { };
  orden = {
    campo : '',
    modo  : 1
  };
  servidor: Servidor;
  constructor(
    private http: HttpClient
  ) {
      this.servidor = new Servidor();
  }
  inicializar(filtro, tabla, campoOrden) {
    this.filtro = filtro;
    this.tabla = tabla;
    this.orden.campo = campoOrden;
    this.visible = false;
  }
  toggleVisible() {
    this.visible = !this.visible;
    this.filtro = {};
  }
  invalidChar(ch) { // Poner estoen servicio de filtro
    return ('abcdefghijklmnñopqrstuvwxyzABCDEFGHIJKLMNÑOPQRSTUVWXYZ0123456789-BackspaceDelete\' '.indexOf( ch ) === -1);
  }
  async cantidad(filtro) {
    filtro.tabla = this.tabla;
    const respuesta = await this.http.post(`${this.servidor.URL}/filtro/cant`, filtro);
    return respuesta;
  }
  async ejecutar(filtro, pagActual?, pagLimite?) {
    let pagAct;
    let pagLim;
    this.filtro = filtro;
    filtro.tabla = this.tabla;
    filtro.orden = this.orden;
    pagAct = (pagActual == null) ? 1 : pagActual;
    pagLim = (pagLimite == null) ? 1 : pagLimite;
    const respuesta = await this.http.post(`${this.servidor.URL}/filtro/${pagAct}/${pagLim}`, filtro);
    return respuesta;
  }
  async buscarRegistro(codGrupo, idDetalle, carnet, codProfesor, codCurso, prueba) {
    let filtro;
    if (codGrupo !== false) {
      filtro = {codGrupo, tabla: 'registro'};
    }
    if (idDetalle !== false) {
      filtro = {idDetalle, tabla: 'notas'};
    }
    if (carnet !== false) {
      filtro = {carnet, tabla: 'registro'};
    }
    if (codProfesor !== false) {
      filtro = {codProfesor, tabla: 'grupos'};
    }
    if (codCurso !== false) {
      filtro = {codCurso, tabla: 'grupos'};
    }
    if (prueba !== false) {
      filtro = {idPrueba: prueba.idPrueba, tabla: prueba.tabla};
    }
    const respuesta = await this.http.post(`${this.servidor.URL}/filtro/1/1`, filtro);
    return respuesta;
  }
  async buscarAlumno(Carnet) {
    const filtro = {
      Carnet,
      tabla : 'alumnos',
    };
    const respuesta = await this.http.post(`${this.servidor.URL}/filtro/1/1`, filtro);
    return respuesta;
  }
  async buscarProfesor(ID) {
    const filtro = {
      ID,
      tabla : 'profesor',
    };
    const respuesta = await this.http.post(`${this.servidor.URL}/filtro/1/1`, filtro);
    return respuesta;
  }
  async buscarCurso(codigo) {
    const filtro = {
      codigo,
      tabla : 'cursos',
    };
    const respuesta = await this.http.post(`${this.servidor.URL}/filtro/1/1`, filtro);
    return respuesta;
  }
  async buscarUsuario(rol, codigo, tabla) {
    let filtro;
    switch (rol) {
        case 1: filtro = {id: codigo, tabla}; break;
        case 2: filtro = {ID: codigo, tabla}; break;
        case 3: filtro = {Carnet: codigo, tabla}; break;
    }
    const respuesta = await this.http.post(`${this.servidor.URL}/filtro/1/1`, filtro);
    return respuesta;
  }
  ordenar(campo?) {
    if (campo !== undefined) {
      if (this.orden.campo.localeCompare(campo) !== 0) {
        this.orden.modo = -1;
      }
      this.orden.campo = campo;
      if (this.orden.modo === 1) {
        this.orden.modo = -1;
      } else {
        this.orden.modo = 1;
      }
    }
  }
  async mostrarMatricula(codigo) { // Grupo con nombre del profesor y lista de alumnos
    const respuesta = await this.http.get(`${this.servidor.URL}/grupo/amplio/${codigo}`);
    return respuesta;
  }
}
