export class Prueba {
  idPrueba?: number;
  titulo: string;
  codCurso: string;
  valor: number;
  noPreguntas: number;
  duracion: string;
  tipo: number;
  constructor(prueba?) {
    if (prueba) {
      if (prueba.idPrueba) {
        this.idPrueba = prueba.idPrueba;
      }
      this.titulo = prueba.titulo;
      this.codCurso = prueba.codCurso;
      this.valor = prueba.valor;
      this.noPreguntas = prueba.noPreguntas;
      this.duracion = prueba.duracion;
      this.tipo = prueba.tipo;
    }
  }
}
