import { ImprimirService } from './../shared/services/imprimir.service';
import { FiltrarService } from './../shared/services/filtrar.service';
import { PruebaService } from '../shared/services/prueba.service';
import { AuthenticationService } from '../shared/services/authentication.service';
import { Grupo } from './../shared/models/grupo.model';
import { Component, OnInit,  ViewChild, Renderer2, ElementRef  } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CatalogoService } from './../shared/services/catalogo.service';
import { PaginacionService } from './../shared/services/paginacion.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-grupo-profesor',
  templateUrl: './grupo-profesor.component.html',
  styleUrls: ['./grupo-profesor.component.css']
})
export class GrupoProfesorComponent implements OnInit {
  vacio;
  idPrueba;
  pruebas;
  regs;
  mensajeEliminar = '';
  nombreProfesor = '';
  nombreCurso = '';
  codGrupo: string;
  campoFiltro = 'texto';
  tituloCatalogo: string;
 // grupo = new Grupo();
  lista = [];
  frmPrincipal: FormGroup;
  idProfesor;
  tituloForm = '';
  filtroActual;
  cantidadRegs: number;

  @ViewChild('ventanaPruebas', { static: true }) ventanaPruebas: ElementRef;
  @ViewChild('ventanaElegir', { static: true }) ventanaElegir: ElementRef;
  // @ViewChild('ventanaConfirm') ventanaConfirm: ElementRef;
  @ViewChild('bloqueFiltro') bloqueFiltro: ElementRef;
  constructor(private fb: FormBuilder, private renderizar: Renderer2,
              private catalogoService: CatalogoService,
              private pruebaService: PruebaService,
              public paginacion: PaginacionService,
              public filtrar: FiltrarService,
              private router: Router,
              private authService: AuthenticationService,
              private imprimirSrv: ImprimirService ) {
    this.frmPrincipal = this.fb.group({
    codGrupo: ['', [ Validators.required, Validators.minLength(7), Validators.pattern('[0-9]{2}-[0-9]{4}')]],
    codProfesor: ['', [Validators.required, Validators.minLength(5)]],
    codCurso: ['', [Validators.required, Validators.minLength(8), Validators.pattern('[0-9A-Za-z]{3}-[0-9]{4}')]],
    fechaInicio: ['', Validators.required],
    horario: ['', Validators.required],
    funcion: ['N']
    });
    this.frmPrincipal.controls.codGrupo.disable();
    this.catalogoService.setServidor('grupo');
}
revisar(n) {
  switch (n) {
    case 1:
        if (this.frmPrincipal.controls.codProfesor.valid) {
          this.buscarProfesor(this.frmPrincipal.controls.codProfesor.value);
        } else {
          this.nombreProfesor = '';
        }
        break;
    case 2:
        if (this.frmPrincipal.controls.codCurso.valid) {
          this.buscarCurso(this.frmPrincipal.controls.codCurso.value);
        } else {
          this.nombreCurso = '';
        }
  }
}
buscarProfesor(ID) {
  this.filtrar.buscarProfesor(ID)
   .then(
      snapshot => {
        snapshot.subscribe(
          res => {
            if (res !== null) {
              this.nombreProfesor = (res as any).nombre + ' ' + (res as any).apellido1 + ' ' + (res as any).apellido2;
              this.frmPrincipal.controls.codProfesor.setErrors(null);
            } else {
              this.nombreProfesor = '';
              this.frmPrincipal.controls.codProfesor.setErrors({noexiste: true});
            }
          }
        );
      }

   );
}
buscarCurso(curso) {
  this.filtrar.buscarCurso(curso)
   .then(
      snapshot => {
        snapshot.subscribe(
          res => {
            if (res !== null) {
              this.nombreCurso = (res as any).nombre;
            } else {
              this.nombreCurso = '';
              this.frmPrincipal.controls.codCurso.setErrors({noexiste: true});
            }
          }
        );
      }
   );
}
cerrarCatalogo() {
  this.router.navigate(['/'], { skipLocationChange: true, replaceUrl: true });
}
cambiarPagina(p) {
  this.paginacion.cambiarPagina(p);
  if (!this.filtrar.visible) {
    this.filtrarM({codProfesor : this.idProfesor});
  } else {
    this.filtrarM(this.filtrar.filtro);
  }
}
refrescar() {
  this.filtrar.cantidad({codProfesor: this.idProfesor })
  .then(
    snapshot => {
      snapshot.subscribe(
        res => {
          this.cantidadRegs = Number(res);
          this.paginacion.calcularPiecera(Number(res));
        }
      );
    }
  );
  this.filtrarM({codProfesor : this.idProfesor});
}
cambioCant(e) {
  this.paginacion.cambioCant(e.target.value);
  this.filtrarM({codProfesor : this.idProfesor});
}
mostrarFiltro() {
  this.filtrar.toggleVisible();
  this.refrescar();
}
ordenar(campo) {
  this.filtrar.ordenar(campo);
  this.filtrarM(this.filtrar.filtro);
}
filtrarM(filtro, e?) {
  filtro.codProfesor = this.idProfesor;
  this.filtroActual = filtro;
  if ((e) && this.filtrar.invalidChar( e.key )) {
    return;
  }
  if (e) {
    this.paginacion.actual = 1;
  }
  this.filtrar.cantidad(filtro)
  .then(
    res => {
      res.subscribe(
        data => this.paginacion.calcularPiecera(Number(data))
      );
    }
  );
  this.filtrar.ejecutar(filtro, this.paginacion.actual, this.paginacion.limite)
    .then(
      snapshot => {
        snapshot.subscribe(
          data => {
            this.lista.length = 0;
            for (const prop in data) {
              if (prop) {
                const dato = {
                  codGrupo: data[prop].codGrupo,
                  codProfesor : data[prop].codProfesor,
                  codCurso: data[prop].codCurso,
                  fechaInicio: data[prop].fechaInicio,
                  horario: data[prop].horario,
                };
                this.lista.push(dato);
              }
            }
            this.vacio = this.lista.length === 0;
          }
        );
      }
    );
}
infoGrupo(grupo) {
  this.codGrupo = grupo;
  this.renderizar.addClass(this.ventanaPruebas.nativeElement, 'show');
  this.renderizar.setStyle(this.ventanaPruebas.nativeElement, 'display', 'block');
}
mostrarPruebas(grupo) {
  this.codGrupo = grupo;
  this.pruebaService.mostrarPruebas(grupo, 1)
    .then(
      snapshot => {
        snapshot.subscribe(
          data => {
            if (data) {
              this.pruebas = data;
              const me = this;
              if (me.pruebas) {
                me.pruebas = Object.keys(me.pruebas).map( key => {
                  return me.pruebas[key];
                });
                this.tituloForm = this.pruebas[0].curso;
                this.idPrueba = this.pruebas[0].idPrueba;
            } else {
                this.pruebas = null;
            }
            }
          }
        );
      }
    );
  this.renderizar.addClass(this.ventanaPruebas.nativeElement, 'show');
  this.renderizar.setStyle(this.ventanaPruebas.nativeElement, 'display', 'block');
}
elegirPrueba(grupo) {
  this.codGrupo = grupo;
  this.pruebaService.mostrarPruebas(grupo, 2)
    .then(
      snapshot => {
        snapshot.subscribe(
          data => {
            if (data) {
              this.pruebas = data;
              const me = this;
              if (me.pruebas) {
                me.pruebas = Object.keys(me.pruebas).map( key => {
                  return me.pruebas[key];
                });
                this.tituloForm = this.pruebas[0].curso;
                this.idPrueba = this.pruebas[0].idPrueba;
            } else {
                this.pruebas = null;
            }
            }
          }
        );
      }
    );
  this.renderizar.addClass(this.ventanaElegir.nativeElement, 'show');
  this.renderizar.setStyle(this.ventanaElegir.nativeElement, 'display', 'block');
}
activarExamen(idPrueba, titulo) {
  this.router.navigate(['activar-examen', this.codGrupo, idPrueba, titulo, 1]);
}
entrarNotas(idPrueba, titulo, valor) {
  this.router.navigate(['entrar-notas', this.codGrupo, idPrueba, titulo, valor]);
}
verNotas(grupo) {
   this.router.navigate(['ver-notas', grupo, '1']);
}
cerrarVentana(v: number) {
  switch (v) {
    case 1:
      this.renderizar.removeClass(this.ventanaPruebas.nativeElement, 'show');
      this.renderizar.removeStyle(this.ventanaPruebas.nativeElement, 'display');
      break;
    case 2:
      this.renderizar.removeClass(this.ventanaElegir.nativeElement, 'show');
      this.renderizar.removeStyle(this.ventanaElegir.nativeElement, 'display');
  }
}
onImprimir() {
  const columna = [['Grupo', 'Curso', 'Inicio', 'Horario']];
  const fila = [];
  this.filtrar.ejecutar(this.filtroActual, 1, this.cantidadRegs)
  .then(
    snapshot => {
      snapshot.subscribe(
        data => {
          for (const prop in data) {
            if (prop) {
              const dato = [
                data[prop].codGrupo,
                data[prop].codCurso,
                data[prop].fechaInicio,
                data[prop].horario
              ];
              fila.push(dato);
            }
          }
          this.imprimirSrv.imprimir(columna, fila, 'Listado de Grupos');
        }
      );
    }
  );
}

ngOnInit() {
  this.idProfesor = this.authService.currentUserValue.id;
  this.paginacion.iniciar();
  this.filtrar.inicializar({codProfesor : this.idProfesor}, 'grupos', 'codGrupo');
  this.refrescar();
}

}
