import { Injectable } from '@angular/core';
import { Servidor } from '../servidor';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../models/user.model';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  private subject = new Subject<any>();
  servidor = new Servidor();

  URI_BASE = this.servidor.URL + '/aut';

  constructor(private http: HttpClient,
              private router: Router, 
              private location: Location) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
   }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }
  login(id: string, passw: string) {
    return this.http.post<any>(this.URI_BASE + '/loggear', {id, passw})
        .pipe(map(user => {
            // inicio exitoso si hay un jwt en la respuesta
            if (user && user.token && user.estado === 'A') {
                // guardar detalles de jwt en almacenamiento local para mantenerse logeado entre páginas
                localStorage.setItem('currentUser', JSON.stringify(user));
                this.currentUserSubject.next(user);
                window.location.replace('/');
            } else if (user && user.estado === 'I') {
              localStorage.setItem('currentUser', JSON.stringify(user));
            }
            return user;
        }));
  }
  logout() {
    // quitar usuario de almacenamiento local para cerrar sesión
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
  }
  sendMessage(log: boolean, r: number) {
    this.subject.next(
        {
          loggeado: log,
          rol: r
        });
  }
  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }
}
