import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { PruebaService } from '../shared/services/prueba.service';

@Component({
  selector: 'app-activar-examen',
  templateUrl: './activar-examen.component.html',
  styleUrls: ['./activar-examen.component.css']
})
export class ActivarExamenComponent implements OnInit {
  activar_Todos : boolean;
  grupo;
  idPrueba;
  alumnos;
  titulo;
  origen;
  vacio = true;
  activaron;
  constructor(
    private ruta: ActivatedRoute,
    private pruebaservice: PruebaService,
    private router: Router
  ) { }
  activar(event, prueba, idDetalle) {
    let datos = {};
    if (event.currentTarget.checked) {
      datos = {
        detalleActivo: idDetalle,
        PruebaActiva: this.idPrueba,
        carnet: event.target.value
      };
    } else {
      datos = {
        carnet: event.target.value,
        detalleActivo: 0,
        PruebaActiva: 0,
      };
    }
    this.pruebaservice.activarPruebaAlumno(datos)
      .then(
        snapshot => {
          snapshot.subscribe(
            data => {
              if (this.activaron === false) {
                this.activaron = (data as any).status;
              }
              this.todosActivos();
            }
          );
        }
      );
  }
  activarTodos(event, grupo) {
    let prueba = 0;
    if (event.currentTarget.checked) {
      prueba = this.idPrueba  
    }
    this.pruebaservice.activarPruebaGrupo({codGrupo:grupo, idPrueba:prueba})
      .then(
        snapshot => {
          snapshot.subscribe(
            data => {
              this.mostrarAlumnos();
            }
          );
        }
      );
    //this.activaron = false;
  }
  todosActivos() {
    this.pruebaservice.todosActivos(this.grupo)
      .then(
        snapshot => {
          snapshot.subscribe(
            data => {
              this.activar_Todos = data[1].activos == 0;
              console.log(data[1].activos);
              console.log(this.activar_Todos);
            }
          )
        }
      )
  }
  mostrarAlumnos() {
    this.pruebaservice.mostrarAlumnos(this.grupo, this.idPrueba)
      .then(
        snapshot => {
          snapshot.subscribe(
            data => {
              if ((data as any).status === 204) {
                this.vacio = true;
              } else {
                this.vacio = false;
              }
              if (data && (data as any).status !== 204) {
                this.alumnos = data;
                const me = this;
                if (me.alumnos) {
                  me.alumnos = Object.keys(me.alumnos).map( key => {
                    return me.alumnos[key];
                  });
              } else {
                  this.alumnos = null;
              }
              }
            } // fin de data =>
          );

        }
      );
  }
  cerrar() {
    switch (this.origen) {
      case '0':
         this.router.navigate(['grupo'], { skipLocationChange: true, replaceUrl: true });
         break;
      case '1':
          this.router.navigate(['grupo-prof'], { skipLocationChange: true, replaceUrl: true });
    }
  }  
  ngOnInit() {
    this.ruta.params.subscribe(params => {
      this.grupo = params.grupo;
      this.idPrueba = params.idPrueba;
      this.titulo = params.titulo;
      this.origen = params.origen;
    });
    this.activaron = false;
    this.todosActivos();
    this.mostrarAlumnos();
  }
}
