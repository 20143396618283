<div class="container vh-100">
  <div class="card">
    <div class="card-header bg-light">
       <h2 class="text-center">{{tituloCatalogo}} para grupo {{grupo.codGrupo}}</h2>
    </div>
    <div class="card-body" style="height:500px; overflow-y: scroll;">
      <div class="container d-flex row">
        <h6 class="col-4">Profesor: {{grupo.nombre}} {{grupo.apellido1}} {{grupo.apellido2}}</h6>
        <h6 class="col-4">Curso: {{grupo.codCurso}}</h6>
        <h6 class="col-2">Inicio: {{grupo.fechaInicio | date:'dd/MM/yyyy'}} </h6>
        <h6 class="col-2">Horario: {{grupo.horario}}</h6>
      </div>
      <hr>
     <!--div *ngIf="filtro.visible" class="container animated slideInDown d-flex mb-3"
       name="bloqueFiltro" #bloqueFiltro>
         <form class="form-inline">
           <div class="form-group mr-3">
               <label for="" class="mr-1">Grupo</label>
               <input type="text" class="form-control-sm" placeholder="cod Grupo" #fCodGrupo
               (keyup)="filtrarM({codGrupo:fCodGrupo.value, codProfesor:fcodProfesor.value,
                        codCurso:fcodCurso.value})">
           </div>
           <div class="form-group mr-3">
               <label for="" class="mr-1">Profesor</label>
               <input type="text" class="form-control-sm" placeholder="id Profesor" #fcodProfesor
               (keyup)="filtrarM({codGrupo:fCodGrupo.value, codProfesor:fcodProfesor.value,
                        codCurso:fcodCurso.value})">
           </div>
           <div class="form-group mr-3">
              <label for="" class="mr-1">Curso</label>
              <input type="text" class="form-control-sm" placeholder="cod Curso" #fcodCurso
              (keyup)="filtrarM({codGrupo:fCodGrupo.value, codProfesor:fcodProfesor.value,
                      codCurso:fcodCurso.value})">
          </div>
          </form>
          <button class="btn btn-danger ml-4" (click)="mostrarFiltro()">&times;</button>
     </div-->
 <div class="table-responsive">
   <table class="table table-sm table-hover table-striped">
     <thead class="thead-light">
       <tr>
         <th>Carnet</th>
         <th>Nombre</th>
         <th>Teléfono</th>
         <th>Fecha</th>
         <th class="text-center">
           <button class="btn btn-outline btn-sm btn-success"(click)="nuevo()" title="Nueva Matrícula">
              <fa-icon [icon]="['fas', 'plus']"></fa-icon>
           </button>
         </th>
     </tr>
     </thead>
     <tbody *ngIf="!vacio">
       <tr *ngFor="let item of matriculas">
         <td>{{item.carnet}}</td>
         <td>{{item.nombre}} {{item.Apellido1}} {{item.Apellido2}}</td>
         <td>{{item.Telefono}}</td>
         <td>{{item.fecha | date: 'dd/MM/yyyy'}}</td>
         <!--td class="text-center">
           <button class="btn btn-outline btn-warning btn-sm " (click)="editar(item.idDetalle)" title="Editar">
             <i class="fa fa-pencil-alt"></i>
           </button>
         </td-->
         <td class="text-center">
           <button class="btn btn-outline btn-danger btn-sm" (click)="confirmarEliminar(item.idDetalle)">
             <fa-icon [icon]="['fas', 'trash']"></fa-icon>
           </button>
         </td>
       </tr>
     </tbody>
   </table>
 </div>
 <div class="alert alert-warning text-center"*ngIf="vacio">No hay alumnos matriculados en este grupo</div>
 <!--
   <div class="d-flex">
     <div class="mr-3">
       <select name="" id="" class="form-control"  (change)="cambioCant($event)">
           <option value="2">2</option>
           <option value="5" selected>5</option>
           <option value="10">10</option>
           <option value="15">15</option>
           <option value="20">20</option>
           <option value="30">30</option>
           <option value="50">50</option>
           <option value="75">75</option>
           <option value="100">100</option>
       </select>
     </div>
     <div>
         <ul class="pagination pagination-md">
             <li class="page-item" [ngClass]="{'disabled':paginacion.actual==1}"><a class="page-link" (click)="cambiarPagina(-1)" >&laquo;</a></li>
             <li class="page-item" *ngIf="paginacion.primero > 1"><a class="page-link" (click)="cambiarPagina(-2)">...</a></li>
             <li class="page-item" [ngClass]="{'active':paginacion.actual==n}"
             *ngFor="let n of paginacion.pags | slice:paginacion.primero-1:paginacion.ultimo; let i = index"><a class="page-link"
               (click)="cambiarPagina(n)">{{n}}</a></li>
               <li class="page-item" *ngIf="paginacion.ultimo < paginacion.numPags"><a class="page-link" (click)="cambiarPagina(-3)">...</a></li>
             <li class="page-item" [ngClass]="{'disabled':paginacion.actual==paginacion.numPags}"><a class="page-link" (click)="cambiarPagina(0)">&raquo;</a></li>
           </ul>
     </div>
   </div>
  -->
 </div>
 </div>
 <div class="card-footer bg-light">
     <div class="botones d-flex justify-content-end">
         <!--button class="btn btn-primary mr-2" (click)="mostrarFiltro();">  Filtrar  </button-->
         <button class="btn btn-success me-2" (click)="onImprimir()">Imprimir</button>
         <button class="btn btn-danger" (click)="regresarGrupos();">  Cerrar  </button>
     </div>
 </div>
 </div>
 <!--Ventana Principal-->
 <div class="modal backdrop" tabindex="-1" role="dialog" name="ventanaPrincipal" #ventanaPrincipal>
   <div class="modal-dialog modal-md animated fadeInDown" role="document">
     <div class="modal-content">
       <div class="modal-header bg-success">
         <h5 class="modal-title">{{tituloForm}}</h5>
         <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close" (click)="cerrarVentana(1)">
         </button>
       </div>
       <div class="modal-body bg-light">
         <form [formGroup]="frmPrincipal" name="frmPrincipal" id="frmPrincipal"
           (ngSubmit)="guardar()" class="form-fluid">
           <div class="form-group row mb-1">
            <label for="" class="col-sm-3 col-form-label">Grupo</label>
            <div class="col-sm-9">
                 <input type="text" formControlName="codGrupo" name="codGrupo" class="form-control col-8" placeholder="Código Grupo">
            </div>
           </div>
           <div class="alert alert-danger" *ngIf="frmPrincipal.controls['codGrupo'].invalid && frmPrincipal.controls['codGrupo'].touched">
              <div *ngIf="frmPrincipal.controls['codGrupo'].errors['required']">El campo codigo grupo es necesario</div>
              <div *ngIf="frmPrincipal.controls['codGrupo'].errors['minlength']">El Nombre debe tener al menos 7 caracteres</div>
           </div>
           <div class="form-group row mb-1">
            <label for="" class="col-sm-3 col-form-label">Carnet</label>
            <div class="col-sm-9">
               <input type="text" formControlName="carnet" name="carnet"
              class="form-control col-8" placeholder="Carnet Alumno"
              (keyup)="revisar()">
            </div>
          </div>
          <div class="alert alert-danger" *ngIf="frmPrincipal.controls['carnet'].invalid && frmPrincipal.controls['carnet'].touched">
            <div *ngIf="frmPrincipal.controls['carnet'].errors['required']">El campo carnet de alumno es necesario</div>
            <div *ngIf="frmPrincipal.controls['carnet'].errors['minlength']">El Nombre debe tener al menos 9 caracteres</div>
            <div *ngIf="frmPrincipal.controls['carnet'].errors['pattern']">Formato de carnet no valido ####-####</div>
            <div *ngIf="frmPrincipal.controls['carnet'].errors['noexiste']">Alumno no existe</div>
            <div *ngIf="frmPrincipal.controls['carnet'].errors['matriculado']">Alumno ya está matriculado en este grupo</div>

          </div>
          <div class="alert alert-success" *ngIf="nombreAlumno !== ''">
              {{nombreAlumno}}
          </div>
          <div class="form-group row">
            <label for="" class="col-sm-3 col-form-label">Fecha Matrícula</label>
            <div class="col-sm-9">
              <input type="date" formControlName="fecha" name="fecha" class="form-control col-8">
            </div>
          </div>
          <input type="hidden" formControlName="funcion" name="funcion">
         </form>
       </div>
       <div class="modal-footer bg-success">
         <button type="submit" form="frmPrincipal" class="btn btn-primary"
         [disabled]="!frmPrincipal.valid">Guardar</button>
         <button type="button" class="btn btn-secondary" data-dismiss="modal"(click)="cerrarVentana(1)">Cerrar</button>
       </div>
     </div>
   </div>
 </div>
 <!--Ventana Confirmación-->
 <div class="modal backdrop" tabindex="-1" role="dialog" name="ventanaConfirm" #ventanaConfirm>
   <div class="modal-dialog" role="document">
     <div class="modal-content">
       <div class="modal-header" [ngClass]="{'bg-warning': tituloForm == 'Advertencia', 'bg-danger': tituloForm !== 'Advertencia'}">
         <h5 class="modal-title">{{tituloForm}}</h5>
         <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close" (click)="cerrarVentana(2)">
         </button>
       </div>
       <div class="modal-body">
          <p>{{mensajeEliminar}}</p>
          <p *ngIf="tituloForm === 'Advertencia'">El alumno tiene calificacion asignada</p>
       </div>
       <div class="modal-footer">
         <button type="button" class="btn btn-primary" (click)="eliminar()" *ngIf="tituloForm === 'Confirmación'">Eliminar</button>
         <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="cerrarVentana(2)">Cancelar</button>
       </div>
     </div>
   </div>
 </div>
