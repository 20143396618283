<div class="container">
  <div class="card">
    <div class="card-header bg-light">
       <h2 class="text-center">Catálogo de Alumnos</h2>
    </div>
    <div class="card-body">
     <div *ngIf="filtrar.visible" class="container animated slideInDown d-flex mb-2"
       name="bloqueFiltro" #bloqueFiltro>
         <form class="form-inline d-flex">
           <div class="form-group me-2">
               <input type="text" class="form-control form-control-sm" placeholder="Carnet"
               #fCarnet size="10"
               (keyup)="filtrarM({carnet: fCarnet.value, nombre:fNombre.value, apellido1:fApellido1.value, apellido2:fApellido2.value}, $event)">
           </div>
           <div class="form-group me-2">
               <input type="text" class="form-control form-control-sm" placeholder="Nombre" size="10"
               #fNombre (keyup)="filtrarM({carnet: fCarnet.value, nombre:fNombre.value, apellido1:fApellido1.value, apellido2:fApellido2.value}, $event)">
           </div>
           <div class="form-group me-2">
            <input type="text" class="form-control form-control-sm me-2" placeholder="Apellido 1" size="10"
            #fApellido1 (keyup)="filtrarM({carnet: fCarnet.value, nombre:fNombre.value, apellido1:fApellido1.value, apellido2:fApellido2.value}, $event)">
            </div>
            <div class="form-group">
            <input type="text" class="form-control form-control-sm" placeholder="Apellido 2" size="10" #fApellido2
            (keyup)="filtrarM({carnet: fCarnet.value, nombre:fNombre.value, apellido1:fApellido1.value, apellido2:fApellido2.value}, $event)">
        </div>
          </form>
          <button class="btn btn-sm btn-danger ms-3"(click)="mostrarFiltro()">&times;</button>
     </div>
    <div class="table-responsive">
   <table class="table table-sm table-hover table-striped">
     <thead class="thead-light">
       <tr [ngClass]="{'dropup':filtrar.orden.modo===1}">
         <th class="cursor-orden" (click)="ordenar('Carnet')">Carnet
          <i [ngClass]="{'dropdown-toggle':filtrar.orden.campo==='Carnet'}"></i>
         </th>
         <th>Id</th>
         <th class="cursor-orden" (click)="ordenar('Nombre')">Nombre
          <i [ngClass]="{'dropdown-toggle':filtrar.orden.campo==='Nombre'}"></i>
         </th>
         <th class="cursor-orden" (click)="ordenar('Apellido1')">Apellido 1
          <i [ngClass]="{'dropdown-toggle':filtrar.orden.campo==='Apellido1'}"></i>
         </th>
         <th class="cursor-orden" (click)="ordenar('Apellido2')">Apellido 2
          <i [ngClass]="{'dropdown-toggle':filtrar.orden.campo==='Apellido2'}"></i>
         </th>
         <th>Correo</th>
         <th>Teléfono</th>
         <th colspan="3" class="text-center">
           <button class="btn btn-outline btn-sm btn-success"(click)="nuevo()" title="Nuevo">
             <fa-icon [icon]="['fas', 'plus']"></fa-icon>
            </button>
            <!--button (click)="reseteoTodos()">R</button-->
         </th>
     </tr>
     </thead>
     <tbody *ngIf="!vacio">
       <tr *ngFor="let item of lista">
         <td>{{item.Carnet}}</td>
         <td>{{item.Identificacion}}</td>
         <td>{{item.Nombre}}</td>
         <td>{{item.Apellido1}}</td>
         <td>{{item.Apellido2}}</td>
         <td>{{item.Correo}}</td>
         <td>{{item.Telefono}}</td>
         <td class="text-center">
            <button class="btn btn-outline btn-info btn-sm " (click)="confirmar(item, 1)" title="Resetear contraseña">
                <fa-icon [icon]="['fas', 'key']"></fa-icon>
            </button>
          </td>
         <td class="text-center">
           <button class="btn btn-outline btn-warning btn-sm " (click)="editar(item.Carnet, item.Identificacion)"
            title="Modificar">
             <fa-icon [icon]="['fas', 'pencil-alt']"></fa-icon>
           </button>
         </td>
         <td class="text-center">
           <button class="btn btn-outline btn-danger btn-sm" (click)="confirmar(item, 2)" title ="Eliminar">
             <fa-icon [icon]="['fas', 'trash']"></fa-icon>
           </button>
         </td>
       </tr>
     </tbody>
   </table>
  </div>
  <div class="alert alert-warning d-block text-center"*ngIf="vacio" >No se encontraron coincidencias</div>
   <div class="d-flex" *ngIf="!vacio">
     <div class="me-3">
       <select name="" id="" class="form-control"  (change)="cambioCant($event)">
           <option value="2">2</option>
           <option value="5" selected>5</option>
           <option value="10">10</option>
           <option value="15">15</option>
           <option value="20">20</option>
           <option value="30">30</option>
           <option value="50">50</option>
           <option value="75">75</option>
           <option value="100">100</option>
       </select>
     </div>
     <div>
      <ul class="pagination pagination-md">
        <li class="page-item" [ngClass]="{'disabled':paginacion.actual==1}"><a class="page-link" (click)="cambiarPagina(-1)" >&laquo;</a></li>
        <li class="page-item" *ngIf="paginacion.primero > 1"><a class="page-link" (click)="cambiarPagina(-2)">...</a></li>
        <li class="page-item" [ngClass]="{'active':paginacion.actual==n}"
        *ngFor="let n of paginacion.pags | slice:paginacion.primero-1:paginacion.ultimo; let i = index"><a class="page-link"
          (click)="cambiarPagina(n)">{{n}}</a></li>
          <li class="page-item" *ngIf="paginacion.ultimo < paginacion.numPags"><a class="page-link" (click)="cambiarPagina(-3)">...</a></li>
        <li class="page-item" [ngClass]="{'disabled':paginacion.actual==paginacion.numPags}"><a class="page-link" (click)="cambiarPagina(0)">&raquo;</a></li>
      </ul>
     </div>
   </div>
 </div>
 </div>
 <div class="card-footer bg-light">
     <div class="botones d-flex justify-content-end">
         <button class="btn btn-primary me-2" (click)="mostrarFiltro();">  Filtrar  </button>
         <button class="btn btn-success me-2" (click)="onImprimir()">Imprimir</button>
         <button class="btn btn-danger" (click)="cerrarCatalogo()">  Cerrar  </button>
     </div>
 </div>
 </div>
 <!--Ventana Alumnos-->
 <div class="modal backdrop" tabindex="-1" role="dialog" name="ventanaAlumno" #ventanaAlumno>
   <div class="modal-dialog animated fadeInDown" role="document">
     <div class="modal-content">
       <div class="modal-header bg-success">
         <h5 class="modal-title">{{tituloForm}}</h5>
         <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close" (click)="cerrarVentana(1)">
         </button>
       </div>
       <div class="modal-body bg-light">
         <form [formGroup]="frmAlumno" name="frmAlumno" id="frmAlumno"
           (ngSubmit)="guardar()" class="form-fluid">
           <div class="row mb-1">
              <label for="" class="col-sm-3 col-form-label">Carnet</label>
              <div class="col-sm-9">
                <input type="text" formControlName="Carnet" name="Carnet" class="form-control col-8" placeholder="Carnet">
              </div>                
           </div>
           <div class="alert alert-danger" *ngIf="frmAlumno.controls['Carnet'].invalid && frmAlumno.controls['Carnet'].touched">
             <div *ngIf="frmAlumno.controls['Carnet'].errors['required']">El campo Carnet es requerido</div>
          </div>
          <div class="row mb-1">
            <label for="" class="col-sm-3 col-form-label">Id</label>
            <div class="col-sm-9">
              <input type="text" formControlName="Identificacion" name="Identificacion" class="form-control col-8" placeholder="Id">
            </div>
         </div>
         <div class="alert alert-danger" *ngIf="frmAlumno.controls['Identificacion'].invalid && frmAlumno.controls['Identificacion'].touched">
           <div *ngIf="frmAlumno.controls['Identificacion'].errors['required']">El campo Id es requerido</div>
           <div *ngIf="frmAlumno.controls['Identificacion'].errors['minlength']">La identificación debe tener al menos 7 caracteres</div>
           <div *ngIf="frmAlumno.controls['Identificacion'].errors['errordup']">Id Alumno duplicado</div>
        </div>
        <div class="row mb-1">
          <label for="" class="col-sm-3 col-form-label">Nombre</label>
          <div class="col-sm-9">
            <input type="text" formControlName="Nombre" name="Nombre" class="form-control col-8" placeholder="Nombre">
          </div>            
        </div>
        <div class="alert alert-danger" *ngIf="frmAlumno.controls['Nombre'].invalid && frmAlumno.controls['Nombre'].touched">
           <div *ngIf="frmAlumno.controls['Nombre'].errors['required']">El campo nombre es requerido</div>
           <div *ngIf="frmAlumno.controls['Nombre'].errors['minlength']">El nombre debe tener al menos 3 caracteres</div>
        </div>
        <div class="row mb-1">
          <label for="" class="col-sm-3 col-form-label">Apellido 1</label>
          <div class="col-sm-9">
            <input type="text" formControlName="Apellido1" name="Apellido1" class="form-control col-8" placeholder="Apellido 1">
          </div>  
        </div>
           <div class="alert alert-danger" *ngIf="frmAlumno.controls['Apellido1'].invalid && frmAlumno.controls['Apellido1'].touched">
              <div *ngIf="frmAlumno.controls['Apellido1'].errors['required']">El campo apellido 1 es requerido</div>
              <div *ngIf="frmAlumno.controls['Apellido1'].errors['minlength']">El Nombre debe tener al menos 3 caracteres</div>
           </div>
           <div class="row mb-1">
            <label for="" class="col-sm-3 col-form-label">Apellido 2</label>
            <div class="col-sm-9">
              <input type="text" formControlName="Apellido2" name="Apellido2" class="form-control col-8" placeholder="Apellido 2">
            </div>              
          </div>
           <div class="alert alert-danger" *ngIf="frmAlumno.controls['Apellido2'].invalid && frmAlumno.controls['Apellido2'].touched">
              <div *ngIf="frmAlumno.controls['Apellido2'].errors['required']">El campo apellido 2 es requerido</div>
              <div *ngIf="frmAlumno.controls['Apellido2'].errors['minlength']">El Nombre debe tener al menos 3 caracteres</div>
          </div>
          <div class="row mb-1">
            <label for="" class="col-sm-3 col-form-label">Correo</label>
            <div class="col-sm-9">
              <input type="text" formControlName="Correo" name="Correo" class="form-control col-8" placeholder="Correo">
            </div>              
        </div>
         <div class="alert alert-danger" *ngIf="frmAlumno.controls['Correo'].invalid && frmAlumno.controls['Correo'].touched">
            <div *ngIf="frmAlumno.controls['Correo'].errors['required']">El campo correo es requerido</div>
            <div *ngIf="frmAlumno.controls['Correo'].errors['email']">Formato de correo no válido</div>
        </div>          
          <div class="row mb-1">
            <label for="" class="col-sm-3 col-form-label">Sexo</label>
            <div class="col-sm-9">
              <select formControlName="Sexo" name="Sexo" id="Sexo" placeolder="Sexo" class="form-select">
                  <option value="M">Masculino</option>
                  <option value="F">Femenino</option>
              </select>
            </div>
          </div>
          <div class="row mb-3">
            <label for="" class="col-sm-3 col-form-label">Teléfono</label>
            <div class="col-sm-9">
              <input type="text" formControlName="Telefono" name="Telefono" class="form-control col-8" placeholder="Teléfono">
            </div>
          </div>
          <div class="alert alert-danger mt-1" *ngIf="frmAlumno.controls['Telefono'].invalid && frmAlumno.controls['Telefono'].touched">
              <div *ngIf="frmAlumno.controls['Telefono'].errors['required']">El campo Teléfono es requerido</div>
              <div *ngIf="frmAlumno.controls['Telefono'].errors['minlength']">El Teléfono debe tener al menos 8 caracteres</div>
           </div>
           <div class="d-flex justify-content-around">
              <div class="form-check form-switch">
                <label class="form-check-label" for="Estado">Activar Usuario</label>
                <input type="checkbox" class="form-check-input" id="Estado" name="Estado" formControlName="Estado">
              </div>
            </div>
             <input type="hidden" formControlName="funcion" name="funcion">
         </form>
       </div>
       <div class="modal-footer bg-success">
         <button type="submit" form="frmAlumno" class="btn btn-primary"
         [disabled]="!frmAlumno.valid || enviado">Guardar</button>
         <button type="button" class="btn btn-secondary" data-dismiss="modal"(click)="cerrarVentana(1)">Cerrar</button>
       </div>
     </div>
   </div>
 </div>
 <!--Ventana Confirmación-->
 <div class="modal backdrop" tabindex="-1" role="dialog" name="ventanaConfirm" #ventanaConfirm>
   <div class="modal-dialog animated fadeInDown" role="document">
     <div class="modal-content">
       <div class="modal-header" [ngClass]="{'bg-danger': accionConfirmar == 2, 'bg-info': accionConfirmar == 1}">
         <h5 class="modal-title">{{tituloForm}}</h5>
         <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close" (click)="cerrarVentana(2)">
         </button>
       </div>
       <div class="modal-body">
         <p>{{mensajeEliminar}} {{alumno.Nombre}} {{alumno.Apellido1}} {{alumno.Apellido2}}</p>
         <p *ngIf="tituloForm === 'Advertencia'">Se encontró registros en curso(s)</p>
       </div>
       <div class="modal-footer">
         <button type="button" class="btn btn-primary" (click)="onConfirmar()"
            *ngIf="tituloForm === 'Confirmación' || tituloForm === 'Contraseña'">Aceptar</button>
         <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="cerrarVentana(2)">Cancelar</button>
       </div>
     </div>
   </div>
 </div>
