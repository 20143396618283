<div class="container">
    <div class="card">
      <div class="card-header bg-light">
         <h2 class="text-center">Catálogo de Administrativos</h2>
      </div>
      <div class="card-body">
       <div *ngIf="filtrar.visible" class="container animated slideInDown d-inline-flex mb-2"
         name="bloqueFiltro" #bloqueFiltro>
           <form class="form-inline d-flex">
             <div class="form-group me-2">
                 <div class="input-group">
                 <input type="text" class="form-control form-control-sm" placeholder="Id"
                 #fCodigo (keyup)="filtrarM({nombre: fNombre.value, id: fCodigo.value}, $event)">
                 </div>
             </div>
             <div class="form-group">
                 <input type="text" class="form-control form-control-sm" placeholder="Nombre"
                 #fNombre (keyup)="filtrarM({nombre: fNombre.value, id: fCodigo.value}, $event)" >
             </div>
            </form>
            <button class="btn btn-sm btn-danger ms-2" (click)="mostrarFiltro()">&times;</button>
       </div>
    <div class="alert alert-danger" *ngIf="noEliminar == true">
      No se puede eliminar el Administrador del Sistema
    </div>
    <div class="table-responsive">
     <table class="table table-sm table-hover table-striped">
       <thead class="thead-light">
        <tr [ngClass]="{'dropup':filtrar.orden.modo===1}">
          <th class="cursor-orden" (click)="ordenar('id')">Id
            <i [ngClass]="{'dropdown-toggle':filtrar.orden.campo==='id'}"></i>
           </th>
           <th class="cursor-orden" (click)="ordenar('nombre')">Nombre
            <i [ngClass]="{'dropdown-toggle':filtrar.orden.campo==='nombre'}"></i>
           </th>
           <th>Correo</th>
           <th>Puesto</th>
           <th>Teléfono</th>
           <th colspan="3" class="text-center">
             <button class="btn btn-outline btn-sm btn-success"(click)="nuevo()" title="Nuevo">
               <fa-icon [icon]="['fas', 'plus']"></fa-icon>
              </button>
           </th>
       </tr>
       </thead>
       <tbody *ngIf="!vacio">
         <tr *ngFor="let item of lista">
           <td>{{item.id}}</td>
           <td>{{item.nombre}}</td>
           <td>{{item.correo}}</td>
           <td>{{item.puesto}}</td>
           <td>{{item.telefono}}</td>
           <td class="text-center">
              <button class="btn btn-outline btn-info btn-sm " (click)="confirmar(item, 1)" title="Resetear contraseña">
                  <fa-icon [icon]="['fas', 'key']"></fa-icon>
              </button>
            </td>
           <td class="text-center">
             <button class="btn btn-outline btn-warning btn-sm " (click)="editar(item.id)" title="Modificar">
               <fa-icon [icon]="['fas', 'pencil-alt']"></fa-icon>
             </button>
           </td>
           <td class="text-center">
             <button class="btn btn-outline btn-danger btn-sm" (click)="confirmar(item, 2)" title="Eliminar">
               <fa-icon [icon]="['fas', 'trash']"></fa-icon>
             </button>
           </td>
         </tr>
       </tbody>
     </table>
    </div>
    <div class="alert alert-warning text-center"*ngIf="vacio">No se encontraron coincidencias</div>
     <div class="d-flex" *ngIf="!vacio">
       <div class="me-3">
        <select name="" id="" class="form-control"  (change)="cambioCant($event)">
          <option value="2" [selected]="paginacion.limite===2">2</option>
          <option value="5" [selected]="paginacion.limite===5">5</option>
          <option value="10" [selected]="paginacion.limite===10">10</option>
          <option value="15" [selected]="paginacion.limite===20">15</option>
          <option value="20" [selected]="paginacion.limite===15">20</option>
          <option value="30" [selected]="paginacion.limite===30">30</option>
          <option value="50" [selected]="paginacion.limite===50">50</option>
          <option value="75" [selected]="paginacion.limite===75">75</option>
          <option value="100" [selected]="paginacion.limite===100">100</option>
      </select>
       </div>
       <div>
        <ul class="pagination pagination-md">
          <li class="page-item" [ngClass]="{'disabled':paginacion.actual==1}"><a class="page-link" (click)="cambiarPagina(-1)" >&laquo;</a></li>
          <li class="page-item" *ngIf="paginacion.primero > 1"><a class="page-link" (click)="cambiarPagina(-2)">...</a></li>
          <li class="page-item" [ngClass]="{'active':paginacion.actual==n}"
          *ngFor="let n of paginacion.pags | slice:paginacion.primero-1:paginacion.ultimo; let i = index"><a class="page-link"
            (click)="cambiarPagina(n)">{{n}}</a></li>
            <li class="page-item" *ngIf="paginacion.ultimo < paginacion.numPags"><a class="page-link" (click)="cambiarPagina(-3)">...</a></li>
          <li class="page-item" [ngClass]="{'disabled':paginacion.actual==paginacion.numPags}"><a class="page-link" (click)="cambiarPagina(0)">&raquo;</a></li>
        </ul>
       </div>
     </div>
   </div>
   </div>
   <div class="card-footer bg-light">
       <div class="botones d-flex justify-content-end">
           <button class="btn btn-primary me-2" (click)="mostrarFiltro();">  Filtrar  </button>
           <!--button class="btn btn-success me-2">Imprimir</button-->
           <button class="btn btn-danger" (click)="cerrarCatalogo()">  Cerrar  </button>
       </div>
   </div>
   </div>
   <!--Ventana Principal-->
   <div class="modal backdrop" tabindex="-1" role="dialog" name="ventanaAdmin" #ventanaAdmin>
     <div class="modal-dialog animated fadeInDown" role="document">
       <div class="modal-content">
         <div class="modal-header bg-success">
           <h5 class="modal-title">{{tituloForm}}</h5>
           <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close" (click)="cerrarVentana(1)">
           </button>
         </div>
         <div class="modal-body bg-light">
           <form [formGroup]="frmAdmin" name="frmAdmin" id="frmAdmin"
             (ngSubmit)="guardar()" class="d-block">
             <div class="row mb-2">
                <label for="" class="col-sm-2 col-form-label">Id</label>
                <div class="col-sm-10">
                  <input type="text" formControlName="id" name="id" class="form-control" placeholder="Id">
                </div>
             </div>
             <div class="alert alert-danger" *ngIf="frmAdmin.controls['id'].invalid && frmAdmin.controls['id'].touched">
               <div *ngIf="frmAdmin.controls['id'].errors['required']">El campo Id es requerido</div>
               <div *ngIf="frmAdmin.controls['id'].errors['minlength']">El id debe tener al menos 7 caracteres</div>
               <div *ngIf="frmAdmin.controls['id'].errors['errordup']">Id administrador duplicado</div>
            </div>
            <div class="row mb-2">
              <label for="" class="col-sm-2 col-form-label">Nombre</label>
              <div class="col-sm-10">
                <input type="text" formControlName="nombre" name="nombre" class="form-control" placeholder="nombre">
              </div>
            </div>
            <div class="alert alert-danger" *ngIf="frmAdmin.controls['nombre'].invalid && frmAdmin.controls['nombre'].touched">
               <div *ngIf="frmAdmin.controls['nombre'].errors['required']">El campo nombre es requerido</div>
               <div *ngIf="frmAdmin.controls['nombre'].errors['minlength']">El nombre debe tener al menos 7 caracteres</div>
            </div>
            <div class="row mb-2">
              <label for="" class="col-sm-2 col-form-label">Correo</label>
              <div class="col-sm-10">
                <input type="text" formControlName="correo" name="correo" class="form-control col-8" placeholder="Correo">
              </div>
            </div>
            <div class="alert alert-danger" *ngIf="frmAdmin.controls['correo'].invalid && frmAdmin.controls['correo'].touched">
               <div *ngIf="frmAdmin.controls['correo'].errors['required']">El campo correo es requerido</div>
               <div *ngIf="frmAdmin.controls['correo'].errors['email']">Formato de correo no válido</div>
             </div>             
             <div class="row mb-2">
              <label for="" class="col-sm-2 col-form-label">Puesto</label>
              <div class="col-sm-10">
                <input type="text" formControlName="puesto" name="puesto" class="form-control col-8" placeholder="Puesto">
              </div>                
             </div>
             <div class="alert alert-danger" *ngIf="frmAdmin.controls['puesto'].invalid && frmAdmin.controls['puesto'].touched">
                <div *ngIf="frmAdmin.controls['puesto'].errors['required']">El campo puesto es requerido</div>
             </div>
            <div class="row">
              <label for="" class="col-sm-2 col-form-label">Teléfono</label>
              <div class="col-sm-10">
                <input type="text" formControlName="telefono" name="telefono" class="form-control col-8" placeholder="Teléfono">
              </div>                
            </div>
            <div class="form-check form-check-switch d-flex justify-content-center mt-2" *ngIf="id !== 'admin'">
              <input type="checkbox" class="form-check-input" id="Estado" name="Estado" formControlName="Estado">
              <label class="form-check-label" for="Estado">Activar Usuario</label>
            </div>
            <input type="hidden" formControlName="funcion" name="funcion">
           </form>
         </div>
         <div class="modal-footer bg-success">
           <button type="submit" form="frmAdmin" class="btn btn-primary"
           [disabled]="!frmAdmin.valid">Guardar</button>
           <button type="button" class="btn btn-secondary" data-dismiss="modal"(click)="cerrarVentana(1)">Cerrar</button>
         </div>
       </div>
     </div>
   </div>
   <!--Ventana Confirmación-->
   <div class="modal backdrop" tabindex="-1" role="dialog" name="ventanaConfirm" #ventanaConfirm>
     <div class="modal-dialog animated fadeInDown" role="document">
       <div class="modal-content">
         <div class="modal-header" [ngClass]="{'bg-danger': accionConfirmar == 2, 'bg-info': accionConfirmar == 1}">
           <h5 class="modal-title">Confirmación</h5>
           <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="cerrarVentana(2)">
           </button>
         </div>
         <div class="modal-body">
           <p>{{tituloForm}} Administrativo.</p>
           <p>{{admin.nombre}}</p>
         </div>
         <div class="modal-footer">
           <button type="button" class="btn btn-primary" (click)="onConfirmar()">Aceptar</button>
           <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="cerrarVentana(2)">Cancelar</button>
         </div>
       </div>
     </div>
   </div>
