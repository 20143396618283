<div class="container">
    <div class="card">
      <div class="card-header">
        <h3>Entrar Notas para {{titulo}} (valor: {{valor}}%)</h3>
      </div>
      <div class="card-body">
          <div class="alert alert-warning text-center" *ngIf="vacio">No hay alumnos matriculados en este grupo</div>
          <div class="jumbotron" *ngIf="!vacio">
              <div class="container">
                <div class="row border-bottom bg-secondary text-light pt-1 pb-1" >
                    <div class="col-2">Carnet</div>
                    <div class="col-3">Id</div>
                    <div class= col-4>Nombre</div>
                    <div class="col-1">Obt</div>
                    <!--th>&nbsp;</th-->
                </div>
                <form id="frmNotas" *ngIf="isLoaded" [formGroup]="frmNotas" (submit)="onSubmit()">
                <div class="row mt-1" *ngFor="let item of listas; let i = index"
                  [ngClass]="{'bg-light':i%2===0}">
                  <div class="col-2">{{item.carnet}}</div>
                  <div class="col-3">{{item.identificacion}}</div>
                  <div class="col-4">{{item.nombre}}</div>
                      <!--input class="form-control text-center" id="a" type="text" #a name="a"
                          [ngClass]="{'text-white ; bg-danger': a.value > valor}"-->
                      <input type="number" max="{{valor}}" min="0" class="form-control form-control-sm col-1 border-top-0 border-right-0 border-left-0 border-bottom border-primary"
                      name="a" formControlName="{{item.label}}"
                      (keyup)="onCambioNota($event)"
                      (change)="onCambioNota($event)"
                      (click)="onCambioNota($event)"
                      (blur)="onBlur($event);">
                      <!--td class='center-block'>{{a.value * 100 / item.valor | number:'1.0-0'}}</td-->
                      <!--input type="hidden" formControlName ="{{item.label}}" value="{{item.label}}"-->
                </div>
                <div class="alert alert-danger" *ngIf="hayError">
                  <div>{{msjError}}</div>
               </div>
                </form>
              </div>
            </div>
            <div *ngIf="msjGuardado" class="alert alert-success text-center">Notas guardadas de forma correcta</div>
      </div>
      <div class="card-footer">
          <div class="botones d-flex justify-content-end">
              <button type="submit" class= "btn btn-info mr-3" form="frmNotas" [disabled]= hayError> Guardar </button>
              <button class="btn btn-danger" (click)="cerrar();">  Cerrar  </button>
          </div>
      </div>
    </div>
  </div>


<!--div class="card">
  <div class="card-header">
        <h5 class="card-title">Entrar Calificación para grupo </h5>
  </div>
  <div class="card-body">
    <div class="table-responsive">
      <table class="table table-sm table-hover table-striped">
        <thead class="thead-light">
          <tr>
            <th>Prueba</th>
            <th>Valor</th>
            <th>Obtenido</th>
            <th>Nota</th>
        </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of notas">
            <td>{{item.titulo}}</td>
            <td>{{item.valor}}%</td>
            <td class='center-block'>
              <input id="a" type="text" #a
                [ngClass]="{'text-white ; bg-danger': a.value > item.valor || a.value > 99}">
            </td>
            <td class='center-block'>{{a.value * 100 / item.valor | number:'1.0-0'}}</td>
          </tr>
        </tbody>
      </table>
    </div>

    </div>
  <div class="modal-footer d-flex justify-content-end">
    <div>
    <button type="button" class="btn btn-secondary ml-2" data-dismiss="modal" >Cerrar</button>
  </div>
  </div>
</div-->
