<div class="container">
  <div class="card">
    <div class="card-header">
      <h3 class="text-center">Calificaciones para el grupo {{grupo}}</h3>
    </div>
    <div class="card-body" style="height:640px;
    overflow-y: scroll;">
        <div class="alert alert-warning text-center" *ngIf="vacio">No hay notas registradas</div>
        <div class="table-responsive" *ngIf="!vacio">
            <table class="table table-sm table-hover table-striped">
              <thead class="thead-light">
                <tr>
                  <th>Carnet</th>
                  <th>Nombre</th>
                  <th>Promedio</th>
                  <th colspan="2">&nbsp;</th>
              </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of alumnos">
                  <td>{{item.carnet}}</td>
                  <td>{{item.nombre}}</td>
                  <td>{{item.promedio}}</td>
                  <td>
                    <button class="btn btn-outline btn-info btn-sm" title="Ver Detalles"
                      (click)="mostrarVentanaInfo(item.idDetalle)"
                      *ngIf="item.idDetalle !== null">
                      <fa-icon [icon]="['fas', 'info-circle']"></fa-icon>
                      <span class="d-none d-lg-inline-block">&nbsp;ver Detalles</span>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
           </div>
    </div>
    <div class="card-footer">
        <div class="botones d-flex justify-content-end">
            <button class="btn btn-success me-2" (click)="onImprimir()">Imprimir</button>
            <button class="btn btn-danger me-2" (click)="cerrar();">  Cerrar  </button>            
        </div>
    </div>
  </div>
</div>
 <!--Ventana detalles Calificación-->
 <div class="modal backdrop" tabindex="-1" role="dialog" name="detalleResultado" #detalleResultado>
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header bg-info">
        <h5 class="modal-title">Detalles resultados del curso {{nombreCurso}} </h5>
        <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close" (click)="cerrarVentanaInfo()" >
        </button>
      </div>
      <div class="modal-body">
        <div class="table-responsive">
          <table class="table table-sm table-hover table-striped">
            <thead class="thead-light">
              <tr>
                <th>Fecha</th>
                <th>Prueba</th>
                <th>Valor</th>
                <th>Obtenido</th>
            </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of detalles">
                <td>{{item.fecha | date: 'dd/MM/yyyy'}}</td>
                <td>{{item.titulo}}</td>
                <td>{{item.valor}}%</td>
                <td class='center-block'>{{item.obtenido}}%</td>
                <!--td class="text-center">
                   <button class="btn btn-outline btn-success btn-sm" (click)="importarExcel(item.idPrueba)">
                     <i class="fas fa-newspaper"></i>
                   </button>
                 </td>  -->
              </tr>
            </tbody>
          </table>
        </div>
       </div>
      <div class="modal-footer d-flex justify-content-end">
        <div>
        <button type="button" class="btn btn-secondary ml-2" data-dismiss="modal"  (click)="cerrarVentanaInfo()" >Cerrar</button>
      </div>
      </div>
    </div>
  </div>
</div>
 <!--Ventana Introducir Calificación-->
 <!--div class="modal backdrop" tabindex="-1" role="dialog" name="detalleResultado" #entrarNotas>
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Entrar Notas Trabajos {{nombreCurso}} </h5>
        <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close" (click)="cerrarVentanaNotas()" >
        </button>
      </div>
      <div class="modal-body">
        <div class="table-responsive">
          <table class="table table-sm table-hover table-striped table-center">
            <thead class="thead-light">
              <tr>
                <th>Prueba</th>
                <th>Valor</th>
                <th>Obtenido</th>
            </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of notas">
                <td>{{item.titulo}}</td>
                <td>{{item.valor}}%</td>
                <td class='center-block'>
                  <input id="a" type="text" #a
                    [ngClass]="{'text-white ; bg-danger': a.value > item.valor || a.value > 99}"
                     >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
       </div>
      <div class="modal-footer d-flex justify-content-end">
        <div>
        <button type="button" class="btn btn-secondary ml-2" data-dismiss="modal"  (click)="cerrarVentanaNotas()" >Cerrar</button>
      </div>
      </div>
    </div>
  </div>
</div-->

