import { FiltrarService } from './../shared/services/filtrar.service';
import { PaginacionService } from './../shared/services/paginacion.service';
import { Component, OnInit,  ViewChild, Renderer2, ElementRef  } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CatalogoService } from './../shared/services/catalogo.service';
import { map } from 'rxjs/operators';
import { Prueba } from '../shared/models/prueba.model';
import { Router } from '@angular/router';

// TODO:Revisar el contaddor hay que filtrarlo para mostrar las preguntas

@Component({
  selector: 'app-pruebas',
  templateUrl: './pruebas.component.html',
  styleUrls: ['./pruebas.component.css']
})
export class PruebasComponent implements OnInit {
  campoFiltro = 'titulo';
  tituloCatalogo = 'Pruebas en Línea';
  nombreCurso = '';
  prueba = new Prueba();
  lista = [];
  frmPrincipal: FormGroup;
  tituloForm = '';
  errorDup = '';
  vacio;
  filtroActual;
  cantidadRegs: number;
  mensajeEliminar;
  @ViewChild('ventanaPrincipal', { static: true }) ventanaPrincipal: ElementRef;
  @ViewChild('ventanaConfirm', { static: true }) ventanaConfirm: ElementRef;
  @ViewChild('bloqueFiltro') bloqueFiltro: ElementRef;
  constructor(private fb: FormBuilder, private renderizar: Renderer2,
              private catalogoService: CatalogoService, private router: Router,
              public paginacion: PaginacionService,
              public filtrar: FiltrarService) {
      this.frmPrincipal = this.fb.group({
        idPrueba: [''],
        titulo: ['', [Validators.required, Validators.minLength(7)]],
        codCurso: ['', [Validators.required, Validators.minLength(6), Validators.pattern('[0-9A-Za-z]{3}-[0-9]{4}')]],
        valor: ['', [Validators.required, Validators.pattern('[0-9]*(.[0-9]{1,2})?')]],
        noPreguntas: ['', [Validators.required, Validators.pattern('[0-9]*')]],
        duracion: ['01:00', [Validators.required, Validators.pattern('[0-9]{1,2}:[0-9]{2}')]],
        tipo: ['1'],
        funcion: ['N']
      });
      catalogoService.setServidor('prueba');
  }
  buscarCurso(curso) {
    this.filtrar.buscarCurso(curso)
     .then(
        snapshot => {
          snapshot.subscribe(
            res => {
              if (res !== null) {
                this.nombreCurso = (res[1] as any).nombre;
                this.frmPrincipal.controls.codCurso.setErrors(null);
              } else {
                this.nombreCurso = '';
                this.frmPrincipal.controls.codCurso.setErrors({noexiste: true});
              }
            }
          );
        }
     );
  }
  onKeyUp() {
    if (this.frmPrincipal.controls.codCurso.valid) {
      this.buscarCurso(this.frmPrincipal.controls.codCurso.value);
    } else {
      this.nombreCurso = '';
    }
  }

  cambioCant(e) {
    this.paginacion.cambioCant(e.target.value);
    this.filtrarM({tipo: 1});
  }
  cambiarPagina(p) { // aquí hay cambios
    this.paginacion.cambiarPagina(p);
    if (!this.filtrar.visible) {
      this.filtrarM({tipo: 1});
    } else {
      this.filtrarM(this.filtrar.filtro);
    }
  }
  guardar() {
   let resultado;
   if (this.frmPrincipal.invalid) {return; }
   const prueba = new Prueba(this.frmPrincipal.value);
   prueba.codCurso = prueba.codCurso.toUpperCase();
   switch (this.frmPrincipal.value.funcion) {
      case 'N':
        this.catalogoService.guardar(prueba)
         .subscribe(
          res => resultado = res,
           err => console.log(err),
           () => {
            if (resultado[0] === '1') {
              this.errorDup = 'Código duplicado';
            } else {
              this.errorDup = '';
              this.refrescar();
              this.cerrarVentana(1);
            }
            }
           );
        break;
      case 'E':
       // prueba.idPrueba = this.codigo;
        this.catalogoService.guardar(prueba, prueba.idPrueba)
          .subscribe(
            res => console.log(res),
            err => console.log(err),
            () => {
              this.refrescar();
              this.cerrarVentana(1);
            }
            );
   }
  }
  nuevo() {
    this.tituloForm = 'Nueva Prueba';
    this.frmPrincipal.reset({funcion: 'N', tipo: '1'});
    this.nombreCurso = '';
    // this.frmPrincipal.controls.idPrueba.enable();
   // this.errorDup = ''; // <-- 2- para error de código

    this.renderizar.addClass(this.ventanaPrincipal.nativeElement, 'show');
    this.renderizar.setStyle(this.ventanaPrincipal.nativeElement, 'display', 'block');
  }
  editar(codigo) {
   this.tituloForm = 'Modificar Prueba';
   this.errorDup = '';
   this.filtrar.ejecutar({idPrueba: codigo})
     .then(
        snapshot => {
          snapshot.subscribe(
            data => {
              data = data[1];
              this.frmPrincipal.setValue({
                idPrueba : (data as Prueba).idPrueba,
                titulo : (data as Prueba).titulo,
                codCurso: (data as Prueba).codCurso,
                valor:  (data as Prueba).valor,
                noPreguntas: (data as Prueba).noPreguntas,
                duracion:  (data as Prueba).duracion,
                tipo: 1,
                funcion : 'E'
              });
            }
          );
        }
     );
   this.renderizar.addClass(this.ventanaPrincipal.nativeElement, 'show');
   this.renderizar.setStyle(this.ventanaPrincipal.nativeElement, 'display', 'block');
  }
  eliminar() {
//    let emp;
    // TODO: Antes de eliminar confirmar que la prueba no tiene preguntas

    this.catalogoService.eliminar(this.prueba.idPrueba)
        .subscribe(
//           res => emp = res,
  //         err => console.log(err),
           () => this.refrescar() // this.cursos = this.cursos.filter(e => e.codigo !== this.curso.codigo)
        );
    this.cerrarVentana(2);
  }
  confirmarEliminar(prueba) {
    this.filtrar.buscarRegistro(false, false, false, false, false,
        {idPrueba: prueba.idPrueba, tabla: 'preguntas'})
    .then (
      snapshot => {
        snapshot.subscribe(
          data => {
            this.prueba.idPrueba = prueba.idPrueba;
            this.prueba.titulo = prueba.titulo;
            if (data === null) {
              this.tituloForm = 'Confirmación';
              this.mensajeEliminar = 'Eliminar prueba';
            } else {
              this.mensajeEliminar = 'No se puede eliminar la prueba';
              this.tituloForm = 'Advertencia';
            }
            this.renderizar.addClass(this.ventanaConfirm.nativeElement, 'show');
            this.renderizar.setStyle(this.ventanaConfirm.nativeElement, 'display', 'block');
          }
        );
      }
    );
  }
  refrescar() {
    this.filtrar.cantidad({tipo: 1})
    .then(
      res => {
        res.subscribe(
          data => {
          this.cantidadRegs = Number(res);
          this.paginacion.calcularPiecera(Number(data));
        }
        );
      }
    );
    this.filtrarM({tipo: 1 });
  }
  agregarPreguntas(prueba) {
    this.router.navigate(['preguntas', prueba]);
  }
  mostrarFiltro() {
    this.filtrar.toggleVisible();
    this.refrescar();
  }
  ordenar(campo) {
    this.filtrar.ordenar(campo);
    this.filtrarM(this.filtrar.filtro);
  }
  filtrarM(filtro, e?) {
    filtro.tipo = 1;
    this.filtroActual = filtro;
    if ((e) && this.filtrar.invalidChar( e.key )) {
      return;
    }
    if (e) {
      this.paginacion.actual = 1;
    }
    filtro.tipo = 1;
    this.filtrar.cantidad(filtro)
    .then(
      res => {
        res.subscribe(
          data => this.paginacion.calcularPiecera(Number(data))
        );
      }
    );
    this.filtrar.ejecutar(filtro, this.paginacion.actual, this.paginacion.limite)
      .then(
        snapshot => {
          snapshot.subscribe(
            data => {
              this.lista.length = 0;
              for (const prop in data) {
                if (prop) {
                  const dato = {
                    idPrueba: data[prop].idPrueba,
                    codCurso: data[prop].codCurso,
                    titulo: data[prop].titulo,
                    valor: data[prop].valor,
                    noPreguntas: data[prop].noPreguntas,
                    duracion: data[prop].duracion
                  };
                  this.lista.push(dato);
                }
              }
              this.vacio = this.lista.length === 0;
            }
          );
        }
      );
  }
  cerrarVentana(v: number) {
    switch (v) {
      case 1:
        this.renderizar.removeClass(this.ventanaPrincipal.nativeElement, 'show');
        this.renderizar.removeStyle(this.ventanaPrincipal.nativeElement, 'display');
        break;
      case 2:
        this.renderizar.removeClass(this.ventanaConfirm.nativeElement, 'show');
        this.renderizar.removeStyle(this.ventanaConfirm.nativeElement, 'display');
    }
  }
  cerrarCatalogo() {
    this.router.navigate(['/'], { skipLocationChange: true, replaceUrl: true });
  }
  ngOnInit() {
    this.paginacion.iniciar();
    this.filtrar.inicializar({tipo: 1}, 'pruebas', 'codCurso');
    this.refrescar();
  }
}
