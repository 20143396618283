<!--Navegación Inicial-->
<nav class="navbar navbar-expand-lg navbar-light bg-success font-weight-bolder" *ngIf="rol == -1">
  <a href="#" class="navbar-brand usuario">| Centro Cultural do Brasil |</a>
  <div class=" ms-auto d-flex">
      <div class="usuario me-2">{{nomUsuario}}</div>
      <button class="btn btn-primary" [routerLink]="['/login']"><i class="fas fa-user-lock"></i> Iniciar</button>
</div>

</nav>
<!--Navegación administrativo-->
<nav class="navbar navbar-expand-lg navbar-light bg-success font-weight-bolder" *ngIf="rol == 1">
  <a class="navbar-brand usuario" href="#">| Admin |</a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarNavDropdown">
    <ul class="navbar-nav">
        <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownCatalogo" 
            role="button" data-bs-toggle="dropdown" aria-expanded="false">
              Catálogos
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdownCatalogo">
              <li class="dropdown-item" [routerLink]="['/cat-admin']">Administrativos</li>
              <li class="dropdown-item" [routerLink]="['/cat-prof']">Profesores</li>
              <li class="dropdown-item" [routerLink]="['/cat-alum']">Alumnos</li>
              <li class="dropdown-item" [routerLink]="['/cat-curso']">Cursos</li>
          </ul>
        </li>
        <li class="nav-item">
          <a class="nav-link"  [routerLink]="['/grupo']">Registro</a>
        </li>
        <li class="nav-item dropdown"><a class="nav-link dropdown-toggle" href="#"  id="navbarDropdownPruebas" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Pruebas
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdownPruebas">
              <li class="dropdown-item"[routerLink]="['/pruebas']">En Línea</li>
              <li class="dropdown-item"[routerLink]="['/pruebasent']">Entregables</li>
          </ul>
        </li>
      <!--li class="nav-item">
        <a class="nav-link"  [routerLink]="['/notas']">Notas</a>
      </li-->
      <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-bs-toggle="dropdown"
          role="button" aria-haspopup="true" aria-expanded="false">
            Configuración
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
            <!-- a class="dropdown-item"  [routerLink]="['/bd']">Base de Datos</a-->
            <li class="dropdown-item"  [routerLink]="['/cambio-contras']">Cambiar Contraseña</li>
          </ul>
      </li>
    </ul>
    <div class=" ms-auto d-flex">
        <div class="usuario me-2">{{nomUsuario}}</div>
        <button class="btn btn-danger" (click)="salir()"><i class="fas fa-user-lock"></i> Salir</button>
  </div>
  </div>
</nav>
<!--Navegación profesor-->
<nav class="navbar navbar-expand-lg navbar-light bg-success font-weight-bolder" *ngIf="rol==2">
  <a class="navbar-brand lead text-uppercase" href="#">| Profesor |</a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarNavDropdown">
    <ul class="navbar-nav">
      <!--li class="nav-item">
        <a class="nav-link" href="#">Pruebas</a>
      </li-->
      <li class="nav-item">
        <a class="nav-link" [routerLink]="['/grupo-prof']">Grupos</a>
      </li>
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-bs-toggle="dropdown"
        role="button" aria-haspopup="true" aria-expanded="false">
          Configuración
        </a>
        <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
          <li class="dropdown-item"  [routerLink]="['/cambio-contras']">Cambiar Contraseña</li>
        </ul>
    </li>
    </ul>
    <div class=" ms-auto d-flex">
        <div class="usuario me-2">{{nomUsuario}}</div>
        <button class="btn btn-danger" (click)="salir()"><i class="fas fa-user-lock"></i> Salir</button>
    </div>

  </div>
</nav>
<!--Navegación estuddiantes-->
<nav class="navbar navbar-expand-lg navbar-light bg-success font-weight-bolder sticky-top" *ngIf="rol==3">
  <a class="navbar-brand lead text-uppercase" href="#">| Estudiante |</a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarNavDropdown">
    <ul class="navbar-nav">
      <li class="nav-item">
        <a class="nav-link" [routerLink]="['/notasest']">Ver Notas</a>
      </li>
      <li class="nav-item">
        <a class="nav-link"  [routerLink]="['/prueba']">Tomar Prueba</a>
      </li>
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-bs-toggle="dropdown"
        role="button" aria-haspopup="true" aria-expanded="false">
          Configuración
        </a>
        <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
          <li class="dropdown-item"  [routerLink]="['/cambio-contras']">Cambiar Contraseña</li>
        </ul>
    </li>
    </ul>
    <div class=" ms-auto d-flex">
        <div class="usuario me-2">{{nomUsuario}}</div>
        <button class="btn btn-danger" (click)="salir()"><i class="fas fa-user-lock"></i> Salir</button>
  </div>
  </div>
</nav>
