export class Matricula {
  idDetalle?: number;
  codGrupo: string;
  carnet: string;
  fecha: Date;
  nota: number;
  constructor(matricula?) {
    if (matricula) {
      if (matricula.idDetalle) {
        this.idDetalle = matricula.idDetalle;
      }
      this.codGrupo = matricula.codGrupo;
      this.carnet = matricula.carnet;
      this.fecha = matricula.fecha;
      this.nota = matricula.nota;
    }
  }
}
