 import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class PaginacionService {
  primero;
  ultimo;
  actual;
  limite;
  numRegs;
  numPags;
  regs = 0;
  pags;
  constructor() { }
  iniciar() {
    this.primero = 1;
    this.ultimo = 10;
    this.actual = 1;
    this.limite =  5;
    this.numRegs = 0;
    this.numPags = 0;
    this.pags = [];
  }
  cambioCant(lim) {
    this.actual = 1;
    this.limite = parseInt(lim, 0);
    this.setNumPags();
  }
  calcularPiecera(regs) {
    this.numRegs = regs;
    this.setNumPags();
  }
  setNumPags() {
    this.numPags = Math.ceil(this.numRegs / this.limite);
    this.pags = Array.from(Array(this.numPags)).map((x, i) => i + 1 );
  }
  cambiarPagina(p) {
    if (p === -2) {
      this.primero -= 10;
      if (this.primero < 1) {
        this.primero = 1;
      }
      this.ultimo = this.primero + 9;
      p = this.primero;
    }
    if (p === -3) {
      this.primero = this.ultimo + 1;
      this.ultimo += 10;
      if (this.ultimo > this.numPags) {
        this.ultimo = this.numPags;
      }
      p = this.primero;
    }
    if (p === -1) {
      if (this.actual > 1) {
        this.actual--;
        if (this.actual < this.primero) {
          this.primero = this.actual - 9;
          this.ultimo = this.actual;
        }
      }
    } else if (p === 0) {
      if (this.actual < this.numPags) {
        this.actual++;
        if (this.actual > this.ultimo) {
          this.primero = this.actual;
          this.ultimo = this.actual + 9;
        }
      } else {
        this.actual = 1;
        this.primero = 1;
        this.ultimo = 10;
      }
    } else { this.actual = p; }
  }
}
