export class Profesor {
  ID: string;
  nombre: string;
  apellido1: string;
  apellido2: string;
  correo: string;
  telefono: string;
  Estado: string;
  constructor(profesor?) {
    if (profesor) {
      this.ID = profesor.ID;
      this.nombre = profesor.nombre;
      this.apellido1 = profesor.apellido1;
      this.apellido2 = profesor.apellido2;
      this.correo = profesor.correo;
      this.telefono = profesor.telefono;
      this.Estado = profesor.Estado === true ? 'A' : 'I';
    }
  }
}
