<div *ngIf="!enviado" class="container-fluid">
  <div class="card">
    <div class="card-header bg-secondary d-flex justify-content-between sticky-top sticky-offset"
      [ngClass]="{'bg-danger text-light': aviso===true}">
      <h3 class="text-light">Prueba: <span class="small mr-3">{{pruebaActual}}</span>
        <span *ngIf="prueba.activa !== '0'">
          Valor: <span class="small">{{prueba.valor}}%</span>
        </span>
      </h3>
      <h3 class="text-light" *ngIf="prueba.activa !== '0'">
        Tiempo: <span class="small"><app-contador #contador></app-contador></span>
      </h3>
    </div>
    <div class="card-body">
      <form *ngIf="isLoaded" id= "frmExamen" [formGroup]="frmExamen" (ngSubmit)="onSubmit()" #form>
      <ol class="list-group" *ngFor="let item of listas; let i = index">
        <li class="list-group-item">
            <div class="d-flex align-items-start" style="font-size: 1.2rem;">
              <div class="font-weight:bold; align-self-center badge-pill badge-primary">{{i+1}}</div>
              <ckeditor style="background-color:#fff; zoom:0.85;" class="align-self-center"
              [editor]="EditorInfo" id="editor" data="{{item.texto}}" (ready)="onReady($event);">
            </ckeditor>
          </div>
          
          <div id="opciones d-flex">
              <div class="d-flex align-items-start">
                <input type="radio" formControlName="{{item.label}}" value='1' class="align-self-center"> 
                <ckeditor style="background-color:#fff; zoom:0.85;"  class="align-self-center"
                  [editor]="EditorInfo" id="editor" data="{{item.opcion1}}" (ready)="onReady($event);">
                </ckeditor>
            </div>
            <div class="d-flex align-items-start">
            <input type="radio" formControlName="{{item.label}}" value='2'  class="align-self-center"> 
              <ckeditor style="background-color:#fff; zoom:0.85;"  class="align-self-center"
                [editor]="EditorInfo" id="editor" data="{{item.opcion2}}" (ready)="onReady($event);">
              </ckeditor>
            </div>
            <div class="d-flex align-items-start">
              <input type="radio" formControlName="{{item.label}}" value='3'  class="align-self-center"> 
              <ckeditor style="background-color:#fff; zoom:0.85;"  class="align-self-center"
                [editor]="EditorInfo" id="editor" data="{{item.opcion3}}" (ready)="onReady($event);">
              </ckeditor>
            </div>
            <div class="ml-3" *ngIf="item.opcion4"  class="d-flex align-items-start">
              <input type="radio" formControlName="{{item.label}}" value='4'  class="align-self-center"> 
              <ckeditor style="background-color:#fff; zoom:0.85;"  class="align-self-center"
                [editor]="EditorInfo" id="editor" data="{{item.opcion4}}" (ready)="onReady($event);">
              </ckeditor>
          </div>
        </div>
        </li>
      </ol>
      <input type="hidden" formControlName="valor">
      </form>
    </div>
    <div *ngIf="prueba.activa !== '0'" class="card-footer d-flex justify-content-center">
        <button form="frmExamen" type="submit" class="btn-lg btn-success" [disabled]=enviado>Enviar</button>
    </div>
  </div>
</div>
 <!--Ventana Información y confirmación-->
 <div class="modal backdrop" tabindex="-1" role="dialog" name="ventanaConfirm" #ventanaConfirm>
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{pruebaActual}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="salir()" >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
         <p>Tienes activa la prueba {{pruebaActual}} que tiene una duración de {{prueba.tiempo}}, al hacer click sobre el botón [Continuar] el tiempo
           empezará a correr y no se podrá cancelar la prueba ni salir de ella de lo contrario la prueba se desactivará.</p>
           <p>El sistema te notificará 5 minutos antes de que finalice el tiempo, tornando a rojo la barra con el título de la prueba,
             si aún no has enviado la prueba, transcurrido este tiempo se enviará de forma automática, aunque no hayas terminado.</p>
           <p>Si deseas resolver la prueba en otro momento, puedes salir ahora haciendo click en el botón [Cancelar] de este aviso</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="empezar()">Continuar</button>
        <button type="button" class="btn btn-secondary" data-dismiss="modal"  (click)="salir()" >Cancelar</button>
      </div>
    </div>
  </div>
</div>
 <!--Ventana Confirmación-->
 <div class="modal backdrop" tabindex="-1" role="dialog" name="ventanaConfirmEnvio" #ventanaConfirmEnvio>
  <div class="modal-dialog animated fadeInDown" role="document">
    <div class="modal-content">
      <div class="modal-header bt-danger">
        <h5 class="modal-title">Envío Prueba</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="cerrarConfirmarEnvio()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>¿Seguro que desea enviar la prueba?</p>
        <p>Al enviar la prueba la misma será revisada y desactivada</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="onConfirmarEnvio()">SI</button>
        <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="cerrarConfirmarEnvio()">NO</button>
      </div>
    </div>
  </div>
</div>