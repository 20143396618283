import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      const currentUser = this.authenticationService.currentUserValue;
     // return true;
      if (currentUser) {
      // retornar verdadero porque se loggeo de forma correcta
      // verificar si la ruta esta resguardada por roles
      // console.log(currentUser);
      if (next.data.roles && next.data.roles.indexOf(currentUser.Rol) === -1) {
        this.router.navigate(['/']);
        return false;
      }
      return true;
    }
    // no se loggeo de forma correcta retornar a la página de loggeo la url de loggeo
      this.router.navigate(['login'], { queryParams: {returnUrl: state.url }});
      return false;
  }
}
