export class Alumno {
  Carnet: string;
  Identificacion: string;
  Nombre: string;
  Apellido1: string;
  Apellido2: string;
  Correo: string
  Sexo: string;
  CodGrupo: string;
  PruebaActiva: number;
  Telefono: string;
  Estado: string;
  constructor(alumno?) {
    if (alumno) {
      this.Carnet = alumno.Carnet;
      this.Identificacion = alumno.Identificacion;
      this.Nombre = alumno.Nombre;
      this.Apellido1 = alumno.Apellido1;
      this.Apellido2 = alumno.Apellido2;
      this.Correo = alumno.Correo;
      this.Sexo = alumno.Sexo;
      this.CodGrupo = alumno.CodGrupo;
      this.PruebaActiva = alumno.PruebaActiva;
      this.Telefono = alumno.Telefono;
      this.Estado = alumno.Estado === true ? 'A' : 'I';
    }
  }
}
