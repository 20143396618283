import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  msjUsuarioActivo;
  constructor() { }
  ocultar() {
    this.msjUsuarioActivo = true;
  }
  ngOnInit() {
    const usuario = JSON.parse(localStorage.getItem('currentUser'));
    this.msjUsuarioActivo = (usuario && usuario.estado === 'A') || (usuario === null);
   // if (usuario === null) { this.usuarioActivo = true; }
    if (!this.msjUsuarioActivo || usuario === null) {
      localStorage.removeItem('currentUser');
    }
  }

}
