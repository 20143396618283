import { Time } from '@angular/common';

export class Grupo {
  codGrupo?: string;
  codProfesor: string;
  codCurso: string;
  fechaInicio: Date;
  horario: Time;
 // nombre?: string;
 // apellido1?: string;
 // apellido2?: string;
  constructor(grupo?) {
    if (grupo) {
  //    if (grupo.codGrupo) {
        this.codGrupo = grupo.codGrupo;
   //   }
        this.codProfesor = grupo.codProfesor;
        this.codCurso = grupo.codCurso;
        this.fechaInicio = grupo.fechaInicio;
        this.horario = grupo.horario;
    }
  }
}
