import { ImprimirService } from './../shared/services/imprimir.service';
import { FiltrarService } from './../shared/services/filtrar.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import html2canvas from 'html2canvas';
import * as CKEDITOR from '../../app/build/ckeditor';
//import * as jsPDF from 'jspdf';

@Component({
  selector: 'app-resultado',
  templateUrl: './resultado.component.html',
  styleUrls: ['./resultado.component.css']
})
export class ResultadoComponent implements OnInit {
  public EditorInfo = CKEDITOR.InlineEditor;
  pruebaActual;
  resultados = [];
  numCorrectos;
  noPreguntas;
  resultado;
  constructor(private ruta: ActivatedRoute,
              private router: Router,
              private imprimirSrv: ImprimirService) {
  }
 respIncorrecta(correcta, elegida){
    return `<div class="d-flex align-items-start"><div style="font-weight:bold;">Respuesta correcta : </div><div >${correcta}</div></div> 
            <div class="d-flex align-items-start"><div style="font-weight:bold;">Respuesta elegida : </div><div > ${elegida}</div></div> `
 }
  salir() {
    this.router.navigate(['/'], { skipLocationChange: true, replaceUrl: true });
  }
  /*
  descargarPDF() {
    const columna = `${this.pruebaActual} \t\tCorrectos ${this.numCorrectos} de ${this.noPreguntas} \t(${this.resultado}%)`;
    const filas= [];
    let res;
    for (const prop in this.resultados) {
      if (prop) {
        if (this.resultados[prop].correcta) {
          res = `${this.resultados[prop].texto} \nRespuesta ${this.resultados[prop].rElegida}`;
        } else {
          res = `${this.resultados[prop].texto} \nRespuesta Elegida: ${this.resultados[prop].rElegida}
Respuesta Correcta: ${this.resultados[prop].rCorrecta}`;
        }
        const dato = [`${Number(prop) + 1}- ${res}`];
        filas.push(dato);
      }
    }
    var element = document.getElementById('imprimir');

    html2canvas(element).then((canvas) => {
      var imgData = canvas.toDataURL('image/png');
      var doc = new jsPDF();
      var imgHeight = canvas.height * 208 / canvas.width;
      doc.addImage(imgData, 0, 0, 208, imgHeight);
      doc.save('resultado.pdf');
    })

   // this.imprimirSrv.imprimir([[columna]], element, 'Resultado de prueba', true);
  }
  */
  ngOnInit() {
    const parametro = JSON.parse(localStorage.getItem('resultados'));
    localStorage.removeItem('resultados');

    parametro.forEach(element => {
      if (element.datos) {
        this.numCorrectos = element.datos.numCorrectos;
        this.resultado = element.datos.resultado;
        this.noPreguntas = element.datos.noPreguntas;
        this.pruebaActual = element.datos.pruebaActual;
      } else {
        const resultado = {
          texto: element.texto,
          rCorrecta: element.rCorrecta,
          rElegida: element.rElegida,
          correcta: element.correcta
        };
        this.resultados.push(resultado);
      }

    });
  }

}
