<div class="container">
  <div class="card">
    <div class="card-header">
      <h3>Activar - {{titulo}} para grupo {{grupo}}</h3>
    </div>
    <div class="card-body" style="height:640px;
    overflow-y: scroll;">
        <div class="alert alert-warning text-center" *ngIf="vacio">No hay alumnos matriculados en este grupo</div>
        <div class="table-responsive"  *ngIf="!vacio">
            <table class="table table-sm table-hover table-striped">
              <thead class="thead-light">
                <tr>
                  <th>Carnet</th>
                  <th>Id</th>
                  <th>Nombre</th>
                  <th>
                    <div class="form-check form-switch">
                    <input type="checkbox" [checked]="activar_Todos"
                    class="form-check-input" id="act_todos"
                        (change)="activarTodos($event, grupo)">
                        <label  class="form-check-label" for="act_todos">Activar Todos </label>
                      </div>                        
                  </th>
              </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of alumnos">
                  <td>{{item.carnet}}</td>
                  <td>{{item.identificacion}}</td>
                  <td>{{item.nombre}}</td>
                  <td>
                      <div class="form-check form-switch mt-2">
                          <input type="checkbox" [checked]="item.PruebaActiva === idPrueba"
                          
                          [disabled] = "item.aplicado && origen === '1'"
                          class="form-check-input" id="E_{{item.carnet}}"
                              value="E_{{item.carnet}}" (change)="activar($event, item.PruebaActiva, item.idDetalle)">
                          <label *ngIf="item.aplicado" class="form-check-label" for="E_{{item.carnet}}">Obt. ({{item.obtenido}}%)</label>
                          <label  *ngIf="!item.aplicado" class="form-check-label" for="E_{{item.carnet}}">Activar </label>
                        </div>
                   </td>
                </tr>
              </tbody>
            </table>
           </div>
    </div>
    <div class="card-footer">
        <div class="botones d-flex justify-content-end">
            <button class="btn "
            [ngClass]="{'btn-danger': !activaron, 'btn-success': activaron}"
            (click)="cerrar();">  Cerrar  </button>
        </div>
    </div>
  </div>
</div>
