export class Admin {
  id: string;
  nombre: string;
  correo: string;
  puesto: string;
  telefono: string;
  Estado: string;
  constructor(admin?) {
    if (admin) {
      this.id = admin.id;
      this.nombre = admin.nombre;
      this.correo = admin.correo;
      this.puesto = admin.puesto;
      this.telefono = admin.telefono;
      this.Estado = admin.Estado === true ? 'A' : 'I';
    }
  }
}
